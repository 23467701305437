import { useState, useEffect } from "react";
import backIcon from "../../../assets/images/back.svg";
import searchIcon from "../../../assets/images/ic_search.svg";
import emptyTrx from "../../../assets/images/empty_items.svg";
import menuFilter from "../../../assets/images/ic_filter.svg";
import menuCart from "../../../assets/images/ic_bag.svg";
import ProductModel from "../../product/models/product_model";
import EmptyState from "../../order/components/empty_state";

import dummyProduct from "../../../assets/images/product-1.png";
import { useNavigate } from "react-router-dom";

import moment from "moment";
import "moment/locale/id";

import processIcon from "../../../assets/images/box-time.svg";
import truckDelivery from "../../../assets/images/truck-delivery.svg";
import wallet from "../../../assets/images/wallet.svg";
import document from "../../../assets/images/document.svg";
import star from "../../../assets/images/star.svg";
import routes from "../../../routes";
import TextInput from "../../shared/components/text_input";
import BottomSheet from "../../shared/components/bottom_sheet";
import CartIcon from "../../global_component/view/cart_icon";
import orderFacade from "../../order/facades/order_facade";
import { Order, OrderPagination } from "../../order/models/order_model";

function TransactionPage() {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(0);
  const { orders, fetchOrders } = orderFacade();
  const [transactions, setTransactions] = useState<ProductModel[]>([]);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    let statuses = ["ongoing", "payment", "cancel", "finished"];

    if (open) return;
    fetchOrders({
      status: statuses[currentTab],
      start_date: startDate,
      end_date: endDate,
      search_key: search,
    });

    // console.log(orders);
  }, [currentTab, search, open]);

  return (
    <div className="page">
      <div className="header">
        <div className={"search"}>
          <img src={backIcon} alt="" className="back" />
          <input
            type="text"
            placeholder="Cari Produk di MamaBear"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <img src={searchIcon} alt="" className="action" />
        </div>
        <div className="actions">
          <CartIcon />
          <img
            src={menuFilter}
            onClick={() => setOpen(true)}
            className="menu"
          />
        </div>
      </div>
      <div className="page-content">
        <div className="tab-menu">
          {["Berlangsung", "Bayar", "Batal", "Selesai"].map((menu, index) => (
            <div
              className={`tab${currentTab == index ? " active" : ""}`}
              key={index}
              onClick={() => setCurrentTab(index)}
            >
              {menu}
            </div>
          ))}
        </div>
        {
          [
            <BerlangsungTab orders={orders} />,
            <BayarTab orders={orders} />,
            <BatalTab orders={orders} />,
            <SelesaiTab orders={orders} />,
          ][currentTab]
        }
      </div>
      <BottomSheet
        title="Filter"
        open={open}
        onClose={() => setOpen(false)}
        showAction={true}
      >
        <div className="height-15"></div>
        <div className="row">
          <div className="expanded">
            <TextInput
              prefix={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M12 1.33301V2.66634M4 1.33301V2.66634"
                    stroke="#DE8D8D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1.66675 8.16216C1.66675 5.25729 1.66675 3.80486 2.50149 2.90243C3.33624 2 4.67974 2 7.36675 2H8.63341C11.3204 2 12.6639 2 13.4987 2.90243C14.3334 3.80486 14.3334 5.25729 14.3334 8.16216V8.5045C14.3334 11.4094 14.3334 12.8618 13.4987 13.7642C12.6639 14.6667 11.3204 14.6667 8.63341 14.6667H7.36675C4.67974 14.6667 3.33624 14.6667 2.50149 13.7642C1.66675 12.8618 1.66675 11.4094 1.66675 8.5045V8.16216Z"
                    stroke="#DE8D8D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2 5.33301H14"
                    stroke="#DE8D8D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
              type="date"
              label="Tanggal (Mulai)"
              isOutlined={true}
              isPrimary={false}
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="width-10"></div>
          <div className="expanded">
            <TextInput
              prefix={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M12 1.33301V2.66634M4 1.33301V2.66634"
                    stroke="#DE8D8D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1.66675 8.16216C1.66675 5.25729 1.66675 3.80486 2.50149 2.90243C3.33624 2 4.67974 2 7.36675 2H8.63341C11.3204 2 12.6639 2 13.4987 2.90243C14.3334 3.80486 14.3334 5.25729 14.3334 8.16216V8.5045C14.3334 11.4094 14.3334 12.8618 13.4987 13.7642C12.6639 14.6667 11.3204 14.6667 8.63341 14.6667H7.36675C4.67974 14.6667 3.33624 14.6667 2.50149 13.7642C1.66675 12.8618 1.66675 11.4094 1.66675 8.5045V8.16216Z"
                    stroke="#DE8D8D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2 5.33301H14"
                    stroke="#DE8D8D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
              type="date"
              label="Tanggal (Selesai)"
              isOutlined={true}
              isPrimary={false}
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
        </div>
        <div className="height-15"></div>
        <div className="primary-button" onClick={() => setOpen(false)}>
          Tampilkan
        </div>
      </BottomSheet>
    </div>
  );
}

export default TransactionPage;
interface TransactionModel {
  status?: string;
  isReviewed?: boolean;
}

interface orderProps {
  orders: OrderPagination;
}

function BerlangsungTab(props: orderProps) {
  const orders = props.orders;
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState<TransactionModel[]>([]);
  return (
    <>
      {orders?.data?.length == 0 ? (
        <EmptyState
          icon={emptyTrx}
          title="Belum Ada Transaksi"
          subtitle="Ayo, belanja dan beli produk<br/>unggulan kami sekarang"
        />
      ) : (
        orders?.data?.map((order, index) => (
          <TransactionsCard
            data={order}
            onClick={() =>
              navigate(routes.transactionDetail, {
                state: {
                  order: order,
                  status: statusMap[order.status ?? "processed"],
                },
              })
            }
          />
        ))
      )}
    </>
  );
}
function BayarTab(props: orderProps) {
  const orders = props.orders;
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState<TransactionModel[]>([]);
  return (
    <>
      {orders?.data?.length == 0 ? (
        <EmptyState
          icon={emptyTrx}
          title="Belum Ada Transaksi"
          subtitle="Ayo, belanja dan beli produk<br/>unggulan kami sekarang"
        />
      ) : (
        orders?.data?.map((order, index) => (
          <TransactionsCard
            data={order}
            onClick={() =>
              navigate(routes.transactionDetail, {
                state: {
                  order: order,
                  status: statusMap[order.status ?? "processed"],
                },
              })
            }
          />
        ))
      )}
    </>
  );
}
function BatalTab(props: orderProps) {
  const orders = props.orders;
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState<TransactionModel[]>([]);
  return (
    <>
      {orders?.data?.length == 0 ? (
        <EmptyState
          icon={emptyTrx}
          title="Belum Ada Transaksi"
          subtitle="Ayo, belanja dan beli produk<br/>unggulan kami sekarang"
        />
      ) : (
        orders?.data?.map((order, index) => (
          <TransactionsCard
            data={order}
            onClick={() =>
              navigate(routes.transactionDetail, {
                state: {
                  order: order,
                  status: statusMap[order.status ?? "processed"],
                },
              })
            }
          />
        ))
      )}
    </>
  );
}
function SelesaiTab(props: orderProps) {
  const orders = props.orders;
  const navigate = useNavigate();
  return (
    <>
      {orders?.data?.length == 0 ? (
        <EmptyState
          icon={emptyTrx}
          title="Belum Ada Transaksi"
          subtitle="Ayo, belanja dan beli produk<br/>unggulan kami sekarang"
        />
      ) : (
        orders?.data?.map((order, index) => (
          <TransactionsCard
            data={order}
            onClick={() =>
              navigate(routes.transactionDetail, {
                state: {
                  order: order,
                  status: statusMap[order.status ?? "processed"],
                },
              })
            }
          />
        ))
      )}
    </>
  );
}

interface orderCardProps {
  onClick?: () => void;
  data?: Order;
}
interface StatusModel {
  text: string;
  icon: string;
  label: string;
}

const statusMap: { [key: string]: StatusModel } = {
  processed: {
    text: "Pesanan di proses",
    icon: processIcon,
    label: "Diproses",
  },
  need_process: {
    text: "Pesanan di menunggu diproses",
    icon: processIcon,
    label: "Menunggu Diproses",
  },
  sending: {
    text: "Pesanan sedang dalam proses pengiriman",
    icon: truckDelivery,
    label: "Dikirim",
  },
  unpaid: {
    text: "Pesanan menunggu dibayar",
    icon: wallet,
    label: "Menunggu Pembayaran",
  },
  cancel_wait: {
    text: "Menunggu proses pembatalan",
    icon: document,
    label: "Pengajuan Pembatalan",
  },
  cancelled: {
    text: "Pesanan dibatalkan oleh MamaBear",
    icon: document,
    label: "Dibatalkan",
  },
  finished: {
    text: "Pesanan belum diulas",
    icon: star,
    label: "Diterima",
  },
};

function TransactionsCard(props: orderCardProps) {
  const orderDate = moment(props.data?.created_at ?? new Date())
    .add(7, "hours")
    .format("DD MMMM YYYY");

  const expiredDate = moment(
    props.data?.order_payment?.payment_gateway_detail.expired_at ?? new Date()
  )
    .add(7, "hours")
    .format("DD MMMM YYYY, HH:mm");

  const numberWithCommas = (value: number) => {
    return new Intl.NumberFormat("id-ID").format(value);
  };

  return (
    <div className="trx-card" onClick={props.onClick}>
      <div className="trx-header">
        <div className="column expanded">
          <div className="title">#{props.data?.code}</div>
          <div className="subtitle">{orderDate}</div>
        </div>
        <div className="status">
          {props.data?.is_complained
            ? "Komplain"
            : statusMap[props?.data?.status ?? "processed"].label}
        </div>
      </div>
      <div className="divider"></div>
      <div className="trx-body">
        <figure>
          <img
            src={
              props.data?.order_products?.[0]?.product_json?.image_url_text ??
              ""
            }
          />
        </figure>
        <div className="column expanded">
          <div className="title">
            {props.data?.order_products?.[0].product_json.name}
          </div>
          {props.data?.status?.includes("unpaid") ? (
            <div className="text">
              {props.data?.order_payment?.payment_gateway_detail?.metadata
                ?.va_number ?? "80771298378281902"}
            </div>
          ) : null}
          <div className="row">
            <div className="subtitle expanded">
              Rp{" "}
              <span>
                {numberWithCommas(
                  props.data?.order_products?.[0].product_json.price ?? 0
                )}
              </span>
            </div>
            <span className="count">
              x {props.data?.order_products?.[0].qty} Barang
            </span>
          </div>
        </div>
      </div>
      <div className="divider"></div>
      <div
        className={`trx-footer${
          props.data?.status?.includes("unpaid") ? " no-arrow" : ""
        }`}
      >
        {
          <img
            src={statusMap[props?.data?.status ?? "processed"].icon}
            className="icon"
          />
        }
        <div className="status">
          {props.data?.order_reviewed
            ? "Pesanan Sudah Diulas"
            : props.data?.is_complained
            ? "Pesanan Sedang Proses Komplain"
            : statusMap[props?.data?.status ?? "processed"].text}
        </div>
        {props.data?.status?.includes("unpaid") ? (
          <div className="time-frame">{expiredDate}</div>
        ) : null}
      </div>
    </div>
  );
}
