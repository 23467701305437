import { useEffect, useState } from "react";
import ProductModel from "../models/product_model";
import { useLocation, useNavigate } from "react-router-dom";
import star from "../../../assets/images/ic_star.svg";
import liked from "../../../assets/images/ic_heart-fill.svg";
import notLiked from "../../../assets/images/ic_heart.svg";
import back from "../../../assets/images/ic_arrow-left.svg";
import icChevron from "../../../assets/images/ic_chevron-down.svg";
import cart from "../../../assets/images/bag.svg";
import dummyProduct1 from "../../../assets/images/dummy-product-1.png";
import dummyProduct2 from "../../../assets/images/dummy-product-2.png";
import dummyProduct3 from "../../../assets/images/dummy-product-3.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BottomSheet from "../../shared/components/bottom_sheet";
import routes from "../../../routes";
import productFacade from "../facade/product_facade";
import moment from "moment";
import "moment/locale/id";
import { CartForm } from "../../order/store/cart_store";
import cartFacade from "../../order/facades/cart_facade";
import { Cart } from "../../order/models/cart_model";
import CartIcon from "../../global_component/view/cart_icon";
import authFacade from "../../authentication/facades/auth_facade";
import Toast from "../../../utils/toast";

function ProductDetail() {
  const {
    productDetail,
    productReviews,
    recommendations,
    fetchProductDetail,
    fetchProductReviews,
    likeProduct,
    fetchRecommendations,
  } = productFacade();

  const { storeCart, updateCart } = cartFacade();
  const { isAuthenticated } = authFacade();

  const location = useLocation();
  const [products, setProducts] = useState<ProductModel[]>([]);
  const [cartForm, setCartForm] = useState<CartForm>();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(false);
  const [count, setCount] = useState(productDetail?.stock ? 1 : 0);

  const [selectedCarts, setSelectedCarts] = useState<Cart[]>([]);

  const firstReview = productReviews.data?.[0];

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  const numberWithCommas = (value: number) => {
    return new Intl.NumberFormat("id-ID").format(value);
  };

  const handleMinus = () => {
    if (count > 0) {
      setCount(count - 1);
      setCartForm({
        product_id: productDetail?.id,
        qty: count - 1,
        product: productDetail,
      });
    }
  };

  const handlePlus = () => {
    if (productDetail?.stock) {
      if (count < productDetail?.stock) {
        setCount(count + 1);
        setCartForm({
          product_id: productDetail?.id,
          qty: count + 1,
          product: productDetail,
        });
      }
    }
  };

  // Handle Add To Cart
  const handleAddToCart = (isBuy: boolean) => {
    // Handle Authenticated
    if (isAuthenticated) {
      storeCart(cartForm ?? {}).then((resStore) => {
        if (resStore.status) {
          resStore.body.qty = count;
          if (isBuy) {
            updateCart({
              product_id: productDetail?.id,
              qty: count,
            }).then((resUpdate) => {
              if (resUpdate) {
                navigate(routes.checkout, {
                  state: { carts: [resStore.body] },
                });
              }
            });
          } else {
            Toast.show("Berhasil menambahkan produk ke keranjang", {});
            navigate(routes.cart);
          }
        } else {
          setOpen(false);
        }
      });
    } else {
      // Handle Unauthenticated
      if (isBuy) {
        navigate(routes.checkout, { state: { carts: [cartForm] } });
      } else {
        let unauthCarts = JSON.parse(
          localStorage.getItem("unauthCarts") ?? "[]"
        ) as CartForm[];

        let hasSame = false;
        unauthCarts.map(function (unauthCart) {
          if (unauthCart.product_id == cartForm?.product_id) {
            if (
              cartForm != undefined &&
              cartForm.qty != undefined &&
              unauthCart.qty != undefined
            ) {
              const newQty = unauthCart.qty + cartForm?.qty;
              unauthCart.qty = newQty;
              hasSame = true;
            }
          }
        });

        if (hasSame) {
          localStorage.setItem("unauthCarts", JSON.stringify(unauthCarts));
        } else {
          localStorage.setItem(
            "unauthCarts",
            JSON.stringify([...unauthCarts, cartForm])
          );
        }

        navigate(routes.cart);
      }
    }
  };

  const handleLike = async (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    productId: number
  ) => {
    event.stopPropagation();
    await likeProduct(productId);
    fetchProductDetail(location.state.id ?? 0);
    fetchProductReviews(location.state.id ?? 0, { rating: 0, sorter: "" });
  };

  const truncatedDescription = productDetail?.description
    ? showMore
      ? productDetail?.description
      : `${productDetail?.description.slice(0, 200)}...`
    : "";

  const settings = {
    dots: true,
    autoplay: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    fetchProductDetail(location.state.id ?? 0);
    fetchProductReviews(location.state.id ?? 0, { rating: 0, sorter: "" });
    fetchRecommendations(location.state.category_id ?? 0);
    setCartForm({
      product_id: productDetail?.id,
      qty: count,
      product: productDetail,
    });
  }, []);

  return (
    <div className="product-detail">
      <div className="header">
        <img
          src={back}
          alt=""
          className="back-btn"
          onClick={() => navigate(-1)}
        />
        <CartIcon />
      </div>
      <div className="image-section">
        <Slider {...settings}>
          {productDetail?.product_cover_files?.map((data, index) => (
            <div className="image-wrapper">
              <img
                src={data}
                onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                  e.currentTarget.src = dummyProduct1;
                }}
              />
            </div>
          ))}
        </Slider>
      </div>
      <div className="content">
        <div className="info">
          <div className="rating">
            <img src={star} alt="" className="star" />
            <p>
              {productDetail?.rating}{" "}
              <span>({productDetail?.reviews_count} Ulasan)</span>
            </p>
            <img
              src={productDetail?.user_liked_product ? liked : notLiked}
              alt=""
              onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                handleLike(event, productDetail?.id ?? 0)
              }
              className="like"
            />
          </div>
          <div className="tag">{productDetail?.category?.name ?? ""}</div>
          <div className="title">{productDetail?.name ?? ""}</div>
          <div className="price">
            <p>
              Rp
              <span>{numberWithCommas(productDetail?.price ?? 0)}</span>
            </p>
            <div className="discount">
              {/* <div className="tag">
                                50%
                            </div>
                            <span>
                                Rp 150.000
                            </span> */}
            </div>
          </div>
        </div>
        <hr />
        <div className="descriptions">
          <h6>Deskripsi</h6>
          <div className="text">
            <p dangerouslySetInnerHTML={{ __html: truncatedDescription }} />
            {productDetail?.description &&
              productDetail?.description.length > 100 && (
                <div className="show-more" onClick={handleShowMore}>
                  {showMore ? "Sembunyikan" : "Lihat Semua"}
                </div>
              )}
          </div>
        </div>
        <hr />
        <div className="review">
          <div
            className="review-header"
            onClick={() => {
              navigate(routes.reviewDetails, { state: location.state });
            }}
          >
            <h6>Ulasan Pembeli</h6>
            <img src={icChevron} alt="" />
          </div>
          {productReviews.data?.length == 0 ? (
            <div className="no-review">Belum ada ulasan</div>
          ) : (
            <div className="review-card">
              <div className="review-card-header">
                <div className="avatar">
                  {firstReview?.user?.name?.charAt(0)}
                </div>
                <div className="title">{firstReview?.user?.name}</div>
                <div className="rate">
                  <span>
                    {moment(firstReview?.created_at).locale("ID").fromNow()}
                  </span>
                  <div className="stars">
                    {Array.from({ length: firstReview?.rating ?? 0 }).map(
                      (_, index) => (
                        <span className="active" key={index}></span>
                      )
                    )}
                    {Array.from({ length: 5 - (firstReview?.rating ?? 0) }).map(
                      (_, index) => (
                        <span key={index}></span>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className="review-card-content">
                <div className="text">
                  <p>{firstReview?.description ?? ""}</p>
                  <div className="show-more">Lihat Semua</div>
                </div>
              </div>
              <div className="review-card-attachment">
                {firstReview?.order_product_review_images?.map(
                  (data, index) => (
                    <figure
                      {...{
                        text:
                          index == 5
                            ? `${
                                (firstReview?.order_product_review_images_count ??
                                  0) - 5
                              }+ Lainnya`
                            : null,
                      }}
                    >
                      <img src={data} alt="" />
                    </figure>
                  )
                )}
              </div>
            </div>
          )}
        </div>
        <div className="recommendations">
          <div className="recommendations-header">
            <h6>Rekomendasi Produk</h6>
            <img src={icChevron} alt="" />
          </div>
          <div className="grid">
            {recommendations.slice(0, 2).map((recommendation, index) => (
              <div
                className="items"
                onClick={() => {
                  // navigate("product-details", {state: data});
                }}
              >
                <figure>
                  <img
                    src={
                      recommendation.product?.image_url_text ?? dummyProduct1
                    }
                  />
                  <div
                    className={
                      recommendation.product?.user_liked_product ? "liked" : ""
                    }
                  ></div>
                  <span className="cart"></span>
                </figure>
                <div className="desc">
                  <div className="title">
                    {recommendation.product?.name ?? ""}
                  </div>
                  <div className="price">
                    Rp{" "}
                    <span>
                      {numberWithCommas(recommendation.product?.price ?? 0)}
                    </span>
                  </div>
                  {/* <div className="row crossAxisCenter">
                    <span className="discount">50%</span>
                    <div className="original-price">Rp 150.000</div>
                    <div className="rating">(4.2)</div>
                  </div> */}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="row bottom-action-button">
          <div
            className="secondary-button expanded"
            onClick={() => {
              setOpen(true);
            }}
          >
            + Keranjang
          </div>
          <div className="width-10"></div>
          <div
            className="primary-button expanded"
            onClick={() => setOpen(true)}
          >
            Beli Langsung
          </div>
        </div>
      </div>
      <BottomSheet
        open={open}
        onClose={() => setOpen(false)}
        title="Tambah Produk"
      >
        <div className="sort-filter">
          <h6>Tambah Produk</h6>
        </div>
        <div className="height-15"></div>
        <div className="preview-product">
          <figure>
            <img src={productDetail?.image_url_text} />
          </figure>
          <div className="info">
            <h6>{productDetail?.name}</h6>
            <div className="price">
              <span>{productDetail?.price_text}</span>
            </div>
            {/* <div className="discount">
              <div className="badge">50%</div>
              <span>Rp 150.000</span>
            </div> */}
            <div className="stock">
              <div className="badge">
                Stok Tersisa : {productDetail?.stock ?? "0"}
              </div>
              <div className="expanded"></div>
              <button onClick={handleMinus}></button>
              <div className="count">{count}</div>
              <button className="add" onClick={handlePlus}></button>
            </div>
          </div>
        </div>
        <div className="height-15"></div>
        <div className="row">
          <button
            className="secondary-button expanded"
            onClick={() => handleAddToCart(false)}
            disabled={count == 0}
          >
            + Keranjang
          </button>
          <div className="width-10"></div>
          <button
            className="primary-button expanded"
            onClick={() => handleAddToCart(true)}
            disabled={count == 0}
          >
            Beli Langsung
          </button>
        </div>
      </BottomSheet>
    </div>
  );
}

export default ProductDetail;
