import { useState, useEffect } from "react";
import TextInput from "../../../../shared/components/text_input";
import { useLocation, useNavigate } from "react-router-dom";
import back from "../../../../../assets/images/ic_arrow-left.svg";
import routes from "../../../../../routes";
import addressFacade from "../../../facades/address_facade";
import Toast from "../../../../../utils/toast";

function AddAddressPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const isFromCheckout = location.state?.fromCheckout ?? false;

  const [isPrimaryAddress, setIsPrimaryAddress] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { address, setAddressField, createAddress, updateAddress } =
    addressFacade();

  const handleGuest = () => {
    if (
      !address.receiver_name ||
      !address.phone_country_code ||
      !address.phone ||
      !address.city ||
      !address.full_address ||
      !address.latitude ||
      !address.longitude
    ) {
      const missingField = !address.receiver_name
        ? "Nama Penerima"
        : !address.phone_country_code
        ? "Kode Negara"
        : !address.phone
        ? "Nomor Telepon"
        : !address.city
        ? "Kota"
        : !address.full_address
        ? "Alamat Lengkap"
        : !address.latitude
        ? "Pin Lokasi"
        : !address.longitude
        ? "Pin Lokasi"
        : "";
      Toast.show(
        "Mohon lengkapi data alamat, data belum diisi : " + missingField,
        {}
      );
      return;
    }

    localStorage.setItem("address", JSON.stringify(address));
    navigate(routes.checkout, { state: { carts: location.state.carts } });
  };

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = event.target;
    setAddressField(name as keyof typeof address, value);
  };

  useEffect(() => {
    setIsPrimaryAddress((location?.state?.is_main ?? address.is_main) == 1);
    setAddressField(
      "is_main",
      (location?.state?.is_main ?? address.is_main) == 1 ? "1" : "0"
    );
    if (location?.state?.id != null) {
      setIsEdit(true);
    }
    if (location?.state?.receiver_name != null) {
      setAddressField("receiver_name", location.state.receiver_name);
    }
    if (location?.state?.label != null) {
      setAddressField("label", location.state.label);
    }
    if (location?.state?.phone_country_code != null) {
      setAddressField("phone_country_code", location.state.phone_country_code);
    }
    if (location?.state?.phone != null) {
      setAddressField("phone", location.state.phone);
    }
    if (location?.state?.city != null) {
      setAddressField("city", location.state.city);
    }
    if (location?.state?.sub_district != null) {
      setAddressField("sub_district", location.state.sub_district);
    }
    if (location?.state?.district != null) {
      setAddressField("sub_district", location.state.district);
    }
    if (location?.state?.full_address != null) {
      setAddressField("full_address", location.state.full_address);
    }
    if (location?.state?.address != null) {
      setAddressField("full_address", location.state.address);
    }
    if (location?.state?.note != null) {
      setAddressField("note", location.state.note);
    }
    if (location?.state?.latitude != null) {
      setAddressField("latitude", location.state.latitude);
    }
    if (location?.state?.longitude != null) {
      setAddressField("longitude", location.state.longitude);
    }
  }, []);

  return (
    <div className="base-page">
      <div className="header p25">
        <img
          src={back}
          alt=""
          className="back-btn"
          onClick={() => navigate(-1)}
        />

        <p className="title">{isEdit ? "Edit Alamat" : "Tambah Alamat"}</p>
      </div>
      <div className="content" style={{ gap: 25 }}>
        <TextInput
          label="Nama Penerima"
          placeholder="Masukkan nama lengkap"
          onChange={handleInputChange}
          name="receiver_name"
          value={address?.receiver_name || ""}
        />
        <TextInput
          label={!isFromCheckout ? "Nomor Telepon" : "Nomor Whatsapp"}
          placeholder={
            !isFromCheckout
              ? "Masukkan nomor telepon"
              : "Masukkan Nomor Whatsapp"
          }
          onChange={handleInputChange}
          name="phone"
          value={address?.phone || ""}
        />
        {!isFromCheckout && (
          <TextInput
            label="Label Alamat"
            placeholder="Cth. Rumah, Kantor, atau lainnya"
            onChange={handleInputChange}
            name="label"
            value={address?.label || ""}
          />
        )}
        {isFromCheckout && (
          <>
            <TextInput
              label="Kota"
              placeholder="Kota"
              onChange={handleInputChange}
              name="city"
              value={address?.city ?? ""}
            />
            <TextInput
              label="Alamat Lengkap"
              isMultiline={true}
              placeholder="Cth. Jln. No 12, Block A"
              onChange={handleInputChange}
              name="full_address"
              value={address?.full_address ?? ""}
            />
          </>
        )}
        <div className="column">
          <div className="pin-label">Pin Lokasi</div>
          <div
            className="pin-location"
            onClick={() =>
              navigate(routes.pinAddress, {
                state: {
                  isFromCheckout: isFromCheckout,
                  ...(isFromCheckout
                    ? { fromCheckout: true, carts: location.state.carts }
                    : {}),
                },
              })
            }
          >
            {(address?.latitude ?? "") != "" && (address.longitude ?? "") != ""
              ? "Ubah pin lokasi"
              : "Tambahkan pin lokasi"}
          </div>
        </div>

        {/* <TextInput label="Provinsi" placeholder="Provinsi" /> */}
        {!isFromCheckout && (
          <>
            <div className="row">
              <div className="expanded">
                <TextInput
                  label="Kecamatan"
                  placeholder="Kecamatan"
                  onChange={handleInputChange}
                  name="sub_district"
                  value={address?.sub_district ?? ""}
                />
              </div>
              <div className="width-10"></div>
              <div className="expanded">
                <TextInput
                  label="Kota"
                  placeholder="Kota"
                  onChange={handleInputChange}
                  name="city"
                  value={address?.city ?? ""}
                />
              </div>
            </div>
            <TextInput
              label="Alamat Lengkap"
              isMultiline={true}
              placeholder="Cth. Jln. No 12, Block A"
              onChange={handleInputChange}
              name="full_address"
              value={address?.full_address ?? ""}
            />
            <TextInput
              label="Catatan"
              placeholder="Cth. Rumah warna hijau atau lainnya"
              onChange={handleInputChange}
              name="note"
              value={address?.note ?? ""}
            />
            <div className="column" style={{ gap: 15 }}>
              <div className="check-row">
                <div
                  className={`check-box mr-10${
                    isPrimaryAddress ? " active" : ""
                  }`}
                  onClick={() => {
                    setIsPrimaryAddress(!isPrimaryAddress);
                    setAddressField(
                      "is_main" as keyof typeof address,
                      !isPrimaryAddress ? "1" : "0"
                    );
                  }}
                ></div>
                <p className="text">Jadikan alamat utama</p>
              </div>
              <div className="check-row">
                <div
                  className={`check-box mr-10${isAgree ? " active" : ""}`}
                  onClick={() => setIsAgree(!isAgree)}
                ></div>
                <p className="text">
                  Saya menyetujui
                  <span onClick={() => navigate(routes.termsCondition)}>
                    Syarat Ketentuan
                  </span>
                  &
                  <span onClick={() => navigate(routes.privacyPolicy)}>
                    Kebijakan Privasi
                  </span>
                </p>
              </div>
              <div
                className="primary-button"
                onClick={() => {
                  if (isAgree) {
                    (location?.state?.id != null
                      ? updateAddress(address, location?.state?.id)
                      : createAddress(address)
                    ).then((success) => {
                      if (success) {
                        navigate(routes.address, {
                          replace: true,
                        });
                      }
                    });
                  } else {
                    Toast.show(
                      "Anda harus menyetujui Syarat Ketentuan & Kebijakan Privasi Kami",
                      {}
                    );
                  }
                }}
              >
                Simpan
              </div>
            </div>
          </>
        )}

        {isFromCheckout && (
          <>
            <div className="column" style={{ gap: 15 }}>
              <div className="primary-button" onClick={handleGuest}>
                Simpan
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default AddAddressPage;
