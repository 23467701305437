import { useLocation, useNavigate } from "react-router-dom";
import back from "../../../assets/images/ic_arrow-left.svg";
import OrderSummary from "../components/order_summary";
import CartSummary from "../components/cart_summary";
import { useState, ReactNode } from "react";
import toast, { Toaster } from "react-hot-toast";

function guideAtm() {
  return (
    <ol type="1">
      <li>Masukkan Kartu ATM BCA & PIN</li>
      <li>
        {
          "Pilih menu Transaksi Lainnya > Transfer > ke Rekening BCA Virtual Accout"
        }
      </li>
      <li>
        Masukkan 5 angka kode perusahaan untuk Tokopedia (12345) dan Nomor HP
        yang terregister di akun Tokopedia Anda (Contoh: 1234508123719283).
      </li>
      <li>
        Di halaman konfirmasi, pastikan detil pembayaran sudah sesuai seperti No
        VA, Nama, Perusahaan / Produk dan Total Tagihan.
      </li>
      <li>Masukkan Jumlah Transfer sesuai dengan Total Tagihan.</li>
      <li>Ikuti instruksi untuk menyelesaikan transaksi.</li>
      <li>Simpan struk transaksi sebagai bukti pembayaran.</li>
    </ol>
  );
}

function guideMbanking() {
  return (
    <ol type="1">
      <li>Buka aplikasi Mobile Banking yang terkait.</li>
      <li>Pilih menu "Transfer" atau "Pembayaran".</li>
      <li>Pilih opsi "Virtual Account" atau "Pembayaran Tagihan".</li>
      <li>
        Masukkan kode perusahaan dan nomor Virtual Account yang Anda dapatkan.
      </li>
      <li>Verifikasi rincian pembayaran dan pastikan semuanya benar.</li>
      <li>Masukkan jumlah pembayaran sesuai dengan tagihan.</li>
      <li>
        Lanjutkan dengan instruksi yang diberikan untuk menyelesaikan transaksi.
      </li>
      <li>Simpan bukti pembayaran yang diberikan oleh aplikasi.</li>
    </ol>
  );
}

function guideInternetBanking() {
  return (
    <ol type="1">
      <li>Login ke platform Internet Banking bank Anda.</li>
      <li>Pilih menu "Transfer" atau "Pembayaran".</li>
      <li>Pilih opsi "Virtual Account" atau "Pembayaran Tagihan".</li>
      <li>
        Masukkan kode perusahaan dan nomor Virtual Account yang Anda dapatkan.
      </li>
      <li>Periksa kembali rincian pembayaran dan pastikan semuanya benar.</li>
      <li>Input jumlah pembayaran sesuai dengan tagihan.</li>
      <li>Ikuti instruksi yang diberikan untuk menyelesaikan transaksi.</li>
      <li>
        Simpan bukti pembayaran yang diberikan oleh platform Internet Banking.
      </li>
    </ol>
  );
}

function guideKantorBank() {
  return (
    <ol type="1">
      <li>Kunjungi kantor cabang bank terdekat.</li>
      <li>Ambil nomor antrian untuk layanan pembayaran.</li>
      <li>
        Setelah dipanggil, berikan informasi Virtual Account kepada petugas
        bank.
      </li>
      <li>Periksa rincian pembayaran yang ditampilkan oleh petugas bank.</li>
      <li>Bayar tagihan sesuai dengan jumlah yang ditentukan.</li>
      <li>
        Periksa dan simpan bukti pembayaran yang diberikan oleh petugas bank.
      </li>
    </ol>
  );
}

function PaymentGuidePage() {
  const navigate = useNavigate();
  const location = useLocation();

  const [payment, setPayment] = useState("BCA");

  return (
    <div className="base-page">
      <div className="header p25">
        <img
          src={back}
          alt=""
          className="back-btn"
          onClick={() => navigate(-1)}
        />
        <div className="title">Tata Cara Pembayaran</div>
      </div>
      <div className="content">
        <div className="cards">
          <Action
            label="Nomor Virtual Akun (BCA)"
            text={location.state?.va_number ?? ""}
            value={location.state?.va_number ?? ""}
          />
          <Action
            label="Total Tagihan"
            text={"Rp. " + location.state.final_amount.toString()}
            value={location.state.final_amount.toString()}
            actionText="Lihat Detail"
          />
        </div>
        <div className="cards">
          <Accordion title="ATM" children={guideAtm} />
          <Accordion title="Mobile Banking" children={guideMbanking} />
          <Accordion title="Internet Banking" children={guideInternetBanking} />
          <Accordion title="Kantor Bank" children={guideKantorBank} />
        </div>
      </div>
    </div>
  );
}

interface AccordionProps {
  children: () => ReactNode;
  title?: string;
}
function Accordion(props: AccordionProps) {
  const [active, setActive] = useState(false);
  return (
    <div className={"accordion" + (active ? " active" : "")}>
      <div className="accordion-header" onClick={() => setActive(!active)}>
        {props.title ?? "ATM BCA"}
      </div>
      <div className="accordion-content">
        {props.children() ?? (
          <ol type="1">
            <li>Masukkan Kartu ATM BCA & PIN</li>
            <li>
              {
                "Pilih menu Transaksi Lainnya > Transfer > ke Rekening BCA Virtual Accout"
              }
            </li>
            <li>
              Masukkan 5 angka kode perusahaan untuk Tokopedia (12345) dan Nomor
              HP yang terregister di akun Tokopedia Anda (Contoh:
              1234508123719283).
            </li>
            <li>
              Di halaman konfirmasi, pastikan detil pembayaran sudah sesuai
              seperti No VA, Nama, Perusahaan / Produk dan Total Tagihan.
            </li>
            <li>Masukkan Jumlah Transfer sesuai dengan Total Tagihan.</li>
            <li>Ikuti instruksi untuk menyelesaikan transaksi.</li>
            <li>Simpan struk transaksi sebagai bukti pembayaran.</li>
          </ol>
        )}
      </div>
    </div>
  );
}

interface ActionProps {
  label?: string;
  text?: string;
  value?: string;
  actionText?: string;
}

function Action(props: ActionProps) {
  function copyToClipboard(text: string): void {
    // Create a textarea element to hold the text
    const textarea = document.createElement("textarea");
    textarea.value = text;

    // Make sure it is out of view and small
    textarea.style.position = "fixed";
    textarea.style.opacity = "0";

    // Append the textarea to the DOM
    document.body.appendChild(textarea);

    // Select and copy the text
    textarea.select();
    document.execCommand("copy");
    toast.success("Text disalin");
    // Clean up: remove the textarea
    document.body.removeChild(textarea);
  }

  return (
    <div className="action">
      <label>{props.label}</label>
      <div className="frame">
        <span>{props.text}</span>
        <button
          className={props.actionText == null ? "icon" : ""}
          onClick={() => {
            if (props.actionText == null) {
              copyToClipboard(props.value ?? "");
            }
          }}
        >
          {props.actionText ?? "Salin"}
        </button>
      </div>
    </div>
  );
}

export default PaymentGuidePage;
