import { ReactNode, useEffect, useState } from "react";
import BottomSheet from "../../../shared/components/bottom_sheet";
import icon from "../../../../assets/images/cart_ilustration.svg";

interface CancelationReasonProp {
  open?: boolean;
  onClose?: () => void;
  onSubmit?: (reason: string) => void;
}

function CancelationReasonDialog(props: CancelationReasonProp) {
  const [reason, setReason] = useState("");

  return (
    <BottomSheet open={props.open} onClose={props.onClose} title="Konfirmasi">
      <div className="rate-list">
        {[
          "Ingin mengubah alamat pengiriman",
          "Ingin mengubah rincian & membuat pesanan baru",
          "Berubah pikiran",
          "Lainnya",
        ].map((val, i) => (
          <div className="rating" onClick={() => setReason(val)}>
            <div
              className={"check-box" + (val == reason ? " active" : "")}
            ></div>
            <span>{val}</span>
          </div>
        ))}
      </div>
      <div className="row">
        <div
          className="primary-button expanded"
          onClick={() => {
            props.onSubmit?.(reason);
            props.onClose?.();
          }}
        >
          Konfirmasi
        </div>
        <div className="width-10"></div>
        <div className="secondary-button expanded" onClick={props.onClose}>
          Batal
        </div>
      </div>
    </BottomSheet>
  );
}

export default CancelationReasonDialog;
