import { useLocation } from "react-router-dom";
import ArticleCards from "../../components/article-card";
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import { useState } from "react";
import { useArticles } from "./queries/use-articles";

export default function ArticleCategory() {
  const { state } = useLocation();
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const articles = useArticles(state.id, page, keyword);

  return (
    <div className="wrapper-pages">
      <Navbar />

      <div className="article-home-page">
        <div className="hero">
          <img src="images/article-hero.png" alt="" />
        </div>

        <div className="wrapper">
          <div className="container">
            <div className="top">
              <h1>
                Baca artikel menarik berisi update, tips dan berbagai informasi
                seputar {state?.name} dan lain-lain!
              </h1>
              <h2>
                <span>Disclaimer:</span> Blog ini ditulis sesuai perkembangan
                ilmu dan fakta terbaru tanpa bermaksud menyerang pihak siapapun
                termasuk orang tua yang memilih untuk tidak menyusui. MamaBear
                mendukung seluruh keputusan menyusui orang tua, baik dengan ASI
                atau susu formula, sebagai bagian dari preferensi pribadi
                masing-masing keluarga.
              </h2>
            </div>

            <div className="search-bar">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  // Add your logic here to handle form submission
                  setKeyword(e.target.elements.keyword.value);
                }}
              >
                <input type="text" name="keyword" placeholder="Cari artikel" />
                <button type="submit">
                  <img className="d-md-none" src="/svg/search-svg.svg" alt="" />
                  <img
                    className="d-none d-md-block"
                    src="/svg/search-svg-desktop.svg"
                    alt=""
                  />
                </button>
              </form>
            </div>

            <div className="article-card-wrapper no-carousel">
              <div className="top">
                <div>
                  <p className="">Kategori</p>
                  <h1>{state?.name}</h1>
                </div>
                <p>
                  Baca artikel terkait {state?.name}, dapatkan artikel menarik
                  lainnya dengan eksplor lebih lanjut
                </p>
                <button>
                  Lihat Semua
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.0002 12.5L4.00024 12.5"
                      stroke="white"
                      strokeWidth="0.75"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15 7.5L19.6464 12.1464C19.8131 12.3131 19.8964 12.3964 19.8964 12.5C19.8964 12.6036 19.8131 12.6869 19.6464 12.8536L15 17.5"
                      stroke="white"
                      strokeWidth="0.75"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>

              <div className="wrapper-card">
                {articles?.isSuccess && Array.isArray(articles?.data) &&
                  articles?.data.map((article, index) => (
                    <ArticleCards key={article.id} {...article} />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
