import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../../../../api/api-client";

const getRecommendationsFn = async () => {
  const response = await apiClient.get("/v1/landing-pages/recommendations");
  return response.data.data;
};

export function useRecommendations() {
  return useQuery({
    queryKey: ["useRecommendations"],
    queryFn: getRecommendationsFn,
  });
}
