import { useState, useEffect } from "react";
import beranda from "../../../../assets/images/beranda.svg";
import berandaActive from "../../../../assets/images/beranda_active.svg";
import transaksi from "../../../../assets/images/transaksi.svg";
import transaksiActive from "../../../../assets/images/transaksi_active.svg";
import favorit from "../../../../assets/images/favorit.svg";
import favoritActive from "../../../../assets/images/favorit_active.svg";
import pengaturan from "../../../../assets/images/pengaturan.svg";
import pengaturanActive from "../../../../assets/images/pengaturan_active.svg";

interface BotNavProps {
  activeMenu?: string;
  onChanged?: (val: string) => void;
}

function BottomNavigation(props: BotNavProps) {
  return (
    <div className="bottom-navigation">
      <div
        className={"menu" + (props.activeMenu == "beranda" ? " active" : "")}
        onClick={() => props.onChanged?.("beranda")}
      >
        {<img src={props.activeMenu == "beranda" ? berandaActive : beranda} />}
        <span>Beranda</span>
      </div>
      <div
        className={"menu" + (props.activeMenu == "transaksi" ? " active" : "")}
        onClick={() => props.onChanged?.("transaksi")}
      >
        {
          <img
            src={props.activeMenu == "transaksi" ? transaksiActive : transaksi}
          />
        }
        <span>Transaksi</span>
      </div>
      <div
        className={"menu" + (props.activeMenu == "favorit" ? " active" : "")}
        onClick={() => props.onChanged?.("favorit")}
      >
        {<img src={props.activeMenu == "favorit" ? favoritActive : favorit} />}
        <span>Favorit</span>
      </div>
      <div
        className={"menu" + (props.activeMenu == "pengaturan" ? " active" : "")}
        onClick={() => props.onChanged?.("pengaturan")}
      >
        {
          <img
            src={
              props.activeMenu == "pengaturan" ? pengaturanActive : pengaturan
            }
          />
        }
        <span>Pengaturan</span>
      </div>
    </div>
  );
}

export default BottomNavigation;
