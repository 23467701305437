import { shallow } from "zustand/shallow";
import productStore from "../store/product_store";

const productFacade = () => {
  const {
    products,
    productPopulars,
    productDetail,
    productReviews,
    recommendations,
    lastSeen,
    fetchProducts,
    fetchProductDetail,
    fetchProductReviews,
    fetchLikeProducts,
    likeProduct,
    fetchRecommendations,
    fetchLastSeen,
  } = productStore(
    (state) => ({
      products: state.products,
      productPopulars: state.productPopulars,
      productDetail: state.productDetail,
      productReviews: state.ProductReviews,
      recommendations: state.recommendations,
      lastSeen: state.lastSeen,
      fetchProducts: state.fetchProducts,
      fetchProductDetail: state.fetchProductDetail,
      fetchProductReviews: state.fetchProductReviews,
      fetchLikeProducts: state.fetchLikeProducts,
      likeProduct: state.likeProduct,
      fetchRecommendations: state.fetchRecommendations,
      fetchLastSeen: state.fetchLastSeen,
    }),
    shallow
  );

  return {
    products,
    productPopulars,
    productReviews,
    productDetail,
    recommendations,
    lastSeen,
    fetchProducts,
    fetchProductDetail,
    fetchProductReviews,
    fetchLikeProducts,
    likeProduct,
    fetchRecommendations,
    fetchLastSeen,
  };
};

export default productFacade;
