import React from "react";
import YouTube from "react-youtube";

export default class YoutubeVideo extends React.Component {
    render() {
        const opts = {
            height: "500px",
            width: "100%",
            playerVars: {
                autoplay: 1,
            },
        };

        return (
            <div className="video1">
                <YouTube videoId="nlHb9_2DICM" 
                    opts={opts} onReady={this._onReady} />
            </div>
        );
    }

    _onReady(event) {
        event.target.pauseVideo();
    }
}