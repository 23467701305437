import { useEffect, useState, MouseEventHandler } from "react";
import { useNavigate, useNavigation } from "react-router-dom";
import banner from "../../../../../assets/images/gold_member.svg";
import emptyVoucher from "../../../../../assets/images/empty_voucher.svg";
import emptyTransaction from "../../../../../assets/images/empty_transaction.svg";
import EmptyState from "../../../components/empty_state";
import VoucherItem from "../../../components/voucher_item";
import routes from "../../../../../routes";
import Net from "../../../../../utils/network_utils";
import membershipFacade from "../../../facades/membership_facade";
import { Voucher } from "../../../models/membership_model";
import profileFacade from "../../../facades/profile_facade";

import memberIron from "../../../../../assets/images/member_iron.svg";
import memberBronze from "../../../../../assets/images/member_bronze.svg";
import memberSilver from "../../../../../assets/images/member_silver.svg";
import memberGold from "../../../../../assets/images/member_gold.svg";
import memberPlatinum from "../../../../../assets/images/member_platinum.svg";
import memberDiamond from "../../../../../assets/images/member_diamond.svg";

interface PointModel {
  status?: string;
  amount?: string;
  type?: string;
}

function MembershipPage() {
  const navigate = useNavigate();
  const [activeTab, setAciveTab] = useState(0);
  const {
    availableVoucher,
    newVoucher,
    memberships,
    fetchVouchers,
    fetchMemberships,
    fetchVoucherAmount,
    fetchSavedTransactions,
  } = membershipFacade();

  const { user } = profileFacade();

  const [nextLevelRemaining, setNextLevelRemaining] = useState("");
  const [nextLevelMember, setNextLevelMember] = useState("");

  useEffect(() => {
    fetchMemberships();
    fetchVoucherAmount();
    fetchSavedTransactions();
    fetchVouchers().then((val) => {
      // setIsInitialized(true);
      setNextLevelRemaining(`${numberWithCommas(getNextLevelMember())}`);
    });
  }, [fetchVouchers]);
  // const { vouchers, fetchVouchers } = membershipFacade()

  // useEffect(
  //     () => {
  //         fetchVouchers();
  //     }
  // )
  const numberWithCommas = (value: number) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      currencyDisplay: "symbol", // Use 'code' for currency code (e.g., IDR)
    }).format(value);
  };

  function getNextLevelMember() {
    for (let i = 0; i < memberships.length; i++) {
      const member = memberships[i];
      if (
        (user.metadata?.transaction_amount_total ?? 0) <=
        (member.end_transaction_amount ?? 0)
      ) {
        var text = "";
        if (
          (user.metadata?.transaction_amount_total ?? 0) >=
          (member.start_transaction_amount ?? 0)
        ) {
          if (i < memberships.length - 1) {
            text = `lagi menuju ${memberships[i + 1].name.split(" ")[0]}`;
          } else {
            text = `lagi menuju ${member.name.split(" ")[0]}`;
          }
        } else {
          text = `lagi menuju ${member.name.split(" ")[0]}`;
        }
        setNextLevelMember(text);
        return (
          (member.end_transaction_amount ?? 0) -
          (user.metadata?.transaction_amount_total ?? 0)
        );
      }
    }
    return 1000;
  }

  return (
    <div className="membership-page">
      <div className="header">
        <svg
          onClick={() => navigate(-1)}
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 8.5L6 12.5M6 12.5L10 16.5M6 12.5L18 12.5"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        Membership
      </div>
      <div className="banner">
        <figure
          className={`membership ${
            (user.membership_level?.toLowerCase() ?? "iron").split(" ")[0] ??
            "iron"
          }`}
          onClick={() =>
            navigate(routes.listMembership, {
              state:
                (user.membership_level?.toLowerCase() ?? "iron").split(
                  " "
                )[0] ?? "iron",
            })
          }
        >
          <img
            src={
              {
                iron: memberIron,
                bronze: memberBronze,
                silver: memberSilver,
                gold: memberGold,
                platinum: memberPlatinum,
                diamond: memberDiamond,
              }[
                (user.membership_level?.toLowerCase() ?? "iron").split(" ")[0]
              ] ?? banner
            }
          />
          <div className="progress">
            <span
              style={{
                width: `${
                  (user.metadata?.transaction_amount_total ?? 10) / 100
                }%`,
              }}
            ></span>
          </div>
          <p>
            <span>{nextLevelRemaining}</span>
            {nextLevelMember}
          </p>
          <div className="deadline">
            Selesaikan misi sebelum 31 Desember {new Date().getFullYear()}!
          </div>
        </figure>
      </div>
      <div
        className="content"
        style={{ background: activeTab == 0 ? "#F1F1F1" : "#FFF" }}
      >
        <div className="tab-section">
          <div
            className={`expanded${activeTab == 0 ? " active" : ""}`}
            onClick={() => setAciveTab(0)}
          >
            <h5>
              <svg
                width="12"
                height="13"
                viewBox="0 0 12 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.30044 2.90032C3.12242 2.90032 2.94839 2.95312 2.80036 3.05202C2.65234 3.15093 2.53697 3.29151 2.46884 3.45598C2.40071 3.62046 2.38289 3.80144 2.41762 3.97605C2.45235 4.15065 2.53808 4.31104 2.66396 4.43692C2.78985 4.56281 2.95023 4.64853 3.12484 4.68327C3.29944 4.718 3.48043 4.70017 3.6449 4.63204C3.80938 4.56392 3.94996 4.44854 4.04886 4.30052C4.14777 4.1525 4.20056 3.97847 4.20056 3.80044C4.20056 3.56172 4.10573 3.33277 3.93692 3.16396C3.76812 2.99516 3.53917 2.90032 3.30044 2.90032ZM11.4735 5.7267L6.42685 0.674033C6.37078 0.618417 6.30428 0.574416 6.23117 0.544553C6.15806 0.51469 6.07977 0.499553 6.0008 0.50001H0.600089C0.440938 0.50001 0.288306 0.563232 0.175769 0.675769C0.0632324 0.788306 1.00299e-05 0.940938 1.00299e-05 1.10009V6.5008C-0.000446566 6.57977 0.0146905 6.65806 0.0445531 6.73117C0.0744158 6.80428 0.118417 6.87078 0.174033 6.92685L5.2267 11.9735C5.56424 12.3106 6.0218 12.5 6.49886 12.5C6.97593 12.5 7.43348 12.3106 7.77103 11.9735L11.4735 8.30103C11.8106 7.96349 12 7.50593 12 7.02887C12 6.5518 11.8106 6.09424 11.4735 5.7567V5.7267ZM10.6274 7.41892L6.91892 11.1214C6.80649 11.2332 6.65439 11.2959 6.49586 11.2959C6.33733 11.2959 6.18524 11.2332 6.07281 11.1214L1.20017 6.25477V1.70017H5.75477L10.6274 6.57281C10.683 6.62888 10.727 6.69538 10.7569 6.76849C10.7867 6.8416 10.8019 6.91989 10.8014 6.99886C10.8008 7.15629 10.7383 7.30715 10.6274 7.41892Z"
                  fill="currentColor"
                />
              </svg>
              Voucher
            </h5>
            <span>{newVoucher} Baru</span>
            <p>
              {availableVoucher == 0
                ? "Tidak ada voucher tersedia"
                : `${availableVoucher} Voucher Tersedia`}
            </p>
          </div>
          <div
            className={`expanded${activeTab == 1 ? " active" : ""}`}
            onClick={() => setAciveTab(1)}
          >
            <h5>
              <svg
                width="12"
                height="13"
                viewBox="0 0 12 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5 2.5H9V2C9 1.86739 8.94732 1.74021 8.85355 1.64645C8.75979 1.55268 8.63261 1.5 8.5 1.5H3.5C3.36739 1.5 3.24021 1.55268 3.14645 1.64645C3.05268 1.74021 3 1.86739 3 2V2.5H1.5C1.36739 2.5 1.24021 2.55268 1.14645 2.64645C1.05268 2.74021 1 2.86739 1 3V4.5C1 5.03043 1.21071 5.53914 1.58579 5.91421C1.96086 6.28929 2.46957 6.5 3 6.5H3.77C4.22227 7.00457 4.83203 7.34118 5.5 7.455V8.5H5C4.60218 8.5 4.22064 8.65804 3.93934 8.93934C3.65804 9.22064 3.5 9.60218 3.5 10V11C3.5 11.1326 3.55268 11.2598 3.64645 11.3536C3.74021 11.4473 3.86739 11.5 4 11.5H8C8.13261 11.5 8.25979 11.4473 8.35355 11.3536C8.44732 11.2598 8.5 11.1326 8.5 11V10C8.5 9.60218 8.34196 9.22064 8.06066 8.93934C7.77936 8.65804 7.39782 8.5 7 8.5H6.5V7.455C7.16797 7.34118 7.77773 7.00457 8.23 6.5H9C9.53043 6.5 10.0391 6.28929 10.4142 5.91421C10.7893 5.53914 11 5.03043 11 4.5V3C11 2.86739 10.9473 2.74021 10.8536 2.64645C10.7598 2.55268 10.6326 2.5 10.5 2.5ZM3 5.5C2.73478 5.5 2.48043 5.39464 2.29289 5.20711C2.10536 5.01957 2 4.76522 2 4.5V3.5H3V4.5C3.0011 4.84085 3.06028 5.17903 3.175 5.5H3ZM7 9.5C7.13261 9.5 7.25979 9.55268 7.35355 9.64645C7.44732 9.74021 7.5 9.86739 7.5 10V10.5H4.5V10C4.5 9.86739 4.55268 9.74021 4.64645 9.64645C4.74021 9.55268 4.86739 9.5 5 9.5H7ZM8 4.5C8 5.03043 7.78929 5.53914 7.41421 5.91421C7.03914 6.28929 6.53043 6.5 6 6.5C5.46957 6.5 4.96086 6.28929 4.58579 5.91421C4.21071 5.53914 4 5.03043 4 4.5V2.5H8V4.5ZM10 4.5C10 4.76522 9.89464 5.01957 9.70711 5.20711C9.51957 5.39464 9.26522 5.5 9 5.5H8.825C8.93972 5.17903 8.9989 4.84085 9 4.5V3.5H10V4.5Z"
                  fill="currentColor"
                />
              </svg>
              Hemat
            </h5>
            <span>
              {numberWithCommas(user.metadata?.transaction_amount_saved ?? 0)}
            </span>
            <p>Berhasil di hemat</p>
          </div>
        </div>
        {activeTab == 0 ? <VoucherTab /> : <PointTab />}
      </div>
    </div>
  );
}

function VoucherTab() {
  const { vouchers, storeVoucher, fetchVouchers } = membershipFacade();
  function handlerVoucher(user_voucher_id?: number) {
    if (user_voucher_id) {
      storeVoucher(user_voucher_id).then((val) => {
        fetchVouchers();
      });
    }
  }

  return (
    <>
      {Array.from(vouchers).every(
        ([key, voucherArray]) => voucherArray.length === 0
      ) ?? false ? (
        <EmptyState
          icon={emptyVoucher}
          title="Voucher Tidak Tersedia"
          subtitle="Anda tidak memiliki voucher saat ini"
        />
      ) : (
        Object.entries({
          discount: "Voucher Diskon",
          free_delivery: "Voucher Gratis Ongkir",
          cashback: "Voucher Cashback",
        }).map(([key, section], index) =>
          (vouchers.get(key)?.length ?? 0) == 0 ? null : (
            <>
              <div key={index} className="list-header">
                <div className="row crossAxisCenter">
                  <div className="expanded column">
                    <div className="title">{section}</div>
                    <div className="subtitle">Lihat semua voucher anda.</div>
                  </div>
                  {(vouchers.get(key)?.length ?? 0) == 15 ? (
                    <div className="view-all">Lihat semua</div>
                  ) : null}
                </div>
              </div>

              {(vouchers.get(key) ?? []).map((data, i) => {
                // console.log(data);
                return (
                  <VoucherItem
                    isFromMembership={true}
                    voucher={data}
                    key={data.id}
                    isClaimed={data?.claimed_at}
                    isFlexible={false}
                    onActionTap={() => {
                      handlerVoucher(data.id);
                    }}
                  ></VoucherItem>
                );
              })}
            </>
          )
        )
      )}
    </>
  );
}

function PointTab() {
  const { savedTransactions } = membershipFacade();

  return (
    <>
      {savedTransactions.length == 0 ? (
        <EmptyState
          icon={emptyTransaction}
          title="Tidak Ada Transaksi"
          subtitle="Belum ada riwayat mama poin"
        />
      ) : (
        <>
          <div className="list-header">
            <div className="title">Riwayat Transaksi</div>
            <div className="subtitle">Lihat semua riwayat transaksi anda.</div>
          </div>
          {savedTransactions.map((trx, index) => (
            <div key={index} className="point-item">
              <div className="expanded">
                <div className="title">{trx.order?.code ?? ""}</div>
                <div className="subtitle">{"13 September 2023, 21:40"}</div>
              </div>
              <div className="points">
                <span className="status">{"Hemat"}</span>
                <span>{trx.saved_amount}</span>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
}

export default MembershipPage;
