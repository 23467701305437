import { useLocation, useNavigate } from "react-router-dom";
import back from "../../../assets/images/ic_arrow-left.svg";
import OrderSummary from "../components/order_summary";
import CartSummary from "../components/cart_summary";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import routes from "../../../routes";
import { OrderModel } from "../models/order_model";
import orderFacade from "../facades/order_facade";
import moment from "moment";
import "moment/locale/id";

function PaymentPage() {
  const { order } = orderFacade();

  const navigate = useNavigate();
  const [countdown, setCountdown] = useState<number>(0);

  const numberWithCommas = (value: number) => {
    return new Intl.NumberFormat("id-ID").format(value);
  };

  const [payment, setPayment] = useState("BCA");

  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment();
      const expiry = moment
        .utc(order.data?.order_payment?.payment_gateway_detail.expired_at)
        .utcOffset("+07:00");
      const diff = expiry.diff(now, "seconds");

      setCountdown(diff);
    }, 1000);

    return () => clearInterval(interval);
  }, [order]);

  return (
    <div className="base-page">
      <div className="header p25">
        <img
          src={back}
          alt=""
          className="back-btn"
          onClick={() => navigate(routes.home)}
        />
        <div className="title">Pembayaran</div>
      </div>
      <div className="content" style={{ paddingBottom: 150 }}>
        <div className="payment-limit">
          <h5>Batas akhir pembayaran</h5>
          <div className="countdown">
            <span>{Math.floor(countdown / 3600)}</span>
            <span>:</span>
            <span>{Math.floor((countdown % 3600) / 60)}</span>
            <span>:</span>
            <span>{countdown % 60}</span>
          </div>
          <p>
            {moment
              .utc(
                order?.data?.order_payment?.payment_gateway_detail?.expired_at
              )
              .utcOffset("+07:00")
              .format("dddd, DD MMMM YYYY - HH:mm")}{" "}
            WIB
          </p>
        </div>
        <div className="cards">
          <Action
            label={
              "Nomor Virtual Akun (" +
              order?.data?.order_payment?.payment_gateway_detail.metadata.bank.toUpperCase() +
              ")"
            }
            text={
              order?.data?.order_payment?.payment_gateway_detail.metadata
                .va_number
            }
            value={
              order?.data?.order_payment?.payment_gateway_detail.metadata
                .va_number
            }
          />
          <Action
            label="Total Tagihan"
            text={
              "Rp. " +
              numberWithCommas(order?.data?.order_payment?.final_amount ?? 0)
            }
            value={order?.data?.order_payment?.final_amount.toString()}
          />
          <div
            className="secondary-button"
            style={{ marginTop: 15 }}
            onClick={() =>
              navigate(routes.paymentGuide, {
                state: {
                  va_number:
                    order?.data?.order_payment?.payment_gateway_detail.metadata
                      .va_number,
                  final_amount: order?.data?.order_payment?.final_amount,
                },
              })
            }
          >
            Baca Tata Cara Pembayaran
          </div>
        </div>
        <OrderSummary
          data={
            new Map([
              [
                "Sub Total",
                "Rp " +
                  numberWithCommas(
                    order?.data?.order_payment?.items_amount ?? 0
                  ),
              ],
              [
                "Ongkos Kirim",
                "Rp " +
                  numberWithCommas(
                    order?.data?.order_payment?.delivery_fee ?? 0
                  ),
              ],
              [
                "Biaya Aplikasi",
                "Rp " +
                  numberWithCommas(
                    order?.data?.order_payment?.application_fee ?? 0
                  ),
              ],
              [
                "Diskon",
                "-Rp " +
                  numberWithCommas(
                    order?.data?.order_payment?.discount_amount ?? 0
                  ),
              ],
              [
                "Cashback",
                "-Rp " +
                  numberWithCommas(
                    order?.data?.order_payment?.cashback_amount ?? 0
                  ),
              ],
              [
                "MamaPoint",
                "-Rp " +
                  numberWithCommas(
                    order?.data?.order_payment?.point_amount ?? 0
                  ),
              ],
            ])
          }
          total={
            "Rp " +
            numberWithCommas(order?.data?.order_payment?.final_amount ?? 0)
          }
        />
      </div>
      <div
        className="summary-button"
        style={{
          gap: "10px",
          left: "0px",
          right: "0px",
          bottom: "0px",
          display: "flex",
          padding: "25px",
          position: "absolute",
          background: "#FFF",
          alignItems: "stretch",
          flexDirection: "column",
          boxShadow: "0px -3px 8px 0px rgba(168, 157, 157, 0.33)",
          justifyContent: "center",
        }}
      >
        <div className="row">
          <button
            style={{
              gap: "10px",
              border: "none",
              display: "flex",
              borderRadius: "7px",
              background: "#EAA",
              padding: "14px 10px",
              alignItems: "center",
              justifyContent: "center",
              color: "#FFF",
              fontSize: "16px",
              fontWeight: "500",
            }}
            onClick={() => navigate(routes.home)}
          >
            Lanjut Belanja
          </button>
          <button
            style={{
              gap: "10px",
              border: "none",
              display: "flex",
              borderRadius: "7px",
              background: "#EAA",
              padding: "14px 10px",
              alignItems: "center",
              justifyContent: "center",
              color: "#FFF",
              fontSize: "16px",
              fontWeight: "500",
              marginLeft: "20px",
            }}
            disabled={true}
          >
            Status Pembayaran
          </button>
        </div>
      </div>
    </div>
  );
}

interface ActionProps {
  label?: string;
  text?: string;
  value?: string;
}

function Action(props: ActionProps) {
  function copyToClipboard(text: string): void {
    // Create a textarea element to hold the text
    const textarea = document.createElement("textarea");
    textarea.value = text;

    // Make sure it is out of view and small
    textarea.style.position = "fixed";
    textarea.style.opacity = "0";

    // Append the textarea to the DOM
    document.body.appendChild(textarea);

    // Select and copy the text
    textarea.select();
    document.execCommand("copy");
    toast.success("Text disalin");
    // Clean up: remove the textarea
    document.body.removeChild(textarea);
  }

  return (
    <div className="action">
      <label>{props.label}</label>
      <div className="frame">
        <span>{props.text}</span>
        <button
          className="icon"
          onClick={() => {
            copyToClipboard(props.value ?? "");
          }}
        >
          Salin
        </button>
      </div>
    </div>
  );
}

export default PaymentPage;
