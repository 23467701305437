import { useEffect, useRef, useState } from "react";
import emptyIcon from "../../../assets/images/empty_product.svg";
import backIcon from "../../../assets/images/back.svg";
import searchIcon from "../../../assets/images/ic_search.svg";
import menuNotif from "../../../assets/images/ic_bell.svg";
import menuCart from "../../../assets/images/ic_bag.svg";
import icTimer from "../../../assets/images/ic_timer.svg";
import product1 from "../../../assets/images/product-1.png";
import product2 from "../../../assets/images/product-2.png";
import dummyProduct1 from "../../../assets/images/dummy-product-1.png";
import dummyProduct2 from "../../../assets/images/dummy-product-2.png";
import dummyProduct3 from "../../../assets/images/dummy-product-3.png";

import display1 from "../../../assets/images/display1.png";
import display2 from "../../../assets/images/display2.png";
import display3 from "../../../assets/images/display3.png";

import adsBanner from "../../../assets/images/ads-banner.png";

import catTea from "../../../assets/images/coffee.svg";
import catDrink from "../../../assets/images/milk.svg";
import catCookies from "../../../assets/images/grid-1.svg";
import catBundle from "../../../assets/images/element-equal.svg";
import catEssential from "../../../assets/images/dummy-product-3.png";

import ProductModel from "../../product/models/product_model";
import BottomSheet from "../../shared/components/bottom_sheet";
import TextInput from "../../shared/components/text_input";
import { useNavigate, Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import routes from "../../../routes";
import categoryFacade from "../facades/category_facade";
import productFacade from "../../product/facade/product_facade";
import CartIcon from "../../global_component/view/cart_icon";
import carouselFacade from "../facades/carousel_facade";
import posterFacade from "../facades/poster_facade";

function DashboardScreen() {
  const navigate = useNavigate();

  const { likeProduct } = productFacade();
  const [isFocused, setIsFocused] = useState(false);
  const [selectedPopular, setSelectedPopular] = useState("Almon Mix");
  const [sortFilter, setSortFilter] = useState("Ulasan Terbaik");
  const [open, setOpen] = useState(false);
  const { categories, fetchCategories } = categoryFacade();
  const { carousels, fetchCarousel } = carouselFacade();
  const { posters, fetchPoster } = posterFacade();

  const handleFocus = () => {
    // setIsFocused(true);
    navigate(routes.search);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: false,
    autoplay: true,
    autoplaySpeed: 3000,
    // pauseOnHover: true,
  };

  const manualFetchAll = async () => {
    await fetchCategories();
    await fetchCarousel();
    await fetchPoster();
  };

  useEffect(() => {
    manualFetchAll();
  }, []);

  const handleLike = async (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    productId: number
  ) => {
    event.stopPropagation();
    await likeProduct(productId);
    manualFetchAll();
  };

  return (
    <div className="dashboard">
      <div className="header">
        <div
          className={"search" + (isFocused ? " focused" : "")}
          style={{ marginTop: 3 }}
        >
          <input
            type="text"
            placeholder="Cari Produk di MamaBear"
            onFocus={handleFocus}
            // onBlur={handleBlur}
          />
          <img src={searchIcon} alt="" className="action" />
        </div>
        <div className="actions">
          <CartIcon />
          <img
            src={menuNotif}
            onClick={() => navigate(routes.notifications)}
            className="menu"
          />
        </div>
      </div>
      <div className="contents-wrapper">
        <div className="ads-banner">
          <Slider {...settings}>
            {posters.map((poster, i) => (
              <div className="ads-container">
                <img className="banner" src={poster.image_url_text} />
                <div className="display-products">
                  {carousels.map((carousel, index) => (
                    <div
                      className="item"
                      onClick={() => {
                        navigate(routes.productDetails, {
                          state: carousel.product,
                        });
                      }}
                    >
                      <figure>
                        <img src={carousel.image_url_text} alt="" />
                      </figure>
                      <div className="title">{carousel.name}</div>
                      <div className="text">{carousel.description}</div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="contents">
          <section className="category">
            {categories.map((category, idx) => (
              <div
                className="item"
                onClick={() =>
                  navigate(routes.search, {
                    state: { categoryId: category.id },
                  })
                }
              >
                <figure>
                  <img
                    src={category.image_url_text}
                    onError={(
                      e: React.SyntheticEvent<HTMLImageElement, Event>
                    ) => {
                      e.currentTarget.src = catTea;
                    }}
                    alt=""
                  />
                </figure>
                <span>{category.name}</span>
              </div>
            ))}
          </section>
          {categories.length == 0 ? (
            <div className="empty">
              <img src={emptyIcon} />
              <h5>Produk Tidak Ditemukan</h5>
              <p>
                Coba sesuaikan kata kunci
                <br />
                untuk menemukan apa yang anda cari
              </p>
            </div>
          ) : (
            <>
              {categories.map((category, idx) => (
                <>
                  <div className="section-title">
                    {category.products?.length ? category.name : ""}
                  </div>
                  {category.products?.length ? (
                    <div className="grid">
                      {category.products?.slice(0, 2).map((data, index) => {
                        return (
                          <div
                            className="items"
                            onClick={() => {
                              navigate(routes.productDetails, { state: data });
                            }}
                          >
                            <figure>
                              <img
                                src={data.image_url_text}
                                onError={(
                                  e: React.SyntheticEvent<
                                    HTMLImageElement,
                                    Event
                                  >
                                ) => {
                                  e.currentTarget.src = dummyProduct1;
                                }}
                              />
                              <div
                                className={
                                  data.user_liked_product ? "liked" : ""
                                }
                                onClick={(
                                  event: React.MouseEvent<
                                    HTMLDivElement,
                                    MouseEvent
                                  >
                                ) => handleLike(event, data.id ?? 0)}
                              ></div>
                              <span className="cart"></span>
                            </figure>
                            <div className="desc">
                              <div className="title">
                                {data.name ?? "Product Name"}
                              </div>
                              <div className="price">
                                <span>{data.price_text}</span>
                              </div>
                              <div className="row crossAxisCenter">
                                {/* <span className="discount">50%</span>
                        <div className="original-price">Rp 150.000</div> */}
                                <div className="rating">({data.rating})</div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ))}
            </>
          )}
        </div>
        <BottomSheet
          open={open}
          onClose={() => setOpen(false)}
          showAction={true}
        >
          <div className="sort-filter">
            <h6>Paling Populer</h6>
            <div className="sort-by">
              {[
                "Paling Sesuai",
                "Terbaru",
                "Ulasan Terbaik",
                "Terlaris",
                "Harga Tertinggi",
                "Harga Terendah",
              ].map((data, index) => (
                <span
                  className={sortFilter == data ? "active" : ""}
                  onClick={function () {
                    setSortFilter(data);
                  }}
                >
                  {data}
                </span>
              ))}
            </div>
          </div>
          <div className="height-15"></div>
          <div className="row">
            <div className="expanded">
              <TextInput
                isOutlined={true}
                isObsecured={false}
                label={"Harga (Min)"}
                placeholder={"Rp"}
              />
            </div>
            <div className="width-10"></div>
            <div className="expanded">
              <TextInput
                isOutlined={true}
                isObsecured={false}
                label={"Harga (Maks)"}
                placeholder={"Rp"}
              />
            </div>
          </div>
          <div className="height-15"></div>
          <div className="primary-button" onClick={() => setOpen(false)}>
            Daftar
          </div>
        </BottomSheet>
      </div>
    </div>
  );
}

export default DashboardScreen;
