import { create } from "zustand";
import ProductModel, {
  Product,
  ProductPagination,
} from "../models/product_model";
import { createJSONStorage, persist } from "zustand/middleware";
import Network from "../../../utils/network_utils";
import Api from "../../../endpoint/api";
import Toast from "../../../utils/toast";
import {
  ProductDetailModel,
  Recommendation,
} from "../models/product_detail_model";
import { ProductReviewPagination } from "../models/product_review_model";

type ProductStore = {
  products: ProductPagination;
  productPopulars: ProductPagination;
  productDetail: ProductDetailModel;
  ProductReviews: ProductReviewPagination;
  lastSeen: Product[];
  recommendations: Recommendation[];
  fetchProducts: ({
    keyword,
    sorter,
    minPrice,
    maxPrice,
    categoryId,
    isPopular,
  }: {
    keyword?: string;
    sorter?: string;
    minPrice?: number;
    maxPrice?: number;
    categoryId?: number | null;
    isPopular?: boolean;
  }) => void;
  fetchProductDetail: (productId: number) => void;
  fetchProductReviews: (
    productId: number,
    {
      sorter,
      rating,
      is_file,
    }: { sorter?: string; rating?: number; is_file?: boolean }
  ) => void;
  fetchLikeProducts: ({ keyword }: { keyword?: string }) => void;
  likeProduct: (productId: number) => Promise<boolean>;
  fetchRecommendations: (category_id: number) => void;
  fetchLastSeen: () => void;
};

const productStore = create<ProductStore>()(
  persist(
    (set, get) => ({
      products: {},
      productPopulars: {},
      productDetail: {},
      ProductReviews: {},
      recommendations: [],
      lastSeen: [],
      fetchProducts: async ({
        keyword,
        sorter,
        minPrice,
        maxPrice,
        categoryId,
        isPopular,
      }) => {
        if (isPopular) {
          sorter = "best_rating";
        }

        const res = await Network.get(Api.product, {
          useToken: true,
          queryParameters: {
            keyword: keyword,
            order_by: sorter,
            price_min: minPrice,
            price_max: maxPrice,
            category_id: categoryId,
          },
        });
        if (res?.ok ?? false) {
          const body = await res!.json();
          const products = body;
          set({ products: products });

          if (isPopular) {
            set({ productPopulars: products });
          }
        }
      },
      fetchProductDetail: async (productId) => {
        const res = await Network.get(
          Api.productDetail.replace(":id", productId.toString()),
          { useToken: true }
        );
        if (res?.ok ?? false) {
          const body = await res!.json();
          const productDetail = body["data"];
          set({ productDetail: productDetail });
        }
      },
      fetchProductReviews: async (productId, { sorter, rating, is_file }) => {
        const res = await Network.get(
          Api.productReviews.replace(":id", productId.toString()),
          {
            useToken: true,
            queryParameters: {
              sort_by: sorter,
              rating: rating,
              is_file: is_file ? 1 : 0,
            },
          }
        );
        if (res?.ok ?? false) {
          const body = await res!.json();
          const productReviews = body;
          set({ ProductReviews: productReviews });
        }
      },
      fetchLikeProducts: async ({ keyword }) => {
        const res = await Network.get(Api.likedProduct, {
          useToken: true,
          queryParameters: { keyword: keyword },
        });
        if (res?.ok ?? false) {
          const body = await res!.json();
          const products = body;
          set({ products: products });
        }
      },
      likeProduct: async (productId) => {
        const res = await Network.post(Api.likedProduct, {
          data: { product_id: productId },
        });
        if (res?.ok ?? false) {
          const body = await res!.json();
          Toast.show(body["message"], {});
          return true;
        }

        return false;
      },

      fetchRecommendations: async (category_id: number) => {
        const res = await Network.get(Api.recommendations, {
          queryParameters: { category_id: category_id.toString() },
        });
        if (res?.ok ?? false) {
          const body = await res!.json();
          const recommendations = body["data"];
          set({ recommendations: recommendations });
        }
      },

      fetchLastSeen: async () => {
        const res = await Network.get(Api.lastSeen, { useToken: true });
        if (res?.ok ?? false) {
          const body = await res!.json();
          const lastSeen = body["data"];
          set({ lastSeen: lastSeen });
        }
      },
    }),
    {
      name: "product-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export default productStore;
