import { useState, useEffect, ReactNode } from "react";
import ProductModel from "../../product/models/product_model";

import CartItem from "../../order/components/cart_item";
import OrderSummary from "../../order/components/order_summary";
import { useLocation, useNavigate } from "react-router-dom";
import ConfirmationDialog from "./dialogs/confirmation_dialog";

import confirmCancel from "../../../assets/images/cart_ilustration_accepted.svg";
import confirmAccepted from "../../../assets/images/cart_ilustration_accepted.svg";
import confirmRating from "../../../assets/images/cart_ilustration_rating.svg";
import confirmCancelation from "../../../assets/images/confirm_cancelation.svg";
import TrxMoreOptionDialog from "./dialogs/trx_more_option_dialog";
import CancelationReasonDialog from "./dialogs/cancelation_reason_dialog";
import ComplaintDialog from "./dialogs/complaint_dialog";
import routes from "../../../routes";
import { Order } from "../../order/models/order_model";
import orderFacade from "../../order/facades/order_facade";
import moment from "moment";
import "moment/locale/id";
import Toast from "../../../utils/toast";

interface StatusModel {
  text: string;
  label: string;
}

function TransactionDetailPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const [status, setStatus] = useState<StatusModel>(location.state.status);

  const orderState = location.state.order as Order;

  const data = location.state ? location.state.data : null;

  const {
    order,
    fetchOrderDetail,
    cancelOrder,
    cancelOrderCancel,
    acceptOrder,
    complainOrder,
  } = orderFacade();

  const [useAccordion, setUseAccordion] = useState(false);
  const [transactions, setTransactions] = useState<ProductModel[]>([]);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [showAcceptConfirmation, setShowAcceptConfirmation] = useState(false);
  const [showRatingConfirmation, setShowRatingConfirmation] = useState(false);
  const [showMoreOption, setShowMoreOption] = useState(false);
  const [showReasonOption, setShowReasonOption] = useState(false);
  const [showComplaint, setShowComplaint] = useState(false);

  const [showCancelCancelation, setShowCancelCancelation] = useState(false);

  const orderDate = moment(order.data?.created_at ?? new Date())
    .add(7, "hours")
    .format("DD MMMM YYYY, HH:mm");

  const cancelOrderDate = moment(
    order.data?.order_cancel_detail?.created_at ?? new Date()
  )
    .add(7, "hours")
    .format("DD MMMM YYYY, HH:mm");

  const cancelOrderBefore = moment(
    order.data?.order_cancel_detail?.created_at ?? new Date()
  )
    .add(7, "days")
    .format("DD MMMM YYYY");

  const numberWithCommas = (value: number) => {
    return new Intl.NumberFormat("id-ID").format(value);
  };

  const totalAmount = numberWithCommas(
    order.data?.order_payment?.final_amount ?? 0
  );

  let totalWeight = 0;
  order.data?.order_products?.forEach((product) => {
    totalWeight += product.qty * product.product_json.weight;
  });

  useEffect(() => {
    fetchOrderDetail(orderState?.id ?? 0);

    setUseAccordion(
      status.label != "Diproses" && status.label != "Menunggu Diproses"
    );
  }, []);

  const handleCancel = (reason: string) => {
    cancelOrder({
      order_id: orderState.id,
      note: reason,
    }).then(() => navigate(-1));
  };

  const handleCancelOrderCancel = () => {
    cancelOrderCancel(orderState.id ?? 0).then(() => navigate(-1));
  };

  const handleAcceptOrder = () => {
    acceptOrder(orderState.id ?? 0).then((status) => {
      if (status) {
        setStatus({
          text: "Pesanan belum diulas",
          label: "Diterima",
        });
        setShowRatingConfirmation(true);
      } else {
        Toast.show("Gagal menerima pesanan", {});
      }
    });
  };

  const handleComplainOrder = (whatsapp: string) => {
    complainOrder({
      complain_type_id: 1,
      order_id: orderState?.id ?? 0,
      whatsapp: whatsapp,
    }).then((status) => {
      if (status) {
        navigate(-1);
      }
    });
  };

  return (
    <div className="page transaction-detail">
      <div className="header">
        <svg
          className="back"
          onClick={() => navigate(-1)}
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 8.5L6 12.5M6 12.5L10 16.5M6 12.5L18 12.5"
            stroke="#191919"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <p>Detail Pesanan</p>
        {!["Menunggu Pembayaran"].includes(status.label) && (
          <span
            className="invoice-action"
            onClick={() => navigate(routes.invoice)}
          >
            Lihat Invoice
          </span>
        )}
      </div>
      <div className="page-content">
        {order.data?.is_complained && (
          <div className="notice-section">
            <div className="label">Menunggu Penyelesaian Komplain</div>
            <div className="text">
              Mamabear akan menyelesaikan komplain yang telah diajukan melalui
              nomor WhatsApp yang telah anda tambahkan paling lambat pada{" "}
              {moment(
                order.data.order_complain?.deadline_date
                  .split("-")
                  .reverse()
                  .join("-")
              ).format("DD MMMM YYYY")}
              . Pastikan anda tidak melewatkan pesan dari tim Mamabear.
            </div>
          </div>
        )}

        {useAccordion ? (
          status.label.includes("batal") ? (
            <div className="notice-section borderless">
              <div className="label">
                {status.label == "Dibatalkan"
                  ? "Pembatalan telah disetujui"
                  : "Menunggu respon pembatalan"}
              </div>
              <div className="text">
                {status.label == "Dibatalkan"
                  ? "Pembatalan pesanan telah disetujui oleh MamaBear"
                  : "MamaBear akan menjawab sebelum tanggal " +
                    cancelOrderBefore +
                    ". Pesanan akan secara otomatis dibatalkan apabila MamaBear belum merespon hingga tanggal tersebut.  Anda dapat membatalkan pengajuan jika berubah pikiran."}
              </div>
            </div>
          ) : null
        ) : (
          <div className="notice-section">
            <div className="label">Pesanan di proses</div>
            <div className="text">
              Pesanan ini akan dikirim maksimal 7 hari setelah pesanan dibuat
            </div>
          </div>
        )}
        {status.label.includes("batal") ? (
          <Cards>
            <div className="cancelation-card">
              <div className="text">
                <span>Status</span>
                <span>
                  Dibatalkan oleh{" "}
                  {order.data?.order_cancel_detail?.is_cancel_by_admin
                    ? "Mamabear"
                    : "Pembeli"}
                </span>
              </div>
              <div className="text">
                <span>Tanggal Pembelian</span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <path
                      d="M9 1V2M3 1V2"
                      stroke="#523A48"
                      strokeWidth="0.75"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1.25 6.12162C1.25 3.94297 1.25 2.85364 1.87606 2.17682C2.50212 1.5 3.50975 1.5 5.525 1.5H6.475C8.49025 1.5 9.49788 1.5 10.1239 2.17682C10.75 2.85364 10.75 3.94297 10.75 6.12162V6.37838C10.75 8.55703 10.75 9.64636 10.1239 10.3232C9.49788 11 8.49025 11 6.475 11H5.525C3.50975 11 2.50212 11 1.87606 10.3232C1.25 9.64636 1.25 8.55703 1.25 6.37838V6.12162Z"
                      stroke="#523A48"
                      strokeWidth="0.75"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1.5 4H10.5"
                      stroke="#523A48"
                      strokeWidth="0.75"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {orderDate}
                </span>
              </div>
              <div className="text">
                <span>Tanggal Pembatalan</span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <path
                      d="M9 1V2M3 1V2"
                      stroke="#523A48"
                      strokeWidth="0.75"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1.25 6.12162C1.25 3.94297 1.25 2.85364 1.87606 2.17682C2.50212 1.5 3.50975 1.5 5.525 1.5H6.475C8.49025 1.5 9.49788 1.5 10.1239 2.17682C10.75 2.85364 10.75 3.94297 10.75 6.12162V6.37838C10.75 8.55703 10.75 9.64636 10.1239 10.3232C9.49788 11 8.49025 11 6.475 11H5.525C3.50975 11 2.50212 11 1.87606 10.3232C1.25 9.64636 1.25 8.55703 1.25 6.37838V6.12162Z"
                      stroke="#523A48"
                      strokeWidth="0.75"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1.5 4H10.5"
                      stroke="#523A48"
                      strokeWidth="0.75"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {cancelOrderDate}
                </span>
              </div>
              <div className="text">
                <span>Pengembalian Dana</span>
                <span>Rp {order.data?.order_payment?.final_amount}</span>
              </div>
              <div className="text">
                <span>Tipe Pengembalian Dana</span>
                <span>MamaPoint</span>
              </div>
              <div
                className="text"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <span style={{ flex: 1 }}>Alasan</span>
                <span style={{ flex: 1 }}>
                  {order.data?.order_cancel_detail?.note}
                </span>
              </div>
            </div>
          </Cards>
        ) : (
          <Cards>
            <div className="status-card">
              <div className="text">
                <span>Status</span>
                <span>
                  {order.data?.is_complained ? "Komplain" : status.label}
                </span>
              </div>
              <div className="text">
                <span>Tanggal Pembelian</span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <path
                      d="M9 1V2M3 1V2"
                      stroke="#523A48"
                      strokeWidth="0.75"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1.25 6.12162C1.25 3.94297 1.25 2.85364 1.87606 2.17682C2.50212 1.5 3.50975 1.5 5.525 1.5H6.475C8.49025 1.5 9.49788 1.5 10.1239 2.17682C10.75 2.85364 10.75 3.94297 10.75 6.12162V6.37838C10.75 8.55703 10.75 9.64636 10.1239 10.3232C9.49788 11 8.49025 11 6.475 11H5.525C3.50975 11 2.50212 11 1.87606 10.3232C1.25 9.64636 1.25 8.55703 1.25 6.37838V6.12162Z"
                      stroke="#523A48"
                      strokeWidth="0.75"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1.5 4H10.5"
                      stroke="#523A48"
                      strokeWidth="0.75"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {orderDate}
                </span>
              </div>
            </div>
          </Cards>
        )}

        <Cards isAccordion={useAccordion}>
          <div className="label">Detail Produk</div>
          {order.data?.order_products?.map((data, i) => (
            <CartItem
              cart={data}
              needWishlist={true}
              key={data.id}
              totalItems={data.qty}
            ></CartItem>
          ))}
        </Cards>

        <Cards isAccordion={useAccordion}>
          <div className="label">Informasi Pengiriman</div>
          <div className="status-card">
            <div className="text">
              <span>Kurir</span>
              <span>
                {order.data?.courier?.name ?? "JNE"}
                <div className="badge">{order.data?.courier?.description}</div>
              </span>
            </div>
            <div className="text">
              <span>No. Resi</span>
              <span>{order.data?.receipt_number}</span>
            </div>
            <div className="text">
              <span>Ongkos Kirim ({totalWeight} gr)</span>
              <span>
                Rp{" "}
                {numberWithCommas(order.data?.order_payment?.delivery_fee ?? 0)}
              </span>
            </div>
            <div className="text">
              <span>Penerima</span>
              <span>{order?.data?.origin_address_json?.receiver_name}</span>
            </div>
            <div className="text">
              <span>Kontak Penerima</span>
              <span>{order?.data?.origin_address_json?.phone}</span>
            </div>
          </div>
        </Cards>

        <Cards isAccordion={useAccordion}>
          <div className="label">Alamat Pengiriman</div>
          <div className="address-section">
            <h5>
              {order?.data?.destination_address_json?.label}
              {order?.data?.destination_address_json?.is_main === 1 &&
                " (Utama)"}
            </h5>
            <p>{order?.data?.destination_address_json?.full_address}</p>
          </div>
        </Cards>

        <OrderSummary
          data={
            new Map([
              [
                "Metode Pembayaran",
                order.data?.order_payment?.payment_gateway_detail.metadata
                  .bank ?? "BCA" + " - Virtual Akun",
              ],
              [
                "Total Harga",
                "Rp " +
                  numberWithCommas(
                    order.data?.order_payment?.items_amount ?? 0
                  ),
              ],
              [
                "Ongkos Kirim (" + totalWeight + " gr)",
                "Rp " +
                  numberWithCommas(
                    order.data?.order_payment?.delivery_fee ?? 0
                  ),
              ],
              [
                "Biaya Aplikasi",
                "Rp " +
                  numberWithCommas(
                    order.data?.order_payment?.application_fee ?? 0
                  ),
              ],
              [
                "Diskon",
                "-Rp " +
                  numberWithCommas(
                    order.data?.order_payment?.discount_amount ?? 0
                  ),
              ],
              [
                "Cashback",
                "-Rp 25.000" +
                  numberWithCommas(
                    order.data?.order_payment?.cashback_amount ?? 0
                  ),
              ],
              [
                "MamaPoint",
                "-Rp. " +
                  numberWithCommas(
                    order.data?.order_payment?.point_amount ?? 0
                  ),
              ],
            ])
          }
          total={"Rp. " + totalAmount}
          labelTotal="Total Belanja"
        />

        {!["Menunggu Pembayaran", "Diproses", "Dibatalkan"].includes(
          status.label
        ) ? (
          status.label == "Pengajuan Pembatalan" ? (
            <div className="row">
              <div
                className="primary-button expanded"
                onClick={() => setShowCancelCancelation(true)}
              >
                Batalkan Pengajuan
              </div>
            </div>
          ) : status.label == "Diterima" ? (
            <div className="row">
              <div className="width-10"></div>
              <div
                className={
                  order.data?.order_reviewed
                    ? "secondary-button expanded"
                    : "primary-button expanded"
                }
                onClick={() =>
                  navigate(routes.review, {
                    state: { orderId: order.data?.id },
                  })
                }
              >
                {order.data?.order_reviewed ? "Lihat Ulasan" : "Beri Ulasan"}
              </div>
            </div>
          ) : (
            <div className="row">
              {useAccordion ? (
                <>
                  <div
                    className="secondary-button"
                    onClick={() => setShowMoreOption(true)}
                  >
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.01 12.499C7.01 11.6706 6.33843 10.999 5.51 10.999H5.5C4.67157 10.999 4 11.6706 4 12.499V12.509C4 13.3375 4.67157 14.009 5.5 14.009H5.51C6.33843 14.009 7.01 13.3375 7.01 12.509V12.499ZM12.51 10.999C13.3384 10.999 14.01 11.6706 14.01 12.499V12.509C14.01 13.3375 13.3384 14.009 12.51 14.009H12.5C11.6716 14.009 11 13.3375 11 12.509V12.499C11 11.6706 11.6716 10.999 12.5 10.999H12.51ZM19.51 10.999C20.3384 10.999 21.01 11.6706 21.01 12.499V12.509C21.01 13.3375 20.3384 14.009 19.51 14.009H19.5C18.6716 14.009 18 13.3375 18 12.509V12.499C18 11.6706 18.6716 10.999 19.5 10.999H19.51Z"
                        fill="#DE8D8D"
                      />
                    </svg>
                  </div>
                  <div className="width-10"></div>
                </>
              ) : null}

              <div className="expanded">
                <div
                  className="primary-button"
                  onClick={() =>
                    useAccordion
                      ? setShowAcceptConfirmation(true)
                      : setShowCancelConfirmation(true)
                  }
                >
                  {useAccordion ? "Produk Diterima" : "Batalkan Pesanan"}
                </div>
              </div>
            </div>
          )
        ) : null}

        {status.label == "Menunggu Pembayaran" && (
          <div className="row">
            <div
              className="primary-button expanded"
              onClick={() => navigate(routes.payment)}
            >
              Detail Pembayaran
            </div>
          </div>
        )}
      </div>
      <ConfirmationDialog
        open={showCancelConfirmation}
        onSubmit={() => setShowReasonOption(true)}
        onClose={() => setShowCancelConfirmation(false)}
        icon={confirmCancel}
        label="Pembatalan Pesanan"
        text="Apakah anda yakin ingin membatalkan<br/>pesanan yang telah dibuat?"
      />
      <ConfirmationDialog
        open={showAcceptConfirmation}
        onSubmit={handleAcceptOrder}
        onClose={() => setShowAcceptConfirmation(false)}
        icon={confirmAccepted}
        label="Pesanan Diterima"
        text="Apakah anda yakin telah menerima<br/>produk yang anda pesan dengan benar?"
      />
      <ConfirmationDialog
        open={showRatingConfirmation}
        onClose={() => setShowRatingConfirmation(false)}
        icon={confirmRating}
        onSubmit={() =>
          navigate(routes.review, { state: { orderId: order.data?.id } })
        }
        label="Beri Penilaian"
        text="Apakah anda ingin memberi penilaian sekarang?"
        actionText="Ya, Tentu"
      />

      <ConfirmationDialog
        open={showCancelCancelation}
        onClose={() => setShowCancelCancelation(false)}
        icon={confirmCancelation}
        onSubmit={handleCancelOrderCancel}
        label="Pembatalan Pengajuan Pesanan Dibatalkan"
        text="Apakah anda yakin ingin memproses pesanan ini kembali?<br/>Setelah ini pesanan anda akan kembali diproses"
        actionText="Ya, Saya yakin"
      />

      <TrxMoreOptionDialog
        open={showMoreOption}
        onClose={() => setShowMoreOption(false)}
        onComplaint={() => setShowComplaint(true)}
        onComplainMore={() => {
          navigate(routes.problemsDetail, { state: { order: order.data } });
        }}
        onTrack={() =>
          navigate(routes.tracking, { state: { orderId: order.data?.id } })
        }
      />
      <CancelationReasonDialog
        open={showReasonOption}
        onClose={() => setShowReasonOption(false)}
        onSubmit={handleCancel}
      />
      <ComplaintDialog
        open={showComplaint}
        onSubmit={handleComplainOrder}
        onClose={() => setShowComplaint(false)}
      />
    </div>
  );
}

export default TransactionDetailPage;
interface CardsProp {
  isAccordion?: boolean;
  children?: ReactNode;
}
function Cards(props: CardsProp) {
  const [isActive, setIsActive] = useState(true);
  return (
    <div
      onClick={() => setIsActive(!isActive)}
      className={`cards${
        props.isAccordion ?? false
          ? ` accordion${isActive ? " active" : ""}`
          : ""
      }`}
    >
      {props.children}
    </div>
  );
}
