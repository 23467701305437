import { MouseEvent } from "react";
import logo from "../../../assets/images/google.svg";
import Api from "../../../endpoint/api";
import { useGoogleLogin } from "@react-oauth/google";
import authFacade from "../facades/auth_facade";
import routes from "../../../routes";
import { useNavigate } from "react-router-dom";
import homeFacade from "../../order/facades/home_facade";
import { getFcmToken } from "../../../firebase";

interface ButtonProps {
  onTap: (event: MouseEvent) => void;
}

function GoogleLoginButton() {
  const { googleAuth, updateFcmToken } = authFacade();
  const { setActiveMenu } = homeFacade();

  const navigate = useNavigate();

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      googleAuth(tokenResponse.access_token).then(async (status) => {
        if (status) {
          const checkToken = await getFcmToken();
          updateFcmToken(checkToken).then((status) => {
            if (status) {
              setActiveMenu("beranda");
              navigate("/store/home");
            }
          });
        }
      });
    },
  });

  return (
    <div className="google-login" onClick={() => login()}>
      <img src={logo} alt="" />
      Masuk dengan Google
    </div>
  );
}

export default GoogleLoginButton;
