import succeed from "../../../assets/images/notifications_success.svg";
import fail from "../../../assets/images/notifications_failed.svg";
import NotificationModel from "../models/notification_model";

function NotificationComponent(props: NotificationModel) {
  return (
    <div
      className={"notification" + (props.is_readed ? " readed" : "")}
      onClick={props.is_readed ? undefined : props.onTap}
    >
      <img src={props.is_succeed ? succeed : fail} />
      <div className="expanded column">
        <h5>{props.title}</h5>
        <p>{props.text}</p>
      </div>
    </div>
  );
}

export default NotificationComponent;
