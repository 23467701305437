import { useEffect, useState } from "react";
import NotificationComponent from "../components/notification";
import emptyIcon from "../../../assets/images/notifications_empty.svg";
import NotificationModel from "../models/notification_model";
import { useNavigate } from "react-router-dom";
import notificationFacade from "../facades/notification_facades";

function NotificationScreen() {
  const navigate = useNavigate();
  // const [notifications, setNotifications] = useState<NotificationModel[]>([]);
  const { notifications, fetchNotifications, readNotification } =
    notificationFacade();

  const handleReadNotification = (id: string) => {
    readNotification(id).then((success) => {
      if (success) {
        fetchNotifications();
      }
    });
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <div className="notifications">
      <div className="header">
        <svg
          onClick={() => navigate(-1)}
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 8.5L6 12.5M6 12.5L10 16.5M6 12.5L18 12.5"
            stroke="#191919"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p className="expanded">Notifikasi</p>
      </div>
      <div className="contents">
        {notifications?.data?.length == 0 ? (
          <div className="empty">
            <img src={emptyIcon} />
            <h5>Tidak Ada Pesan</h5>
            <p>
              Anda tidak memiliki pesan,
              <br />
              Coba kembali lagi nanti
            </p>
          </div>
        ) : (
          notifications?.data?.map((data, index) => (
            <NotificationComponent
              onTap={() => handleReadNotification(data.id ?? "")}
              is_readed={data.read_at}
              is_succeed={
                data.data?.notification_type == "order_failed" ? false : true
              }
              text={data.data?.body ?? ""}
              title={data.data?.title ?? ""}
            />
          ))
        )}
      </div>
    </div>
  );
}

export default NotificationScreen;
