import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import productImage1 from "../../../assets/images/product-1.png";
import productImage2 from "../../../assets/images/product-2.png";
import TextInput from "../../shared/components/text_input";
import Agreement from "../../authentication/components/agreement";
import ComplainItem from "../components/complain_item";
import ComplainItemModel, { ComplainDocument } from "../models/complain_model";
import ProblemsTypeDialog from "./dialogs/problems_type_dialog";
import ProofUploadDialog from "./dialogs/proof_upload_dialog";
import routes from "../../../routes";
import { Order, OrderModel } from "../../order/models/order_model";
import orderFacade from "../../order/facades/order_facade";
import Toast from "../../../utils/toast";

interface ReasonModel {
  id: number;
  issue: string;
  text: string;
}

function ProblemsDetailPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const orderState = location.state.order as Order;
  const { complainOrder } = orderFacade();

  const [proofUpload, setProofUpload] = useState(false);
  const [showProblemsType, setShowProblemsType] = useState(false);
  const [reportItems, setReportItems] = useState<ComplainItemModel[]>([]);
  const [documents, setDocuments] = useState<ComplainDocument[]>([]);
  const [description, setDescription] = useState<string>("");
  const [whatsapp, setWhatsapp] = useState<string>("");

  const [reason, setReason] = useState<ReasonModel>({
    id: 0,
    issue: "",
    text: "Pilih Jenis Masalah",
  });

  const numberWithCommas = (value: number) => {
    return new Intl.NumberFormat("id-ID").format(value);
  };

  useEffect(() => {
    var complaints: ComplainItemModel[] = [];

    orderState?.order_products?.map((item) => {
      complaints.push({
        qty: item?.qty,
        isSelected: false,
        reportedAmount: 0,
        name: item?.product_json.name,
        price: item?.product_json.price,
        image: item?.product_json.image_url_text,
        id: item?.id,
      });
    });
    setReportItems(complaints);
  }, []);

  const handleSelectReason = (reason: ReasonModel) => {
    setReason(reason);
    setShowProblemsType(false);
  };

  const handleAddDocument = (document: ComplainDocument) => {
    setDocuments([...documents, document]);
    setProofUpload(false);
  };

  const handleSubmit = async () => {
    let sendItems = [] as any[];

    reportItems.forEach((item) => {
      if (item.isSelected) {
        sendItems.push({
          id: item.id,
          qty: item.reportedAmount,
        });
      }
    });

    const payload = {
      order_id: orderState.id ?? 0,
      complain_type_id: reason.id,
      description: description,
      whatsapp: whatsapp,
      order_product: sendItems,
      document: documents,
    };

    if (payload.complain_type_id === 0) {
      Toast.show("Pilih jenis masalah", {});
      return;
    }

    if (payload.description === "") {
      Toast.show("Isi Deskripsi Terlebih Dahulu", {});
      return;
    }

    if (payload.whatsapp === "") {
      Toast.show("Isi Nomor WhatsApp Terlebih Dahulu", {});
      return;
    }

    complainOrder(payload).then((res) => {
      if (res) {
        navigate(routes.home, { replace: true });
      }
    });
  };

  return (
    <div className="page problems-detail-page">
      <div className="header">
        <svg
          className="back"
          onClick={() => navigate(-1)}
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 8.5L6 12.5M6 12.5L10 16.5M6 12.5L18 12.5"
            stroke="#191919"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p>Detail Masalah</p>
      </div>
      <div className="page-content">
        <div className="cards">
          <div className="label">Jenis Masalah</div>
          <TextInput isOutlined>
            <option value="">{reason.text}</option>
            {/* <option value="">Barang tidak sampai</option>
            <option value="">Barang sampai dalam kondisi rusak</option> */}
          </TextInput>
          <div
            className="overlay"
            onClick={() => setShowProblemsType(true)}
          ></div>
        </div>
        <div className="cards">
          <div className="label">Detail Produk</div>
          <div className="complained-items">
            {reportItems.map((item, idx) => (
              <ComplainItem
                key={idx}
                item={item}
                onSelected={(val) => {
                  reportItems[idx].isSelected = val;

                  if (!val) {
                    reportItems[idx].reportedAmount = 0;
                  } else {
                    reportItems[idx].reportedAmount = 1;
                  }

                  setReportItems([...reportItems]);
                }}
                onChanged={(val) => {
                  reportItems[idx].reportedAmount =
                    (reportItems[idx].reportedAmount ?? 0) + val;
                  if ((reportItems[idx].reportedAmount ?? 0) < 0) {
                    reportItems[idx].reportedAmount = 0;
                  }
                  if (
                    (reportItems[idx].reportedAmount ?? 0) >
                    (reportItems[idx].qty ?? 0)
                  ) {
                    reportItems[idx].reportedAmount = reportItems[idx].qty ?? 0;
                  }

                  if (reportItems[idx].reportedAmount === 0) {
                    reportItems[idx].isSelected = false;
                  } else {
                    reportItems[idx].isSelected = true;
                  }

                  setReportItems([...reportItems]);
                }}
              />
            ))}
          </div>
        </div>
        <div className="cards">
          <div className="row">
            <div className="label expanded">Pengembalian Dana</div>
            <span className="txt">
              Rp {numberWithCommas(orderState.order_payment?.final_amount ?? 0)}
            </span>
          </div>
        </div>
        <div className="cards">
          <div className="label">Deskripsi Masalah</div>
          <TextInput
            isOutlined
            isMultiline
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Jelaskan masalah anda"
            text="Minimal 20 Karakter"
          ></TextInput>
        </div>

        <div className="cards">
          <div className="column">
            <div className="label">Unggah Bukti</div>
            <div className="text" style={{ marginTop: 2 }}>
              <span>Wajib!</span> Unggah bukti berupa foto dan video maksimal 5
              foto atau 4 foto dengan 1 video
            </div>
          </div>
          <div className="proof-documents">
            {documents.map((document, idx) => (
              <div key={idx} className="item">
                <figure>
                  <img
                    src={
                      document.file != null
                        ? URL.createObjectURL(document.file)
                        : productImage1
                    }
                  />
                </figure>
                <div className="column expanded">
                  <div className="row">
                    <div className="title expanded">Deskripsi :</div>
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_316_10405)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4.56875 7.99951C4.56875 7.48174 4.14902 7.06201 3.63125 7.06201L3.625 7.06201C3.10723 7.06201 2.6875 7.48174 2.6875 7.99951L2.6875 8.00576C2.6875 8.52353 3.10723 8.94326 3.625 8.94326H3.63125C4.14902 8.94326 4.56875 8.52353 4.56875 8.00576V7.99951ZM8.00625 7.06201C8.52402 7.06201 8.94375 7.48174 8.94375 7.99951V8.00576C8.94375 8.52353 8.52402 8.94326 8.00625 8.94326H8C7.48223 8.94326 7.0625 8.52353 7.0625 8.00576V7.99951C7.0625 7.48174 7.48223 7.06201 8 7.06201L8.00625 7.06201ZM12.3813 7.06201C12.899 7.06201 13.3188 7.48174 13.3188 7.99951V8.00576C13.3188 8.52353 12.899 8.94326 12.3813 8.94326H12.375C11.8572 8.94326 11.4375 8.52353 11.4375 8.00576V7.99951C11.4375 7.48174 11.8572 7.06201 12.375 7.06201H12.3813Z"
                          fill="#191919"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_316_10405">
                          <rect width="15" height="15" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="text">
                    {document.description ?? "Tidak ada deskripsi"}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="primary-button" onClick={() => setProofUpload(true)}>
            + Tambah Berkas
          </div>
        </div>

        <div className="cards">
          <div className="row">
            <div className="label expanded">Estimasi Waktu Komplain</div>
            <div className="timer">3-6 Hari Kerja</div>
          </div>
          <div className="text">
            Estimasi waktu dapat berubah lebih cepat atau lambat sesuai dengan
            perkembangan komplain.
          </div>
        </div>
        <div className="cards">
          <div className="label">Nomor WhatsApp</div>
          <TextInput
            value={whatsapp}
            onChange={(e) => setWhatsapp(e.target.value)}
            isOutlined
            type="number"
            prefix={<span>+62</span>}
            placeholder="Masukkan nomor"
          />
        </div>
        <Agreement />
        <div className="row">
          <div className={`primary-button expanded`} onClick={handleSubmit}>
            Ajukan Komplain
          </div>
        </div>
      </div>
      <ProblemsTypeDialog
        open={showProblemsType}
        onClose={() => setShowProblemsType(false)}
        onSubmit={handleSelectReason}
      />
      <ProofUploadDialog
        open={proofUpload}
        onClose={() => setProofUpload(false)}
        onSubmit={handleAddDocument}
      />
    </div>
  );
}

export default ProblemsDetailPage;
