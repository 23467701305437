import { useLocation, useNavigate } from "react-router-dom";
import back from "../../../assets/images/ic_arrow-left.svg";
import OrderSummary from "../components/order_summary";
import CartSummary from "../components/cart_summary";
import Radio from "../../shared/components/radio";
import { useEffect, useState } from "react";
import routes from "../../../routes";
import { PaymentMethodModel } from "../models/payment_method_model";
import PaymentMethodFacade from "../facades/payment_method_facade";
import orderFacade from "../facades/order_facade";
import { OrderModel } from "../models/order_model";
import { Cart } from "../models/cart_model";
import authFacade from "../../authentication/facades/auth_facade";
import Toast from "../../../utils/toast";

interface PricesState {
  subtotal: number;
  shippingFee: number;
  appFee: number;
  discount: number;
  cashback: number;
  mamaPointPrice: number;
  totalPrice: number;
}

function PaymentMethodPage() {
  const location = useLocation();
  const prices = location.state as PricesState;
  const navigate = useNavigate();
  const [selectedPayment, setSelectedPayment] = useState<PaymentMethodModel>();
  const { paymentMethods, fetchPaymentMethod } = PaymentMethodFacade();
  const { order, storeOrder, storeOrderGuest } = orderFacade();
  const [disabled, setDisabled] = useState<boolean>(false);
  const { isAuthenticated } = authFacade();

  const handleOrder = async () => {
    setDisabled(true);
    if (isAuthenticated) {
      const cartIds = JSON.parse(localStorage.getItem("carts") ?? "[]").map(
        (cart: Cart) => cart.id
      );

      const courierId = JSON.parse(localStorage.getItem("courier") ?? "{}").id;

      const userAddressId = JSON.parse(
        localStorage.getItem("address") ?? "{}"
      ).id;

      const mamaPoint = JSON.parse(
        localStorage.getItem("useMamaPoint") ?? "false"
      ) as boolean;
      const dropShipName =
        localStorage.getItem("dropShipName") ?? ("" as string);
      const dropShipPhone =
        localStorage.getItem("dropShipPhone") ?? ("" as string);

      const userVoucher = JSON.parse(localStorage.getItem("voucher") ?? "{}");

      const paymentMethodId = selectedPayment?.id;

      const OrderPayload = {
        cartIds,
        courierId,
        userAddressId,
        paymentMethodId,
        dropShipName,
        dropShipPhone,
        mamaPoint,
        userVoucherId: userVoucher.id ?? "",
      };

      try {
        storeOrder(OrderPayload).then((res) => {
          if (res) {
            localStorage.removeItem("carts");
            localStorage.removeItem("courier");
            localStorage.removeItem("address");
            localStorage.removeItem("voucher");
            localStorage.removeItem("useMamaPoint");
            localStorage.removeItem("isDropship");
            localStorage.removeItem("dropShipName");
            localStorage.removeItem("dropShipPhone");

            navigate(routes.payment);
          } else {
            Toast.show("Error storing order:", {});
            setDisabled(false);
          }
        });
      } catch (error) {
        // Handle any errors that occurred during storeOrder
        Toast.show("Error storing order:", {});
        setDisabled(false);
      }
    } else {
      const carts = JSON.parse(localStorage.getItem("carts") ?? "[]");
      const courierId = JSON.parse(localStorage.getItem("courier") ?? "{}").id;
      const guestAddress = JSON.parse(localStorage.getItem("address") ?? "{}");
      const dropShipName =
        localStorage.getItem("dropShipName") ?? ("" as string);
      const dropShipPhone =
        localStorage.getItem("dropShipPhone") ?? ("" as string);

      const paymentMethodId = selectedPayment?.id;

      guestAddress.full_name = guestAddress.receiver_name;
      guestAddress.fullName = guestAddress.receiver_name;
      const OrderPayload = {
        courier_id: courierId,
        dropshipper_name: dropShipName,
        dropshipper_phone: dropShipPhone,
        payment_method_id: paymentMethodId,
        carts: carts,
        address: guestAddress,
      };

      try {
        storeOrderGuest(OrderPayload).then((res) => {
          if (res) {
            localStorage.removeItem("carts");
            localStorage.removeItem("courier");
            localStorage.removeItem("address");
            localStorage.removeItem("voucher");
            localStorage.removeItem("useMamaPoint");
            localStorage.removeItem("isDropship");
            localStorage.removeItem("dropShipName");
            localStorage.removeItem("dropShipPhone");

            navigate(routes.payment);
          } else {
            Toast.show("Error storing order:", {});
          }
        });
      } catch (error) {
        // Handle any errors that occurred during storeOrder
        console.error("Error storing order:", error);
        setDisabled(false);
      }
    }
  };

  useEffect(() => {
    if (paymentMethods.length == 0) {
      fetchPaymentMethod();
    }
  });

  const numberWithCommas = (value: number) => {
    return new Intl.NumberFormat("id-ID").format(value);
  };

  return (
    <div className="base-page">
      <div className="header p25">
        <img
          src={back}
          alt=""
          className="back-btn"
          onClick={() => navigate(-1)}
        />
        <div className="title">Metode Pembayaran</div>
      </div>
      <div className="content" style={{ background: "#FFF5F5" }}>
        <div className="cards">
          {paymentMethods.map((value, index) => (
            <div
              className={
                "payment-options" +
                (selectedPayment?.id == value?.id ? " active" : "")
              }
              key={value?.id}
              onClick={() => setSelectedPayment(value)}
            >
              <figure>
                <img src={value?.image_url_text} alt={value.channel} />
              </figure>
              <div className="text">
                <h5>{value.channel}</h5>
                <p>Virtual Account</p>
              </div>
              <Radio
                checked={selectedPayment == value}
                onChange={(val) => setSelectedPayment(value)}
              />
            </div>
          ))}
        </div>
        <OrderSummary
          data={
            new Map([
              ["Sub Total", "Rp. " + numberWithCommas(prices.subtotal)],
              ["Ongkos Kirim", "Rp. " + numberWithCommas(prices.shippingFee)],
              ["Biaya Aplikasi", "Rp. " + numberWithCommas(prices.appFee)],
              ["Diskon", "-Rp. " + numberWithCommas(prices.discount)],
              ["Cashback", "-Rp. " + numberWithCommas(prices.cashback)],
              ["MamaPoint", "-Rp. " + numberWithCommas(prices.mamaPointPrice)],
            ])
          }
        />
      </div>
      <CartSummary
        onActionTap={() => {
          selectedPayment && handleOrder();
        }}
        disabled={disabled}
        showVoucher={false}
        price={prices.totalPrice}
        actionText="Bayar Sekarang"
      />
    </div>
  );
}

export default PaymentMethodPage;
