import { useState, useEffect, useCallback, useRef } from "react";
import TextInput from "../../../../shared/components/text_input";
import { useLocation, useNavigate } from "react-router-dom";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  StandaloneSearchBox,
  Libraries,
} from "@react-google-maps/api";
import routes from "../../../../../routes";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: -7.2925899,
  lng: 112.7175088,
};

const libraries: Libraries = ["places"];

function PinAddressPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAR3gLno8vsK5ML_iRLYHtRmB55pdBYrq4",
    libraries: libraries,
  });

  const [geoLocation, setGeoLocation] = useState("");
  const [marker, setMarker] = useState<google.maps.LatLng | null>(null);

  const onUnmount = useCallback(function callback(map: google.maps.Map) {
    mapRef.current = null;
  }, []);

  const ignore = useRef(false);

  useEffect(() => {
    if (!ignore.current) {
      getCurrentLocation();
    }
  }, [marker]);

  async function getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          geocoderFunc({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (err) => {
          console.error(err.message);
        }
      );
    } else {
      geocoderFunc({
        lat: marker?.lat() ?? center.lat,
        lng: marker?.lng() ?? center.lng,
      });
      console.error("Geolocation is not supported by your browser.");
    }
    ignore.current = false;
  }

  async function geocoderFunc(loc: google.maps.LatLngLiteral | null) {
    if (google.maps) {
      const { Geocoder } = (await google.maps.importLibrary(
        "geocoding"
      )) as google.maps.GeocodingLibrary;
      const geocoder = new Geocoder();
      const latlng = new google.maps.LatLng(
        (loc?.lat ?? center.lat) as number,
        (loc?.lng ?? center.lng) as number
      ); // Replace with your LatLng

      geocoder.geocode({ location: marker ?? latlng }, (results, status) => {
        if (status === "OK" && results && results[0]) {
          const { address_components, geometry, formatted_address } =
            results[0];
          setGeoLocation(formatted_address);
          const cityComponent = address_components?.find((component) =>
            component.types.includes("administrative_area_level_2")
          );
          const districtComponent = address_components?.find((component) =>
            component.types.includes("administrative_area_level_3")
          );
          setCity(cityComponent?.long_name ?? "");
          setDistrict(districtComponent?.long_name ?? "");
        } else {
          console.error("Geocoding failed:", status);
        }
      });
    }
  }

  const [searchBox, setSearchBox] =
    useState<google.maps.places.SearchBox | null>(null);
  const mapRef = useRef<google.maps.Map | null>(null);

  const onLoad = (map: google.maps.Map) => {
    mapRef.current = map;
  };

  const onPlacesChanged = () => {
    const newPlaces = searchBox?.getPlaces?.();
    if (newPlaces && newPlaces.length > 0) {
      setGeoLocation(newPlaces[0].formatted_address ?? "");
      ignore.current = true;
      setMarker(newPlaces[0].geometry?.location ?? marker);
      mapRef.current?.panTo(newPlaces[0].geometry?.location ?? marker!);

      const bounds = new window.google.maps.LatLngBounds();
      const { address_components, geometry, formatted_address } = newPlaces[0];

      const cityComponent = address_components?.find((component) =>
        component.types.includes("administrative_area_level_2")
      );
      const districtComponent = address_components?.find((component) =>
        component.types.includes("administrative_area_level_3")
      );
      setCity(cityComponent?.long_name ?? "");
      setDistrict(districtComponent?.long_name ?? "");

      newPlaces.forEach((place) => {
        if (place.geometry && place.geometry.location) {
          bounds.extend(place.geometry.location);
        }
      });
      mapRef.current?.fitBounds(bounds);
    }
  };
  // AIzaSyBCUKePgvazZmgP2Z0n46FQPDVS8UNPJ30
  return (
    <div className="base-page">
      <div className="pin-map-container">
        {isLoaded ? (
          <StandaloneSearchBox
            onLoad={(searchBox) => setSearchBox(searchBox)}
            onPlacesChanged={onPlacesChanged}
          >
            <div className="search">
              <input type="text" placeholder="Cari alamat anda" />
            </div>
          </StandaloneSearchBox>
        ) : (
          <span>is Loading</span>
        )}

        <div className="maps">
          {isLoaded ? (
            <GoogleMap
              onLoad={onLoad}
              zoom={15}
              center={center}
              onUnmount={onUnmount}
              mapContainerStyle={containerStyle}
              onClick={(e) => {
                // console.log(e.latLng);
                setMarker(e.latLng);
              }}
              options={{
                disableDefaultUI: true,
                zoomControl: false,
              }}
            >
              {marker != null ? <Marker position={marker} /> : null}
              <></>
            </GoogleMap>
          ) : (
            <span>Loading</span>
          )}
        </div>
        <div className="sheets">
          <div
            className="navigation-focus"
            onClick={() => {
              if (marker != null) {
                mapRef.current?.panTo(marker!);
              }
            }}
          ></div>
          <TextInput
            isMultiline={true}
            label="Alamat Anda"
            value={geoLocation}
            onChange={(val) => setGeoLocation(val.target.value)}
          ></TextInput>
          <div
            className={`primary-button${
              geoLocation.trim().length == 0 ? " disabled" : ""
            }`}
            onClick={() =>
              navigate(routes.addAddress, {
                state: {
                  address: geoLocation,
                  city: city,
                  district: district,
                  latitude: marker?.lat(),
                  longitude: marker?.lng(),
                  ...(location.state.isFromCheckout
                    ? { fromCheckout: true, carts: location.state.carts }
                    : {}),
                },
                replace: true,
              })
            }
          >
            Konfirmasi Alamat
          </div>
        </div>
      </div>
    </div>
  );
}

export default PinAddressPage;
