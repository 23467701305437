import { useEffect, useState, MouseEventHandler } from "react";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import banner from "../../../../../assets/images/gold_member.svg";

import memberIron from "../../../../../assets/images/member_iron.svg";
import memberBronze from "../../../../../assets/images/member_bronze.svg";
import memberSilver from "../../../../../assets/images/member_silver.svg";
import memberGold from "../../../../../assets/images/member_gold.svg";
import memberPlatinum from "../../../../../assets/images/member_platinum.svg";
import memberDiamond from "../../../../../assets/images/member_diamond.svg";

import emptyVoucher from "../../../../../assets/images/empty_voucher.svg";

import EmptyState from "../../../components/empty_state";
import VoucherItem from "../../../components/voucher_item";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import profileFacade from "../../../facades/profile_facade";
import membershipFacade from "../../../facades/membership_facade";
import { MembershipModel, Voucher } from "../../../models/membership_model";

function MembershipListPage() {
  const navigate = useNavigate();
  const [focusedMember, setFocusedMember] = useState(0);
  const { memberships, fetchMemberships } = membershipFacade();
  const { user } = profileFacade();

  const location = useLocation();

  useEffect(() => {
    setFocusedMember(
      {
        iron: 0,
        bronze: 1,
        silver: 2,
        gold: 3,
        platinum: 4,
        diamond: 5,
      }[(location.state ?? "iron") as string] ?? 0
    );

    if (memberships.length == 0) {
      fetchMemberships();
    }
  }, []);

  const settings = {
    dots: true,
    autoplay: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const numberWithCommas = (value: number) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      maximumFractionDigits: 0,
      currencyDisplay: "symbol", // Use 'code' for currency code (e.g., IDR)
    }).format(value);
  };

  return (
    <div className="membership-page">
      <div className="header">
        <svg
          onClick={() => navigate(-1)}
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 8.5L6 12.5M6 12.5L10 16.5M6 12.5L18 12.5"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        Membership
      </div>
      <div className="banner">
        <Slider
          {...settings}
          initialSlide={
            {
              iron: 0,
              bronze: 1,
              silver: 2,
              gold: 3,
              platinum: 4,
              diamond: 5,
            }[(location.state ?? "iron") as string] ?? 0
          }
          afterChange={(page) => {
            setFocusedMember(page);
          }}
        >
          {memberships.map(({ name, end_transaction_amount }, index) => (
            <figure
              className={`membership ${name.toLowerCase().split(" ")[0]}`}
              key={name}
            >
              <img
                src={
                  {
                    iron: memberIron,
                    bronze: memberBronze,
                    silver: memberSilver,
                    gold: memberGold,
                    platinum: memberPlatinum,
                    diamond: memberDiamond,
                  }[name.toLowerCase().split(" ")[0] ?? "iron"]
                }
              />
              <p>
                <span>
                  {numberWithCommas(
                    user.metadata?.transaction_amount_total ?? 0
                  )}
                </span>
                / {numberWithCommas(end_transaction_amount)}
              </p>
              <div className="deadline">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11"
                  height="10"
                  viewBox="0 0 11 10"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.1028 4.66275C10.1028 7.21904 8.03056 9.29132 5.47427 9.29132C2.91799 9.29132 0.845703 7.21904 0.845703 4.66275C0.845703 2.10646 2.91799 0.0341797 5.47427 0.0341797C8.03056 0.0341797 10.1028 2.10646 10.1028 4.66275ZM5.01142 6.97704C5.01142 6.72141 5.21865 6.51418 5.47427 6.51418C5.7299 6.51418 5.93713 6.72141 5.93713 6.97704C5.93713 7.23267 5.7299 7.43989 5.47427 7.43989C5.21865 7.43989 5.01142 7.23267 5.01142 6.97704ZM5.12713 5.58847C5.12713 5.78019 5.28255 5.93561 5.47427 5.93561C5.666 5.93561 5.82142 5.78019 5.82142 5.58847L5.82142 2.34847C5.82142 2.15674 5.666 2.00132 5.47428 2.00132C5.28255 2.00132 5.12713 2.15674 5.12713 2.34847L5.12713 5.58847Z"
                    fill="currentColor"
                  />
                </svg>
                Syarat & Ketentuan Berlaku
              </div>
            </figure>
          ))}
        </Slider>
      </div>
      <div className="content floating" style={{ background: "#F1F1F1" }}>
        {
          <VoucherTab
            vouchers={memberships[focusedMember].membership_level_rewards.map(
              (item) => {
                return { voucher: item.voucher };
              }
            )}
          />
        }
      </div>
    </div>
  );
}
interface VoucherTabProp {
  vouchers: MembershipModel[];
}

function VoucherTab(props: VoucherTabProp) {
  useEffect(() => {
    // console.log(props.vouchers)
  }, [props.vouchers]);

  return (
    <>
      {
        props.vouchers.length == 0 ? (
          <EmptyState
            icon={emptyVoucher}
            title="Voucher Tidak Tersedia"
            subtitle="Anda tidak memiliki voucher saat ini"
          />
        ) : (
          // ["Reward"].map((section, index) => (
          <>
            <div className="list-header">
              <div className="title">Reward</div>
              <div className="subtitle">
                Lihat semua reward dari level member ini.
              </div>
            </div>
            {props.vouchers.map((data, i) => (
              <VoucherItem
                showButton={false}
                voucher={data}
                key={i}
                isFlexible={false}
                onActionTap={() => {}}
              ></VoucherItem>
            ))}
          </>
        )
        // ))
      }
    </>
  );
}

export default MembershipListPage;
