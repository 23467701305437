import dummyProduct from "../../../assets/images/product-1.png";
import ComplainItemModel from "../models/complain_model";

interface ComplainItemProps {
  item: ComplainItemModel;
  onChanged: (val: number) => void;
  onSelected: (val: boolean) => void;
}
function ComplainItem(props: ComplainItemProps) {
  const numberWithCommas = (value: number) => {
    return new Intl.NumberFormat("id-ID").format(value);
  };

  return (
    <div className="cart-item item">
      <div className="row">
        <div className="row" style={{ gap: 0 }}>
          <div
            className={`check-box${props.item.isSelected ? " active" : ""}`}
            style={{ marginRight: 10 }}
            onClick={() => props.onSelected(!(props.item.isSelected ?? false))}
          ></div>
          <figure>
            <img src={props.item.image ?? dummyProduct} />
          </figure>
        </div>
        <div className="col">
          <h6>{props.item.name ?? "MamaBear Soya Mix"}</h6>
          <div className="row">
            <div className="price">
              Rp
              <span>{numberWithCommas(props.item.price ?? 75000)}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="expanded">
          <div className="desc">Jumlah barang rusak atau tidak sesuai</div>
        </div>
        <div className="stock">
          <button onClick={() => props.onChanged(-1)}></button>
          <div className="count">
            {" "}
            <span>{props.item.reportedAmount}</span> /{props.item.qty}
          </div>
          <button className="add" onClick={() => props.onChanged(1)}></button>
        </div>
      </div>
    </div>
  );
}

export default ComplainItem;
