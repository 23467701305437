import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import productImage1 from "../../../assets/images/product-1.png";
import productImage2 from "../../../assets/images/product-2.png";
import routes from "../../../routes";
import { Order } from "../../order/models/order_model";
import reviewFacade from "../../order/facades/review_facade";

function ReviewPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const receivedData = location.state;
  const orderId = location.state.orderId;

  const { review, fetchReview, storeReview } = reviewFacade();

  useEffect(() => {
    fetchReview(orderId);
  }, []);

  const handleSubmit = async () => {
    storeReview(orderId).then(
      (res) => res && navigate(routes.home, { replace: true })
    );
  };
  return (
    <div className="page product-review-page">
      <div className="header">
        <svg
          className="back"
          onClick={() => navigate(-1)}
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 8.5L6 12.5M6 12.5L10 16.5M6 12.5L18 12.5"
            stroke="#191919"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p>Beri Ulasan Produk</p>
      </div>
      <div className="page-content">
        <div className="expanded column" style={{ gap: 10 }}>
          {review?.data?.order_products?.map((orderProduct, idx) => (
            <div
              className="product-review-card"
              onClick={() =>
                navigate(routes.reviewDetail, { state: { orderProduct } })
              }
            >
              <figure>
                <img src={orderProduct.product_json.image_url_text} />
              </figure>
              <div className="column expanded">
                <div className="label">{orderProduct.product_json.name}</div>
                <div className="stars">
                  {[...Array(5)].map((data, i) =>
                    i < (orderProduct.order_product_review?.rating ?? 0) ? (
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.472 2.88211C11.8525 1.96898 13.1461 1.96898 13.5265 2.88211L15.5308 7.69229C15.6925 8.08047 16.0718 8.33332 16.4923 8.33332H21.7629C22.732 8.33332 23.2174 9.50501 22.5321 10.1903L18.2693 14.4531C18.0018 14.7206 17.9004 15.1121 18.0043 15.4758L19.6948 21.3926C19.9802 22.3916 18.8662 23.2048 18.0017 22.6285L13.0771 19.3454C12.7272 19.1122 12.2714 19.1122 11.9215 19.3454L7.00505 22.623C6.13775 23.2012 5.02009 22.3854 5.30646 21.3831L6.99427 15.4758C7.09819 15.1121 6.99675 14.7206 6.72925 14.4531L2.48751 10.2113C1.79447 9.5183 2.28531 8.33332 3.26541 8.33332H8.50623C8.92675 8.33332 9.30602 8.08047 9.46776 7.69229L11.472 2.88211Z"
                          fill="#FAD43B"
                        />
                      </svg>
                    ) : (
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.472 2.88211C11.8525 1.96898 13.1461 1.96898 13.5265 2.88211L15.5308 7.69229C15.6925 8.08047 16.0718 8.33332 16.4923 8.33332H21.7629C22.732 8.33332 23.2174 9.50501 22.5321 10.1903L18.2693 14.4531C18.0018 14.7206 17.9004 15.1121 18.0043 15.4758L19.6948 21.3926C19.9802 22.3916 18.8662 23.2048 18.0017 22.6285L13.0771 19.3454C12.7272 19.1122 12.2714 19.1122 11.9215 19.3454L7.00505 22.623C6.13775 23.2012 5.02009 22.3854 5.30646 21.3831L6.99427 15.4758C7.09819 15.1121 6.99675 14.7206 6.72925 14.4531L2.48751 10.2113C1.79447 9.5183 2.28531 8.33332 3.26541 8.33332H8.50623C8.92675 8.33332 9.30602 8.08047 9.46776 7.69229L11.472 2.88211Z"
                          fill="#FEE6E6"
                        />
                      </svg>
                    )
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div
          className={`primary-button${
            !review.data?.order_reviewed ? "" : " disabled"
          }`}
          onClick={!review.data?.order_reviewed ? handleSubmit : () => {}}
        >
          Kirim Ulasan
        </div>
      </div>
    </div>
  );
}

export default ReviewPage;
