import { useNavigate } from "react-router-dom";
import logout from "../../../assets/images/logout.svg";
import routes from "../../../routes";
import { ReactNode } from "react";

interface ProtectedContentProps {
  children: ReactNode;
  title?: string;
}

function ProtectedContent(props: ProtectedContentProps) {
  const navigate = useNavigate();
  return (
    <>
      {localStorage.getItem("token") != undefined ? (
        props.children
      ) : (
        <div className="unauthenticated">
          <img src={logout} alt="" />
          <h3>{props.title ?? "Akses Terbatas"}</h3>
          <p>
            Anda belum melakukan login <br />
            Belum Punya akun?{" "}
            <span
              style={{ color: "#DE8D8D" }}
              onClick={() => navigate(routes.login)}
            >
              Daftar Sekarang
            </span>
          </p>
          {/* <div className="row">
            <div
              className="secondary-button"
              onClick={() => navigate(routes.login)}
            >
              Masuk
            </div>
          </div> */}
        </div>
      )}
    </>
  );
}

export default ProtectedContent;
