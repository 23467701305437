// import { Button, Input, useDisclosure } from "@chakra-ui/react";
import React, { useState, useEffect  } from "react";
import { NavLink } from "react-router-dom";

export default function Navbar() {
  let [isOpen, setIsOpen] = useState(false);

  const [visible, setVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
      // Jika scroll ke atas
    if (currentScrollY < lastScrollY) {
      setVisible(true);
    } else {
      // Jika scroll ke bawah
      setVisible(false);
    }
    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    
    // Bersihkan event listener saat komponen di-unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  return (
    <div className="navbar-wrapper">
      <div className="navbar"
            style={{
              transition: "transform 0.3s ease",
              transform: visible ? "translateY(0)" : "translateY(-100%)",
              position: "fixed",
              top: 0,
              width: "100%",
              backgroundColor: "white",
              boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
              zIndex: 1000,
            }}
          >
        <div className="left">
          <img src="svg/brand.svg" alt="" />
        </div>
        <div className="center">
          <div className="menu-desktop">
            <NavLink
              style={({ isActive }) => ({
                color: isActive ? "#de8d8d" : "#523A48",
                fontWeight: isActive ? "600" : "400",
                fontSize: isActive ? "18px" : "16px",
              })}
              to={"/"}
              className={"menu dekstop"}
            >
              Beranda
            </NavLink>
            <NavLink
              style={({ isActive }) => ({
                color: isActive ? "#de8d8d" : "#523A48",
                fontWeight: isActive ? "600" : "400",
                fontSize: isActive ? "18px" : "16px",
              })}
              to={"/our-story"}
              className={"menu dekstop"}
            >
              Cerita Kami
            </NavLink>
            <NavLink
              target="_blank"
              to={"/store/home"}
              className={"menu dekstop"}
            >
              Store
            </NavLink>
            <NavLink
              style={({ isActive }) => ({
                color: isActive ? "#de8d8d" : "#523A48",
                fontWeight: isActive ? "600" : "400",
                fontSize: isActive ? "18px" : "16px",
              })}
              to={"/article"}
              className={"menu dekstop"}
            >
              Artikel
            </NavLink>
            <NavLink
              to={"/career-home"}
              style={({ isActive }) => ({
                color: isActive ? "#de8d8d" : "#523A48",
                fontWeight: isActive ? "600" : "400",
                fontSize: isActive ? "18px" : "16px",
              })}
              className={"menu dekstop"}
            >
              Karir
            </NavLink>
            <NavLink
              to={"/contact-us"}
              style={({ isActive }) => ({
                color: isActive ? "#de8d8d" : "#523A48",
                fontWeight: isActive ? "600" : "400",
                fontSize: isActive ? "18px" : "16px",
              })}
              className={"menu dekstop"}
            >
              Hubungi Kami
            </NavLink>

            <NavLink
              to={"/cekresi"}
              style={({ isActive }) => ({
                color: isActive ? "#de8d8d" : "#523A48",
                fontWeight: isActive ? "600" : "400",
                fontSize: isActive ? "18px" : "16px",
              })}
              className={"menu dekstop"}
            >
              Cek Resi
            </NavLink>

            <NavLink
              to={"/about-us"}
              style={({ isActive }) => ({
                color: isActive ? "#de8d8d" : "#523A48",
                fontWeight: isActive ? "600" : "400",
                fontSize: isActive ? "18px" : "16px",
              })}
              className={"menu dekstop"}
            >
              Tentang Kami
            </NavLink>
          </div>
        </div>
        <div className="right">
          <div>
            <img
              onClick={() => setIsOpen(!isOpen)}
              src="svg/navbar-menu.svg"
              alt=""
              className="icon-menu"
              style={{ cursor: "pointer" }}
            />
            {/* <button onClick={() => setIsOpen(!isOpen)}>hvhvh</button> */}
          </div>
        </div>
      </div>
      {
        <div
          className="menu-in-drawer"
          style={{
            maxHeight: isOpen ? "418px" : "0",
            padding: isOpen ? "25px" : "0 25px",
          }}
        >
          <NavLink
            style={({ isActive }) => ({
              color: isActive ? "#de8d8d" : "#eaa",
              fontWeight: isActive ? "600" : "400",
            })}
            to={"/"}
            className={"menu"}
          >
            Beranda
          </NavLink>
          <NavLink
            style={({ isActive }) => ({
              color: isActive ? "#de8d8d" : "#eaa",
              fontWeight: isActive ? "600" : "400",
            })}
            to={"/our-story"}
            className={"menu"}
          >
            Cerita Kami
          </NavLink>
          <NavLink target="_blank" to={"/store/home"} className={"menu"}>
            Produk
          </NavLink>
          <NavLink
            style={({ isActive }) => ({
              color: isActive ? "#de8d8d" : "#eaa",
              fontWeight: isActive ? "600" : "400",
            })}
            to={"/article"}
            className={"menu"}
          >
            Artikel
          </NavLink>
          <NavLink
            to={"/career-home"}
            style={({ isActive }) => ({
              color: isActive ? "#de8d8d" : "#eaa",
              fontWeight: isActive ? "600" : "400",
            })}
            className={"menu"}
          >
            Karir
          </NavLink>
          <NavLink
            to={"/contact-us"}
            style={({ isActive }) => ({
              color: isActive ? "#de8d8d" : "#eaa",
              fontWeight: isActive ? "600" : "400",
            })}
            className={"menu"}
          >
            Hubungi Kami
          </NavLink>
        </div>
      }
    </div>
  );
}
