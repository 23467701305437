import { create } from "zustand";
import { OrderModel } from "../models/order_model";
import { persist } from "zustand/middleware";
import Network from "../../../utils/network_utils";
import Api from "../../../endpoint/api";
import Toast from "../../../utils/toast";

interface ReviewForm {
  order_id: number;
  order_product_id: number;
  description?: string;
  rating?: number;
  order_product_image?: FileList | null;
  [key: string]: any;
}

type ReviewStore = {
  review: OrderModel;
  fetchReview: (orderId: number) => void;
  sendReview: (reviewForm: ReviewForm) => Promise<boolean>;
  storeReview: (orderId: number) => Promise<boolean>;
};

const reviewStore = create<ReviewStore>()(
  persist(
    (set, get) => ({
      review: {},
      fetchReview: async (orderId: number) => {
        const res = await Network.get(Api.reviews, {
          queryParameters: {
            order_id: orderId,
          },
        });

        if (res?.ok ?? false) {
          const body = await res!.json();
          const review = body;
          set({ review: review });
        }
      },
      sendReview: async (reviewForm: ReviewForm) => {
        const formData = new FormData();

        for (const key in reviewForm) {
          if (key === "order_product_image") {
            const images = reviewForm[key] as FileList;
            for (let i = 0; i < images.length; i++) {
              formData.append(`${key}[]`, images[i]);
            }
          } else {
            formData.append(key, reviewForm[key]);
          }
        }
        const res = await Network.post(Api.reviews, {
          data: formData,
        });

        if (res.ok ?? false) {
          Toast.show("Berhasil mengirim ulasan", {});
          return true;
        }

        return false;
      },
      storeReview: async (orderId: number) => {
        const res = await Network.post(Api.sendReview, {
          data: {
            order_id: orderId,
          },
        });

        if (res.ok ?? false) {
          Toast.show("Berhasil menyimpan ulasan", {});
          return true;
        }

        return false;
      },
    }),
    {
      name: "review-storage",
      getStorage: () => sessionStorage,
    }
  )
);

export default reviewStore;
