import React from 'react';

const TextSection = ({ title, desc }) => {
    return (
        <div className="text">
            <p className="title">
                {title}
            </p>
            <p className="desc" dangerouslySetInnerHTML={{ __html: desc }} />
        </div>
    );
};

export default TextSection;