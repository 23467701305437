import React from 'react';
import '../css/timeline.css';

function Timeline({ trackingData }) {
    return (
        <div className="card-tracking">
            {/* Map through the data array and render timeline items */}
            <div className="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                {trackingData.map((item, index) => (
                    <div key={index} className="vertical-timeline-item vertical-timeline-element">
                        <div>
                            <span className="vertical-timeline-element-icon bounce-in">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 7 7" fill="none" style={{ margin: '2px' }}>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.02838 1.84596C6.0898 1.90748 6.12432 1.99088 6.12432 2.07784C6.12432 2.16479 6.0898 2.24819 6.02838 2.30971L2.8565 5.48159C2.79498 5.54301 2.71158 5.57753 2.62463 5.57753C2.53768 5.57753 2.45428 5.54301 2.39275 5.48159L0.97088 4.05971C0.91292 3.99751 0.881367 3.91524 0.882863 3.83023C0.884364 3.74522 0.9188 3.66412 0.978921 3.604C1.03904 3.54388 1.12015 3.50945 1.20515 3.50794C1.29016 3.50644 1.37243 3.538 1.43463 3.59596L2.62463 4.78596L5.56463 1.84596C5.62614 1.78451 5.70953 1.75 5.7965 1.75C5.88344 1.75 5.96687 1.78451 6.02838 1.84596Z" fill="white" />
                                </svg>
                                {/* <img src={item.iconClass} alt="" /> */}
                            </span>
                            <div className="vertical-timeline-element-content bounce-in">
                                <h4 className="timeline-title">{item.status}</h4>
                                <p className="deskripsi" style={{ marginLeft: '230px' }}>{item.note}</p>
                                <span className="vertical-timeline-element-date">{item.updated_at}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        // <div className="row d-flex justify-content-center mt-70 mb-70">
        //     <div className="col-md-6">
        //         <div className="main-card mb-3 card">
        //             <div className="card-body">
        //                 <div className="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
        //                     {/* Map through the data array and render timeline items */}
        //                     {timelineData.map((item, index) => (
        //                         <div key={index} className="vertical-timeline-item vertical-timeline-element">
        //                             <div>
        //                                 <span className="vertical-timeline-element-icon bounce-in">
        //                                     <i className={item.iconClass}></i>
        //                                 </span>
        //                                 <div className="vertical-timeline-element-content bounce-in">
        //                                     <h4 className="timeline-title">{item.title}</h4>
        //                                     <p>{item.description}</p>
        //                                     <span className="vertical-timeline-element-date">{item.time}</span>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     ))}
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    );
}

export default Timeline;