import { shallow } from "zustand/shallow";
import reviewStore from "../store/review_store";

const reviewFacade = () => {
  const { review, fetchReview, sendReview, storeReview } = reviewStore(
    (state) => ({
      review: state.review,
      fetchReview: state.fetchReview,
      sendReview: state.sendReview,
      storeReview: state.storeReview,
    }),
    shallow
  );

  return { review, fetchReview, sendReview, storeReview };
};

export default reviewFacade;
