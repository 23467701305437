import { shallow } from "zustand/shallow";
import authStore from "../store/auth_store";

const authFacade = () => {
  const {
    isAuthenticated,
    email,
    phone,
    phone_country_code,
    googleAuth,
    login,
    loginForm,
    register,
    setRegisterField,
    registerForm,
    setField,
    loading,
    error,
    verifyOtp,
    resendOtp,
    profileForm,
    profileCompletion,
    setProfileFormField,
    logout,
    updateFcmToken,
  } = authStore(
    (state) => ({
      email: state.email,
      phone: state.phone,
      phone_country_code: state.phone_country_code,
      error: state.error,
      loading: state.loading,
      loginForm: state.loginForm,
      profileForm: state.profileForm,
      registerForm: state.registerForm,
      isAuthenticated: state.isAuthenticated,
      googleAuth: state.googleAuth,
      login: state.login,
      logout: state.logout,
      register: state.register,
      setField: state.setField,
      verifyOtp: state.verifyOtp,
      resendOtp: state.resendOtp,
      setRegisterField: state.setRegisterField,
      profileCompletion: state.profileCompletion,
      setProfileFormField: state.setProfileFormField,
      updateFcmToken: state.updateFcmToken,
    }),
    shallow
  );

  return {
    isAuthenticated,
    email,
    phone,
    phone_country_code,
    googleAuth,
    login,
    logout,
    loginForm,
    register,
    registerForm,
    setRegisterField,
    setField,
    loading,
    error,
    verifyOtp,
    resendOtp,
    profileForm,
    profileCompletion,
    setProfileFormField,
    updateFcmToken,
  };
};

export default authFacade;
