import { useLocation, useNavigate } from "react-router-dom";
import dummyProduct1 from "../../../assets/images/dummy-product-1.png";
import dummyProduct2 from "../../../assets/images/dummy-product-2.png";
import dummyProduct3 from "../../../assets/images/dummy-product-3.png";
import back from "../../../assets/images/ic_arrow-left.svg";
import { useEffect, useState } from "react";
import BottomSheet from "../../shared/components/bottom_sheet";
import productFacade from "../facade/product_facade";
import moment from "moment";
import "moment/locale/id";
import { ReviewFilter } from "../models/product_review_model";

function ReviewDetail() {
  const { productReviews, fetchProductReviews } = productFacade();

  const location = useLocation();
  const navigate = useNavigate();
  const [openRating, setOpenRating] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [rating, setRating] = useState(0);
  const [filterBy, setFilterBy] = useState("");
  const [isActiveFile, setIsActiveFile] = useState(false);

  function handleSort() {
    setOpenFilter(false);
    fetchProductReviews(location.state.id, {
      rating: rating,
      sorter: filterBy,
      is_file: isActiveFile,
    });
  }

  function handleRating() {
    setOpenRating(false);
    fetchProductReviews(location.state.id, {
      rating: rating,
      sorter: filterBy,
      is_file: isActiveFile,
    });
  }

  function handleChangeRating(ratingLocal: number) {
    if (ratingLocal == rating) {
      setRating(0);
    } else {
      setRating(ratingLocal);
    }
  }

  useEffect(() => {
    fetchProductReviews(location.state.id, {
      rating: rating,
      sorter: filterBy,
      is_file: isActiveFile,
    });
  }, [isActiveFile]);

  return (
    <div className="review-detail">
      <div className="header">
        <img
          src={back}
          alt=""
          className="back-btn"
          onClick={() => navigate(-1)}
        />
        <div className="title">Ulasan Pembeli</div>
      </div>
      <div className="content">
        <div className="info">
          <div className="rating">
            <p>
              {location.state.rating}
              <span>/5.0</span>
            </p>
          </div>
          <div className="text">
            <p>{parseFloat(location.state.rating) * 20}% Pembeli Merasa Puas</p>
            <span>
              {location.state.reviews_count} rating |{" "}
              {location.state.reviews_count} ulasan
            </span>
          </div>
        </div>
        <hr className="dotted" />
        <div className="tags">
          <div
            className={"tag " + (isActiveFile ? "active" : "inactive")}
            onClick={() => setIsActiveFile(!isActiveFile)}
          >
            Foto & Video
          </div>
          <div className="tag" onClick={() => setOpenRating(true)}>
            Rating
          </div>
          <div className="tag" onClick={() => setOpenFilter(true)}>
            Urutkan
          </div>
        </div>
        <hr />
        <div className="review">
          {productReviews.data?.length == 0 ? (
            <div className="no-review">Belum ada ulasan</div>
          ) : (
            productReviews.data?.map((review, index) => (
              <div className="review-card">
                <div className="review-card-header">
                  <div className="avatar">{review?.user?.name?.charAt(0)}</div>
                  <div className="title">{review?.user?.name}</div>
                  <div className="rate">
                    <span>
                      {moment(review?.created_at).locale("ID").fromNow()}
                    </span>
                    <div className="stars">
                      {Array.from({ length: review?.rating ?? 0 }).map(
                        (_, index) => (
                          <span className="active" key={index}></span>
                        )
                      )}
                      {Array.from({ length: 5 - (review?.rating ?? 0) }).map(
                        (_, index) => (
                          <span key={index}></span>
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div className="review-card-content">
                  <div className="text">
                    <p>{review?.description ?? ""}</p>
                    <div className="show-more">Lihat Semua</div>
                  </div>
                </div>
                <div className="review-card-attachment">
                  {review?.order_product_review_images?.map((data, index) => (
                    <figure
                      {...{
                        text:
                          index == 5
                            ? `${
                                (review?.order_product_review_images_count ??
                                  0) - 5
                              }+ Lainnya`
                            : null,
                      }}
                    >
                      <img src={data} alt="" />
                    </figure>
                  ))}
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <BottomSheet
        open={openRating}
        onClose={() => setOpenRating(false)}
        title="Rating dengan ulasan"
      >
        <div className="rate-list">
          {[...Array(5)].map((x, rateVal) => (
            <div
              className="rating"
              onClick={() => handleChangeRating(5 - rateVal)}
            >
              <div
                className={
                  "check-box" + (5 - rateVal == rating ? " active" : "")
                }
              ></div>
              {[...Array(5 - rateVal)].map((x, j) => (
                <div className="star"></div>
              ))}
              <span>{5 - rateVal} Rating</span>
            </div>
          ))}
        </div>
        <div className="primary-button" onClick={handleRating}>
          Tampilkan
        </div>
      </BottomSheet>
      <BottomSheet
        open={openFilter}
        onClose={() => setOpenFilter(false)}
        title="Rating dengan ulasan"
      >
        <div className="rate-list">
          {Object.values(ReviewFilter).map((filter, i) => (
            <div className="rating" onClick={() => setFilterBy(filter.value)}>
              <div
                key={filter.value}
                className={
                  "check-box" + (filter.value == filterBy ? " active" : "")
                }
              ></div>
              <span>{filter.text}</span>
            </div>
          ))}
        </div>
        <div className="primary-button" onClick={handleSort}>
          Tampilkan
        </div>
      </BottomSheet>
    </div>
  );
}

export default ReviewDetail;
