import Nojob from "../../../assets/images/no-job.svg";
export default function NoJobFound() {
    return (
        <div className="no-job">
            <div className="text">
                <p className="text-top">Tidak Ada <span>Lowongan</span></p>
                <p className="text-bottom">
                Maaf, saat ini belum ada lowongan yang tersedia. Silakan cek kembali nanti.
                </p>
            </div>
            <div className="d-flex w-100 justify-content-center">
                <img src={Nojob} alt="" />
            </div>
        </div>
    );
}
