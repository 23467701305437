import { useRef, useState } from "react";
import CardProfitReseller from "../components/card-profit-reseller";
import Navbar from "../components/navbar";
import Footer from "../components/footer";

export default function Reseller() {
  const [isPlay, setIsPlay] = useState(false);

  const videoRef = useRef(null);

  const handlePlayClick = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlay(true);
    } else {
      videoRef.current.pause();
      setIsPlay(false);
    }
  };

  function ProfitItem(props) {
    return (
      <div className="item">
        <img src={`svg/reseller-svg-${props.imgProfit}.svg`} alt="" />
        <h3>{props.titleProfit}</h3>
      </div>
    );
  }

  return (
    <div className="wrapper-pages">
      <Navbar />
      <div className="reseller">
        <div className="heading">
          <div
            className="play-button-wrapper"
            style={{ display: isPlay ? "none" : "flex" }}
            onClick={handlePlayClick}
          >
            <svg
              className="play-button"
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="80" height="80" rx="40" fill="#EEAAAA" />
              <path
                d="M60.4096 39.8013C60.4109 40.3418 60.2723 40.8734 60.0073 41.3445C59.7423 41.8156 59.3598 42.2101 58.8972 42.4897L30.2246 60.0291C29.7412 60.3251 29.1875 60.4867 28.6207 60.4972C28.054 60.5077 27.4947 60.3667 27.0007 60.0888C26.5114 59.8153 26.1037 59.4163 25.8197 58.9329C25.5357 58.4496 25.3856 57.8993 25.3848 57.3387V22.2638C25.3856 21.7032 25.5357 21.1529 25.8197 20.6696C26.1037 20.1862 26.5114 19.7873 27.0007 19.5137C27.4947 19.2358 28.054 19.0948 28.6207 19.1053C29.1875 19.1158 29.7412 19.2774 30.2246 19.5734L58.8972 37.1128C59.3598 37.3924 59.7423 37.7869 60.0073 38.258C60.2723 38.7291 60.4109 39.2607 60.4096 39.8013Z"
                fill="white"
              />
            </svg>
          </div>
          <video
            ref={videoRef}
            controls={!isPlay ? false : true}
            src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4"
          ></video>
        </div>

        <div className="content">
          <section className="why-section">
            <div className="container">
              <h1>
                <span>Mengapa</span> Jualan Produk MamaBear
              </h1>

              <div className="why-reseller-wrapper">
                <CardProfitReseller
                  img={<img src="svg/reseller-svg-1.svg" alt="" />}
                  desc={
                    "Pertumbuhan dan minat beli produk MamaBear di Indonesia terus meningkat."
                  }
                  title={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: "Daya Beli Tinggi",
                      }}
                    />
                  }
                />
                <CardProfitReseller
                  img={<img src="svg/reseller-svg-2.svg" alt="" />}
                  desc={
                    "Produk-produk kami memiliki peluang repeat order yang tinggi"
                  }
                  title={
                    <div dangerouslySetInnerHTML={{ __html: "Keuntungan" }} />
                  }
                />
                <CardProfitReseller
                  img={<img src="svg/reseller-svg-3.svg" alt="" />}
                  desc={
                    "Produk kami yang sangat diminati pasar memiliki peluang market yang besar"
                  }
                  title={
                    <div
                      dangerouslySetInnerHTML={{ __html: "Peluang Bisnis" }}
                    />
                  }
                />
                <CardProfitReseller
                  img={<img src="svg/reseller-svg-4.svg" alt="" />}
                  desc={
                    "Produk MamaBear sudah termasuk kebutuhan utama di berbagai kalangan"
                  }
                  title={
                    <div
                      dangerouslySetInnerHTML={{ __html: "Kebutuhan Utama" }}
                    />
                  }
                />
              </div>
            </div>
          </section>

          <section className="profit-reseller">
            <div className="container">
              <div className="right">
                <h1>
                  <span>Keuntungan Menjadi</span>
                  <br /> Reseller MamaBear
                </h1>
                <div className="profit-reseller-wrapper">
                  <ProfitItem
                    titleProfit={"Registrasi Gratis"}
                    imgProfit={"5"}
                  />
                  <ProfitItem
                    titleProfit={"Gratis support marketing oleh pusat"}
                    imgProfit={"6"}
                  />
                  <ProfitItem
                    titleProfit={"Harga Produk Stabil"}
                    imgProfit={"7"}
                  />
                  <ProfitItem
                    titleProfit={"Terdapat di web agen resmi"}
                    imgProfit={"8"}
                  />
                  <ProfitItem
                    titleProfit={"Margin sehat dengan repeat order tinggi"}
                    imgProfit={"9"}
                  />
                  <ProfitItem
                    titleProfit={"Gratis materi promosi"}
                    imgProfit={"10"}
                  />
                </div>
              </div>
              <div className="left">
                <img src="images/reseller-big-img-2.png" alt="" />
              </div>
            </div>
          </section>

          <div className="join-reseller">
            <div className="container">
              <div className="join-reseller-inner">
                <div className="right">
                  <div className="text">
                    <h4>Ayoo!!</h4>
                    <h1 className="h1-xl">Bergabung Reseller MamaBear</h1>
                    <p className="p-md">
                      Hubungi Whatsapp di bawah ini Untuk Mendaftar Menjadi
                      Reseller MamaBear!
                    </p>
                  </div>

                  <button
                    onClick={() =>
                      window.open("https://wa.me/628888695757", "_blank")
                    }
                  >
                    Daftar Sekarang
                    <svg
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.91438 16.5315C6.65562 16.8549 6.70806 17.3269 7.03151 17.5856C7.35495 17.8444 7.82692 17.7919 8.08568 17.4685L12.0857 12.4685C12.3048 12.1946 12.3048 11.8054 12.0857 11.5315L8.08568 6.53145C7.82692 6.208 7.35495 6.15556 7.03151 6.41432C6.70806 6.67308 6.65562 7.14505 6.91438 7.46849L10.5396 12L6.91438 16.5315ZM12.9144 16.5315C12.6556 16.8549 12.7081 17.3269 13.0315 17.5856C13.355 17.8444 13.8269 17.7919 14.0857 17.4685L18.0857 12.4685C18.3048 12.1946 18.3048 11.8054 18.0857 11.5315L14.0857 6.53145C13.8269 6.208 13.355 6.15556 13.0315 6.41432C12.7081 6.67308 12.6556 7.14505 12.9144 7.46849L16.5396 12L12.9144 16.5315Z"
                        fill="#DE8D8D"
                      />
                    </svg>
                  </button>
                </div>
                <div className="left">
                  <img src="images/reseller-big-img-3.png" alt="" />
                  <img
                    className="desktop"
                    src="images/reseller-big-img-3-desktop.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
