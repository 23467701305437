import { shallow } from "zustand/shallow";
import notificationStore from "../store/notification_store";

const notificationFacade = () => {
  const { notifications, fetchNotifications, readNotification } =
    notificationStore(
      (state) => ({
        notifications: state.notifications,
        fetchNotifications: state.fetchNotifications,
        readNotification: state.readNotification,
      }),
      shallow
    );

  return { notifications, fetchNotifications, readNotification };
};

export default notificationFacade;
