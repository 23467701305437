import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import {
    EffectCoverflow,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination"
import "swiper/css/effect-coverflow";
import "react-tabs/style/react-tabs.css";
import YoutubeVideo from "../../components/youtube";
import Profile from "../../components/profile";
import History from "../../components/history";
import Value from "../../components/value";
import BannerAboutUs from "../../../../assets/images/banner-about-us.png";
import PatternLeaf from "../../../../assets/images/pattern-leaf.svg";
import BestQuality1 from "../../../../assets/images/best-quality1.png";
import BestQuality2 from "../../../../assets/images/best-quality2.png";
import BestQuality3 from "../../../../assets/images/best-quality3.png";
import Health from "../../../../assets/images/health.png";
import PatternHealth from "../../../../assets/images/pattern-health.png";
import Ellipse from "../../../../assets/images/ellipse-pink.png";
import PatternHistory1 from "../../../../assets/images/pattern-history1.png";
import PatternHistory2 from "../../../../assets/images/pattern-history2.png";
import OurHistory1 from "../../../../assets/images/our-history1.png";
import OurHistory2 from "../../../../assets/images/our-history2.png";
import OurHistory3 from "../../../../assets/images/our-history3.png";
import OurHistory4 from "../../../../assets/images/our-history4.png";
import PatternTeam from "../../../../assets/images/pattern-team.png";
import OurTeam1 from "../../../../assets/images/our-team1.png";
import OurTeam2 from "../../../../assets/images/our-team2.png";
import OurTeam3 from "../../../../assets/images/our-team3.png";
import OurTeam4 from "../../../../assets/images/our-team4.png";
import OurGallery1 from "../../../../assets/images/gallery1.png";
import OurGallery2 from "../../../../assets/images/gallery2.png";
import OurGallery3 from "../../../../assets/images/gallery3.png";
import OurGallery4 from "../../../../assets/images/gallery4.png";
import OurGallery5 from "../../../../assets/images/gallery5.png";
import OurGallery6 from "../../../../assets/images/gallery6.png";
import OurGallery7 from "../../../../assets/images/gallery7.png";
import Testimoni1 from "../../../../assets/images/testimoni1.png";
import Affiliate from "../../../../assets/images/affiliate.png";
import PatternAffiliate from "../../../../assets/images/pattern-affiliate.png";
import ArrowDown from "../../../../assets/images/direction-down.svg";

export default function AboutUs() {
    const SwiperButtonPrev = () => {
        const swiper = useSwiper();

        return (
            <svg
                onClick={() => {
                    swiper.slidePrev();
                }}
                width="64"
                height="64"
                viewBox="0 0 70 70"
                fill="#DE8D8D"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="0.472973"
                    y="0.472973"
                    width="69.0541"
                    height="69.0541"
                    rx="34.527"
                />
                <rect
                    x="0.472973"
                    y="0.472973"
                    width="69.0541"
                    height="69.0541"
                    rx="34.527"
                />
                {/* <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.9209 1.50459C17.9206 1.90228 17.7623 2.28355 17.4809 2.56459L9.80895 10.2366C9.57673 10.4687 9.39252 10.7444 9.26684 11.0477C9.14117 11.3511 9.07648 11.6762 9.07648 12.0046C9.07648 12.3329 9.14117 12.6581 9.26684 12.9614C9.39252 13.2648 9.57673 13.5404 9.80895 13.7726L17.4709 21.4346C17.7442 21.7175 17.8954 22.0964 17.892 22.4897C17.8885 22.883 17.7308 23.2592 17.4527 23.5373C17.1746 23.8154 16.7983 23.9732 16.405 23.9766C16.0118 23.98 15.6329 23.8288 15.3499 23.5556L7.68795 15.8996C6.65771 14.8673 6.0791 13.4685 6.0791 12.0101C6.0791 10.5517 6.65771 9.15285 7.68795 8.12059L15.3599 0.443587C15.5697 0.233671 15.8371 0.0907004 16.1281 0.0327662C16.4192 -0.0251679 16.7209 0.00453824 16.9951 0.118126C17.2692 0.231713 17.5036 0.424077 17.6684 0.670876C17.8332 0.917674 17.9211 1.20782 17.9209 1.50459Z"
                fill="white"/> */}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.3294 35.8977C22.8008 35.3691 22.8008 34.512 23.3294 33.9834L30.5484 26.7644C31.077 26.2358 31.9341 26.2358 32.4627 26.7644C32.9913 27.293 32.9913 28.15 32.4627 28.6786L27.5543 33.587H45.9437C46.6912 33.587 47.2973 34.193 47.2973 34.9405C47.2973 35.6881 46.6912 36.2941 45.9437 36.2941H27.5543L32.4627 41.2025C32.9913 41.7311 32.9913 42.5881 32.4627 43.1167C31.9341 43.6453 31.077 43.6453 30.5484 43.1167L23.3294 35.8977Z"
                    fill="#FFFFFF"
                />
            </svg>
        );
    };

    const SwiperButtonNext = () => {
        const swiper = useSwiper();

        return (
            <svg
            onClick={() => swiper.slideNext()}
            width="64"
            height="64"
            viewBox="0 0 70 70"
            fill="#DE8D8D"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.472973"
                y="0.472973"
                width="69.0541"
                height="69.0541"
                rx="34.527"
            />
            <rect
                x="0.472973"
                y="0.472973"
                width="69.0541"
                height="69.0541"
                rx="34.527"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M46.6706 35.8977C47.1992 35.3691 47.1992 34.512 46.6706 33.9834L39.4516 26.7644C38.923 26.2358 38.0659 26.2358 37.5373 26.7644C37.0087 27.293 37.0087 28.15 37.5373 28.6786L42.4457 33.587H24.0563C23.3088 33.587 22.7027 34.193 22.7027 34.9405C22.7027 35.6881 23.3088 36.2941 24.0563 36.2941H42.4457L37.5373 41.2025C37.0087 41.7311 37.0087 42.5881 37.5373 43.1167C38.0659 43.6453 38.923 43.6453 39.4516 43.1167L46.6706 35.8977Z"
                fill="#FFFFFF"
            />
        </svg>


        )
    }

    return (
        <div className="wrapper-pages">
            <Navbar />

            <div className="about-us">
                <div className="hero">
                    <img src={BannerAboutUs} alt="" />
                    <div className="wrapper">
                        <div className="title-hero">
                            <h1>Tentang <span>Kami</span></h1>
                            <p>Mengutamakan Kesehatan dan Kenyammanan Ibu Menyusui</p>
                        </div>
                    </div>
                </div>

                <div className="our-value">
                    <img src={PatternLeaf} alt="" class="left" />
                    <img src={PatternLeaf} alt="" class="right" />
                    <div className="wrapper">
                        <div className="text-title">
                            <h2>
                                Nilai <span>Kami</span>
                            </h2>
                            <p>Kami percaya bahwa makanan yang sehat dan bergizi sangat penting bagi ibu menyusui. Oleh karena itu, kami hanya menggunakan bahan-bahan terbaik untuk memastikan setiap produk kami tidak hanya lezat tetapi juga memberikan nutrisi yang dibutuhkan. Kami berkomitmen untuk:</p>
                        </div>
                        <div className="our-value-wrapper">
                            <Value
                                isLeft={true}
                                img={BestQuality1}
                                title={"Kualitas Terbaik"}
                                desc={"Kami hanya menggunakan bahan-bahan alami dan segar, yang dipilih dengan hati-hati dari petani dan pemasok terpercaya. Setiap bahan yang masuk ke dapur kami telah melalui proses seleksi ketat untuk memastikan bahwa hanya yang terbaik yang kami sajikan kepada Anda."}
                            />
                            <Value
                                isLeft={false}
                                img={BestQuality2}
                                title={"Kesehatan Ibu dan Bayi"}
                                desc={"Kami bekerja sama dengan ahli nutrisi untuk memastikan setiap makanan dan minuman yang kami produksi mengandung nutrisi yang diperlukan untuk meningkatkan kualitas ASI dan kesehatan ibu. Produk kami bebas dari bahan pengawet, pewarna buatan, dan aditif yang dapat membahayakan kesehatan."}
                            />
                            <Value
                                isLeft={true}
                                img={BestQuality3}
                                title={"Keberlanjutan"}
                                desc={"Mulai dari pemilihan bahan baku hingga proses pengemasan, kami memastikan bahwa setiap langkah diambil dengan mempertimbangkan dampak lingkungan. Kami menggunakan kemasan yang ramah lingkungan dan mendukung inisiatif daur ulang untuk mengurangi jejak karbon kami."}
                            />
                        </div>
                    </div>
                </div>

                <div className="our-history">
                    <img src={Ellipse} alt="" className="ellipse-pink" />
                    <div className="wrapper">
                        <div className="text-title">
                            <h2>
                                Sejarah <span>MamaBear</span> <br />
                                Indonesia
                            </h2>
                            <p>Sekilas, cerita Mamabear mungkin hanya tentang keinginan seorang ibu untuk menyusui anaknya. Tetapi, setiap langkah Mamabear juga bercerita tentang perjuangan sosok ibu menghadapi tantangan membesarkan anak, baik secara fisik maupun mental.</p>
                        </div>
                        <YoutubeVideo />
                        <div className="our-history-wrapper">
                            <History
                                itemClass={"our-history-item"}
                                patternImg={PatternHistory1}
                                isLeft={true}
                                patternClass={"pattern-history1"}
                                img={OurHistory1}
                                title={"Berawal dari gagal menyusui anak"}
                                desc={"Agnes Susanti Widjaja adalah ibu dari 3 anak sekaligus founder Mamabear Indonesia. Sama seperti semua ibu yang baru mulai menyusui, ekspektasi Agnes adalah ASI lancar setelah melahirkan. Tapi realitanya, ASI tidak lancar mengakibatkan problem berat badan bayi yang tidak kunjung naik. Bahkan, Agnes juga merasakan baby blues yang mengarah ke depresi paska melahirkan. Akhirnya, impian ASI eksklusif harus berakhir hanya setelah 40 hari setelah melahirkan karena sulit ASI."}
                            />
                            <History
                                itemClass={"our-history-item"}
                                patternImg={PatternHistory2}
                                isLeft={false}
                                patternClass={"pattern-history2"}
                                img={OurHistory2}
                                title={"Mulai Termotivasi"}
                                desc={"Walaupun berat, ini jadi motivasi bagi Agnes untuk mencari solusi ASI yang efektif. Berbekal pengalaman dan ilmu sebagai tamatan Sarjana di Food Technology & Nutrition, Agnes banyak menemukan manfaat dari bahan bahan natural yang dapat menjadi ASI Booster. Bahan-bahan natural tersebut lalu dikombinasikan dengan riset dan teknologi terkini sehingga terciptalah produk pelancar ASI dengan brand MamaBear yang dapat digunakan untuk membantu produksi ASI ibu menyusui."}
                            />
                            <History
                                itemClass={"our-history-item"}
                                patternImg={PatternHistory1}
                                isLeft={true}
                                patternClass={"pattern-history1"}
                                img={OurHistory3}
                                title={"Mulai Hadir di Indonesia"}
                                desc={"Mamabear Indonesia lalu hadir di tahun 2016 dengan fokus pada produk- produk ASI Booster berkualitas yang terbukti meningkatkan produksi ASI ibu menyusui. Setiap produk Mamabear berpedoman pada riset, bahan alami, dan produksi lokal dengan standar higienitas tertinggi. Setiap produk dirancang untuk menuntun, menutrisi dan melindungi kesehatan ibu & bayi."}
                            />
                            <History
                                itemClass={"our-history-item"}
                                patternImg={PatternHistory2}
                                isLeft={false}
                                patternClass={"pattern-history2"}
                                img={OurHistory4}
                                title={"Membantu Lebih dari 1.000.000++ Ibu Menyusui"}
                                desc={"Hingga kini, Mamabear telah membantu lebih dari 1.000.000 ibu menyusui. Kami juga memiliki lebih dari 500 agen di Indonesia, Filipina, dan beberapa negara lain. Mamabear siap hadir dan membantu mama dengan solusi menyusui yang terbaik.  "}
                            />
                        </div>
                    </div>
                </div>

                <div className="our-team">
                    <img src={PatternTeam} alt="" className="pattern-team" />
                    <img src={PatternLeaf} alt="" class="left" />
                    <img src={PatternLeaf} alt="" class="right" />
                    <div className="wrapper">
                        <div className="text-title">
                            <h2>
                                Tim <span>Kami</span>
                            </h2>
                            <p>Tim kami terdiri dari para ahli nutrisi, chef berpengalaman, dan ibu-ibu yang paham betul kebutuhan ibu menyusui. Setiap anggota tim kami membawa keahlian dan semangat mereka untuk menciptakan produk-produk berkualitas tinggi yang bisa Anda percayai.</p>
                        </div>
                        <div className="our-team-wrapper">
                            <Profile
                                img={OurTeam1}
                                name={"Agnes Susanti Widjaja"}
                                job={"Founder & CEO"}
                            />
                            <Profile
                                img={OurTeam2}
                                name={"Ahmad Herwitz"}
                                job={"Head of Nutrition"}
                            />
                            <Profile
                                img={OurTeam3}
                                name={"Alena Rhiel Madsen"}
                                job={"Lead Chef"}
                            />
                            <Profile
                                img={OurTeam4}
                                name={"Gustavo Bator"}
                                job={"Customer Relations Manager"}
                            />
                        </div>
                    </div>
                </div>

                <div className="our-gallery">
                    <img src={Ellipse} alt="" className="ellipse-pink" />
                    <img src={PatternLeaf} alt="" class="left" />
                    <img src={PatternLeaf} alt="" class="right" />
                    <div className="wrapper">
                        <div className="text-title">
                            <h2>
                                Gallery <span>Kami</span>
                            </h2>
                            <p>Di bawah ini adalah beberapa momen berharga dan produk unggulan kami yang telah menjadi bagian dari perjalanan para ibu menyusui:</p>
                        </div>
                        <div className="our-gallery-wrapper">
                            <div className="our-gallery-item">
                                <img src={OurGallery1} alt="" />
                                <img src={OurGallery2} alt="" />
                            </div>
                            <div className="our-gallery-item">
                                <img src={OurGallery3} alt="" />
                                <img src={OurGallery4} alt="" />
                                <img src={OurGallery5} alt="" />
                            </div>
                            <div className="our-gallery-item">
                                <img src={OurGallery6} alt="" />
                                <img src={OurGallery7} alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="testimoni">
                    <img src={Ellipse} alt="" className="ellipse-pink" />
                    <div className="wrapper">
                        <div className="text-title">
                            <h2>
                                Testimoni <span>Pelanggan</span>
                            </h2>
                            <p>Kami bangga telah membantu banyak ibu menyusui dalam perjalanan mereka. Berikut adalah beberapa testimoni dari pelanggan kami yang puas dengan produk dan layanan kami:</p>
                        </div>
                        <div className="testimoni-wrapper">
                            <Swiper
                                className={"testimoniSwiper"}
                                effect="coverflow"
                                modules={[EffectCoverflow]}
                                grabCursor={true}
                                centeredSlides={true}
                                slidesPerView="auto"
                                coverflowEffect={{
                                    rotate: 0,
                                    stretch: 80,
                                    depth: 200,
                                    modifier: 1,
                                    slideShadows: false,
                                }}
                                spaceBetween={0}
                                // slidesPerView={1}
                                loop={false}
                                navigation
                                pagination={false}
                            >
                                <SwiperSlide>
                                    <img src={Testimoni1} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={Testimoni1} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={Testimoni1} alt="" />
                                </SwiperSlide>
                                <div className="navigation-carousel">
                                    <div className="navigation-item">
                                        <SwiperButtonPrev />
                                        <SwiperButtonNext />
                                    </div>
                                </div>
                            </Swiper>
                        </div>
                    </div>
                </div>

                <div className="affiliate">
                        <img src={Affiliate} alt="" className="img-affiliate" />
                        <img src={PatternAffiliate} alt="" className="pattern-affiliate" />
                        <div className="wrapper">
                            <div className="affiliate-wrapper">
                                <div className="text-title">
                                    <h2>
                                        Ayoo!!
                                    </h2>
                                    <h1>
                                        Bergabung Affiliate MamaBear
                                    </h1>
                                    <p>Hubungi Whatsapp di bawah ini Untuk Mendaftar Menjadi Affiliate MamaBear!</p>
                                </div>
                                <a href="">
                                    Daftar Sekarang
                                    <img src={ArrowDown} alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
            </div>

            <Footer />
        </div>
    )
}