import { create } from "zustand";
import { Cart, CartPagination, Product } from "../models/cart_model";
import { persist } from "zustand/middleware";
import Network from "../../../utils/network_utils";
import Api from "../../../endpoint/api";
import Toast from "../../../utils/toast";
import { ProductDetailModel } from "../../product/models/product_detail_model";
import authFacade from "../../authentication/facades/auth_facade";

export interface CartForm {
  product_id?: number;
  qty?: number;
  _method?: string;
  product?: ProductDetailModel;
}

interface storeCartResponse {
  status: boolean;
  body: Cart;
}

type CartStore = {
  cartForm: CartForm;
  carts: CartPagination;
  fetchCart: (isAuthenticated: boolean) => void;
  storeCart: (form: CartForm) => Promise<storeCartResponse>;
  updateCart: (form: CartForm) => Promise<boolean>;
  deleteCart: (cartIds?: number[]) => Promise<boolean>;
};

const cartStore = create<CartStore>()(
  persist(
    (set, get) => ({
      cartForm: {},
      carts: {},
      fetchCart: async (isAuthenticated) => {
        if (isAuthenticated) {
          const res = await Network.get(Api.cart, {});
          if (res?.ok ?? false) {
            const body = await res!.json();
            const carts = body;
            set({ carts: carts });
          }
        } else {
          const carts = {
            data: JSON.parse(localStorage.getItem("unauthCarts") ?? "[]"),
          } as CartPagination;
          set({ carts: carts });
        }
      },
      storeCart: async (form) => {
        const res = await Network.post(Api.cart, { data: form });
        if (res?.ok ?? false) {
          const body = await res!.json();
          // Toast.show(body["message"] ?? "error", {});
          return { status: true, body: body["data"] ?? {} };
        }
        Toast.show("Gagal menambahkan Produk", {});
        return { status: false, body: {} };
      },
      updateCart: async (form) => {
        form._method = "PUT";
        const res = await Network.post(Api.cartUpdate, { data: form });
        if (res?.ok ?? false) {
          return true;
        }
        return false;
      },
      deleteCart: async (cartIds) => {
        const res = await Network.post(Api.cartUpdate, {
          data: { cart_ids: cartIds, _method: "DELETE" },
        });
        if (res?.ok ?? false) {
          return true;
        }
        return false;
      },
    }),
    {
      name: "cart-storage",
      getStorage: () => sessionStorage,
    }
  )
);

export default cartStore;
