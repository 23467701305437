import { useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import { GetResi } from "./queries/use-resi-tracking";
import { useNavigate } from 'react-router-dom';

export default function CekResi() {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchFailed, setSearchFailed] = useState(false);
    const navigate = useNavigate();

    let Navigate = useNavigate();
    const handleChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSearch = async () => {
        try {
            if (!searchTerm.trim()) {
                throw new Error('Nomor resi tidak boleh kosong');
            }

            const resiData = await GetResi(searchTerm.trim());
            if (resiData) {
                navigate(`/cekdetail/${searchTerm.trim()}`);
            } else {
                setSearchFailed(true);
            }
        } catch (error) {
            console.error(error);
            setSearchFailed(true);
        }
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="wrapper-pages">
            <Navbar />
            <div className="reseller">
                <div className="content">
                    <div className="cek-resi">
                        <h1>
                            <span>Lacak</span> Pengiriman
                        </h1>
                        <p>
                            {windowWidth > 600
                                ? 'Anda bisa memantau terus proses pengiriman anda. Masukkan Nomor Resi Pengiriman Anda'
                                : 'Masukkan Nomor Resi Anda'}
                        </p>
                        <div className="side-card">
                            <form>
                                <input
                                    type="text"
                                    value={searchTerm}
                                    onChange={handleChange}
                                    placeholder="Nomor Resi"
                                />
                                <button type="button" onClick={handleSearch}>Check</button>
                            </form>
                        </div>

                        {searchFailed && (
                            <div className="empty-resi">
                                <h1>Tidak Ditemukan</h1>
                                <p className="tag">Nomor Resi <span className="resi-number">#{searchTerm}</span></p>
                                <p className="informasi-cs">Silahkan hubungi CS kami di <span><a href="https://api.whatsapp.com/send/?phone=628888695757&text=Hi%20MamaBear%2C+Saya+mau+konsultASI+nih...%F0%9F%98%8A&app_absent=0">+62888-869-5757 </a></span> untuk mendapatkan bantuan lebih lanjut</p>
                            </div>
                        )}
                        {/* {searchFailed && (
                            <div className="empty-resi">
                                <h1>Tidak Ditemukan</h1>
                                <p className="tag">Nomor Resi <span className="resi-number">#{searchTerm}</span></p>
                                <p className="informasi-cs">Silahkan hubungi CS kami di <span><a href="https://api.whatsapp.com/send/?phone=628888695757&text=Hi%20MamaBear%2C+Saya+mau+konsultASI+nih...%F0%9F%98%8A&app_absent=0">+62888-869-5757 </a></span> untuk mendapatkan bantuan lebih lanjut</p>
                            </div>
                        )} */}
                    </div>
                    {windowWidth > 600 && (
                        <div className="join-reseller">
                            <div className="container">
                                <div className="join-reseller-inner">
                                    <div className="right">
                                        <div className="text">
                                            <h4>Ayoo!!</h4>
                                            <h1 className="h1-xl">Cek Pengiriman Anda Sekarang!</h1>
                                            <p className="p-md">
                                                Masukkan nomor cek resi anda diatas untuk bisa memantau proses pengiriman anda.
                                            </p>
                                        </div>
                                        <br />&nbsp;<br />
                                    </div>
                                    <div className="left">
                                        <img src="images/reseller-big-img-3.png" alt="" />
                                        <img
                                            className="desktop"
                                            src="images/reseller-big-img-3-desktop.png"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
}
