import { ReactNode, useEffect, useState } from "react";
import BottomSheet from "../../../shared/components/bottom_sheet";
import golfIcon from "../../../../assets/images/golf-hole.svg";
import documentIcon from "../../../../assets/images/document_pink.svg";
import { NavLink, useNavigate } from "react-router-dom";

interface TrxMoreOptionProp {
  open?: boolean;
  onClose?: () => void;
  onTrack?: () => void;
  onComplaint?: () => void;
  onComplainMore?: () => void;
}

function TrxMoreOptionDialog(props: TrxMoreOptionProp) {
  const navigate = useNavigate();

  return (
    <BottomSheet open={props.open} onClose={props.onClose} title="Opsi Lainnya">
      <div className="more-options">
        <div
          className="option"
          onClick={() => {
            props.onClose?.();
            props.onTrack?.();
          }}
        >
          <img src={golfIcon} className="icon" />
          <p>Lacak Pesanan</p>
        </div>
        <div style={{ width: "100%" }} onClick={props.onComplainMore}>
          <div className="option">
            <img src={documentIcon} className="icon" />
            <p>Ajukan Komplain</p>
          </div>
        </div>
      </div>
    </BottomSheet>
  );
}

export default TrxMoreOptionDialog;
