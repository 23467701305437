import { useState } from "react";
import BottomNavigation from "./home/bottom_navigation";
import FavouritePage from "./home/favourite_page";
import SettingPage from "./home/setting_page";
import DashboardScreen from "../../dashboard/view/dashboard_screen";
import TransactionPage from "../../transaction/view/transaction_page";
import homeFacade from "../facades/home_facade";
import ProtectedContent from "../../shared/components/unauthenticated";

function HomeScreen() {
  const { activeMenu, setActiveMenu } = homeFacade();

  // return (
  //   <div
  //     style={{
  //       display: "flex",
  //       alignItems: "center",
  //       width: "100%",
  //       height: "100%",
  //       justifyContent: "center",
  //     }}
  //   >
  //     <h1>Coming Soon</h1>
  //   </div>
  // );

  return (
    <div className="home-screen">
      <div className="content">
        {
          {
            beranda: <DashboardScreen />,
            favorit: (
              <ProtectedContent title="Belum Ada Favorit">
                <FavouritePage />
              </ProtectedContent>
            ),
            transaksi: (
              <ProtectedContent title="Belum Ada Transaksi">
                <TransactionPage />
              </ProtectedContent>
            ),
            pengaturan: (
              <ProtectedContent>
                <SettingPage />
              </ProtectedContent>
            ),
          }[activeMenu]
        }
      </div>
      <BottomNavigation
        onChanged={(val) => setActiveMenu(val)}
        activeMenu={activeMenu}
      />
    </div>
  );
}

export default HomeScreen;
