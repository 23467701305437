import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProductModel from "../../../product/models/product_model";
import dummyProduct1 from "../../../../assets/images/dummy-product-1.png";
import dummyProduct2 from "../../../../assets/images/dummy-product-2.png";
import dummyProduct3 from "../../../../assets/images/dummy-product-3.png";
import emptyIcon from "../../../../assets/images/empty_favourite.svg";
import EmptyState from "../../components/empty_state";
import backIcon from "../../../../assets/images/back.svg";
import searchIcon from "../../../../assets/images/ic_search.svg";
import menuCart from "../../../../assets/images/ic_bag.svg";
import routes from "../../../../routes";
import productFacade from "../../../product/facade/product_facade";
import CartIcon from "../../../global_component/view/cart_icon";
import { CartForm } from "../../store/cart_store";
import cartFacade from "../../facades/cart_facade";
import Toast from "../../../../utils/toast";

function FavouritePage() {
  const navigate = useNavigate();
  const { products, fetchLikeProducts, likeProduct } = productFacade();
  const { storeCart } = cartFacade();
  const [search, setSearch] = useState("");
  const [cartForm, setCartForm] = useState<CartForm>();

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    fetchLikeProducts({ keyword: search });
  }, [search]);

  const handleLike = async (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    productId: number
  ) => {
    event.stopPropagation();
    await likeProduct(productId);
    fetchLikeProducts({ keyword: search });
  };

  const handleAddToCart = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    productId: number
  ) => {
    event.stopPropagation();
    setCartForm({
      product_id: productId,
      qty: 1,
    });

    if (cartForm) {
      storeCart(cartForm).then((res) => {
        if (res) {
          Toast.show("Berhasil menambahkan produk ke keranjang", {});
          navigate(routes.cart);
        }
      });
    }
  };

  return (
    <div className="page">
      <div className="header">
        <div className={"search"}>
          <img src={backIcon} alt="" className="back" />
          <input
            type="text"
            placeholder="Cari Produk di MamaBear"
            onChange={handleSearch}
            value={search}
          />
          <img src={searchIcon} alt="" className="action" />
        </div>
        <div className="actions">
          <CartIcon />
          {/* <img src={menuFilter} onClick={() => setOpen(true)} className="menu" /> */}
        </div>
      </div>
      <div className="page-content">
        {products?.data?.length == 0 ? (
          <EmptyState
            icon={emptyIcon}
            title="Tidak Ada Favorit"
            subtitle="Anda belum memiliki produk favorit.<br/>Tidak ada kata terlambat untuk mengubahnya"
          />
        ) : (
          <div className="grid">
            {products.data?.map((data, index) => {
              return (
                <div
                  className="items"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(routes.productDetails, { state: data });
                  }}
                >
                  <figure>
                    <img
                      src={data.image_url_text}
                      onError={(
                        e: React.SyntheticEvent<HTMLImageElement, Event>
                      ) => {
                        e.currentTarget.src = dummyProduct1;
                      }}
                    />
                    <div
                      className="liked"
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleLike(event, data.id ?? 0)}
                    ></div>
                    <div
                      className="cart"
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => handleAddToCart(event, data?.id ?? 0)}
                    ></div>
                  </figure>
                  <div className="desc">
                    <div className="title">{data.name ?? "Product Name"}</div>
                    <div className="price">
                      <span>{data.price_text}</span>
                    </div>
                    <div className="row crossAxisCenter">
                      {/* <span className="discount">50%</span>
                        <div className="original-price">Rp 150.000</div> */}
                      <div className="rating">({data.rating})</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default FavouritePage;
