import { useState, useEffect, ChangeEvent } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import productImage1 from "../../../assets/images/product-1.png";
import productImage2 from "../../../assets/images/product-2.png";
import uploadFile from "../../../assets/images/upload_file.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import routes from "../../../routes";
import { OrderProduct } from "../../order/models/order_model";
import reviewFacade from "../../order/facades/review_facade";

function ReviewDetailPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const orderProduct = location.state.orderProduct as OrderProduct;

  const { sendReview } = reviewFacade();

  const [selectedImages, setSelectedImages] = useState<FileList | null>(null);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);
  const [rating, setRating] = useState<number>(0);
  const [description, setDescription] = useState<string>("");

  useEffect(() => {
    if (orderProduct.order_product_review) {
      setRating(orderProduct.order_product_review.rating);
      setDescription(orderProduct.order_product_review.description);
    }
  });

  const handleImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      const imageArray = Array.from(files);

      const imageUrls: string[] = [];

      for (const file of imageArray) {
        const imageUrl = await readFileAsDataURL(file);
        imageUrls.push(imageUrl);
      }

      setImagePreviews((prevImagePreviews) => [
        ...prevImagePreviews,
        ...imageUrls,
      ]);

      setSelectedImages((prevSelectedImages) => {
        const newFileList = new DataTransfer();

        if (prevSelectedImages) {
          for (const file of Array.from(prevSelectedImages)) {
            newFileList.items.add(file);
          }
        }

        for (const file of imageArray) {
          newFileList.items.add(new File([file], file.name));
        }

        const newFileListObject = newFileList.files;

        return newFileListObject;
      });
    }
  };

  const readFileAsDataURL = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target && typeof event.target.result === "string") {
          resolve(event.target.result);
        } else {
          reject(new Error("Failed to read file as data URL."));
        }
      };
      reader.onerror = (event) => {
        reject(new Error("Failed to read file as data URL."));
      };
      reader.readAsDataURL(file);
    });
  };

  const handleRemoveImage = (index: number) => {
    const updatedImagePreviews = [...imagePreviews];
    updatedImagePreviews.splice(index, 1);
    setImagePreviews(updatedImagePreviews);

    if (selectedImages && index >= 0 && index < selectedImages.length) {
      // Convert the FileList to an array
      const imageArray = Array.from(selectedImages);

      // Remove the file at the specified index
      imageArray.splice(index, 1);

      const newFileList = new DataTransfer();

      for (const file of imageArray) {
        newFileList.items.add(file);
      }

      // Update the state with the new FileList
      setSelectedImages(newFileList.files);
    }
  };

  const handleSubmit = () => {
    const payload = {
      order_id: orderProduct.order_id,
      order_product_id: orderProduct.id,
      description: description,
      rating: rating,
      order_product_image: selectedImages ? selectedImages : null,
    };

    sendReview(payload).then((success) => {
      if (success) {
        navigate(routes.review, {
          state: { orderId: orderProduct.order_id },
        });
      }
    });
  };

  // function handleSingleChange(e: ChangeEvent<HTMLInputElement>, index: number) {
  //     if (e.target.files) {
  //         const selectedImage = e.target.files?.[0] || null;
  //         selectedImages[index] = selectedImage;
  //         imagePreviews[index] =
  //             setImagePreviews
  //     }
  // };

  const settings = {
    dots: true,
    autoplay: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="page product-review-page">
      <div className="header">
        <svg
          className="back"
          onClick={() => navigate(-1)}
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 8.5L6 12.5M6 12.5L10 16.5M6 12.5L18 12.5"
            stroke="#191919"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p>Beri Ulasan Produk</p>
      </div>
      <div className="image-section">
        <Slider {...settings}>
          {orderProduct.product_json.product_covers.map((cover) => (
            <img src={cover.file_url_text} />
          ))}
        </Slider>
      </div>
      <div className="review-content">
        <h2>{orderProduct.product_json.name}</h2>
        <hr />
        <p>
          Berikan penilaian berupa rating dan deskripsikan penilaianmu mengenai
          produk ini
        </p>
        <div className="rating">
          <div className="stars">
            {[...Array(5)].map((data, i) =>
              i < rating ? (
                <svg
                  onClick={() => setRating(i + 1)}
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.472 2.88211C11.8525 1.96898 13.1461 1.96898 13.5265 2.88211L15.5308 7.69229C15.6925 8.08047 16.0718 8.33332 16.4923 8.33332H21.7629C22.732 8.33332 23.2174 9.50501 22.5321 10.1903L18.2693 14.4531C18.0018 14.7206 17.9004 15.1121 18.0043 15.4758L19.6948 21.3926C19.9802 22.3916 18.8662 23.2048 18.0017 22.6285L13.0771 19.3454C12.7272 19.1122 12.2714 19.1122 11.9215 19.3454L7.00505 22.623C6.13775 23.2012 5.02009 22.3854 5.30646 21.3831L6.99427 15.4758C7.09819 15.1121 6.99675 14.7206 6.72925 14.4531L2.48751 10.2113C1.79447 9.5183 2.28531 8.33332 3.26541 8.33332H8.50623C8.92675 8.33332 9.30602 8.08047 9.46776 7.69229L11.472 2.88211Z"
                    fill="#FAD43B"
                  />
                </svg>
              ) : (
                <svg
                  onClick={() => setRating(i + 1)}
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.472 2.88211C11.8525 1.96898 13.1461 1.96898 13.5265 2.88211L15.5308 7.69229C15.6925 8.08047 16.0718 8.33332 16.4923 8.33332H21.7629C22.732 8.33332 23.2174 9.50501 22.5321 10.1903L18.2693 14.4531C18.0018 14.7206 17.9004 15.1121 18.0043 15.4758L19.6948 21.3926C19.9802 22.3916 18.8662 23.2048 18.0017 22.6285L13.0771 19.3454C12.7272 19.1122 12.2714 19.1122 11.9215 19.3454L7.00505 22.623C6.13775 23.2012 5.02009 22.3854 5.30646 21.3831L6.99427 15.4758C7.09819 15.1121 6.99675 14.7206 6.72925 14.4531L2.48751 10.2113C1.79447 9.5183 2.28531 8.33332 3.26541 8.33332H8.50623C8.92675 8.33332 9.30602 8.08047 9.46776 7.69229L11.472 2.88211Z"
                    fill="#FEE6E6"
                  />
                </svg>
              )
            )}
          </div>
          <span className="rate-text">({rating}.0 Rating)</span>
        </div>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          rows={6}
          placeholder="Ceritakan pengalamanmu tentang kualitas produk ini dan bagaimana pengirimannya"
        ></textarea>
        <div className="column">
          <div className="label">Unggah foto produk</div>
          <div className="text">Unggah beberapa foto produk</div>
        </div>
        <div className="attachment">
          {imagePreviews.map((preview, index) => (
            <figure className="active">
              <img src={preview} alt="" />
              <div
                className="remove"
                onClick={() => handleRemoveImage(index)}
              ></div>
            </figure>
          ))}
          <figure>
            <img src={uploadFile} alt="" />
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={handleImageChange}
            />
          </figure>
        </div>
        <div className="height-10"></div>
        {!orderProduct.order_product_review && (
          <div className="primary-button" onClick={handleSubmit}>
            Simpan Ulasan
          </div>
        )}
      </div>
    </div>
  );
}

export default ReviewDetailPage;
