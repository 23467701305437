import { ReactNode, useEffect, useState } from "react";
import BottomSheet from "../../../shared/components/bottom_sheet";
import golfIcon from "../../../../assets/images/golf-hole.svg";
import documentIcon from "../../../../assets/images/document_pink.svg";
import TextInput from "../../../shared/components/text_input";

interface ComplaintProp {
  open?: boolean;
  onClose?: () => void;
  onSubmit?: (whatsapp: string) => void;
}

function ComplaintDialog(props: ComplaintProp) {
  const [openWa, setOpenWa] = useState(false);

  const [whatsapp, setWhatsapp] = useState<string>("");

  return (
    <BottomSheet open={props.open} onClose={props.onClose} title="Opsi Lainnya">
      <div className="more-options" style={{ gap: 15, padding: 5 }}>
        <div className="text">
          {openWa
            ? "Masukkan nomor WhatsApp anda untuk melanjutkan mekanisme komplain yang anda ajukan."
            : "Ceritakan yang terjadi pada pesanan anda, lengkapi di halaman bertikutnya"}
        </div>
        {openWa ? (
          <>
            <TextInput
              prefix={<span>+62</span>}
              label="Nomor WhatsApp"
              isOutlined={true}
              value={whatsapp}
              onChange={(e) => setWhatsapp(e.target.value)}
              placeholder="Masukkan nomor"
            />
            <div className="row" style={{ marginTop: 5 }}>
              <div
                className="expanded primary-button"
                onClick={() => {
                  props.onClose?.();
                  props.onSubmit?.(whatsapp);
                }}
              >
                Lanjutkan
              </div>
              <div className="width-10"></div>
              <div
                className="expanded secondary-button"
                onClick={props.onClose}
              >
                Batal
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="option" onClick={() => setOpenWa(true)}>
              <p>Produk belum diterima</p>
            </div>
          </>
        )}
      </div>
    </BottomSheet>
  );
}

export default ComplaintDialog;
