import Navbar from "../components/navbar";
import Footer from "../components/footer";
import TextSection from "../components/text-section";

import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination"
import "swiper/css/effect-coverflow";
import "react-tabs/style/react-tabs.css";

import BannerIngre from "../../../assets/images/banner-ingredients.png";
import CardIngredient from "../components/card-ingredient";

import Bahan1 from "../../../assets/images/bahan1.png";
import Bahan2 from "../../../assets/images/bahan2.png";
import Bahan3 from "../../../assets/images/bahan3.png";
import Bahan4 from "../../../assets/images/bahan4.png";
import Bahan5 from "../../../assets/images/bahan5.png";
import Bahan6 from "../../../assets/images/bahan6.png";
import Bahan7 from "../../../assets/images/bahan7.png";
import Bahan8 from "../../../assets/images/bahan8.png";

export default function Ingredients() {
    const ingredienData = {
        isFetching: false,
        isSuccess: true,
        data: [
            {
                imgSrc: Bahan1, 
                nameProd: 'Teh Pelancar ASI',
                title: 'Fenugreek / Kelabat',
                desc: `
                    Salah satu bahan pemicu produksi ASI ( galactagogue) yang paling sering digunakan, Fenugreek atau disebut juga kelabat adalah tanaman herbal yang penuh manfaat stimulasi ASI. Bahkan, kemanjuran kelabat bukan hanya untuk meningkatkan jumlah produksi, tetapi juga membantu kenaikan berat badan bayi- terutama di masa awal kelahiran.
                    <br/><br/>
                    <b>Fakta Menarik</b> Tahukah Anda? Fenugreek atau kelabat punya rasa dan aroma yang hampir sama dengan sirup maple loh. Bahan ini juga sering dipakai untuk mengobati sakit menstruasi, problem kolesterol, diabetes dan berbagai keluhan lain.
                    <br/><br/>
                    <b>Penjelasan Ilmiah </b> Beberapa hasil studi menunjukkan kalau biji kelabat bisa merangsang pertumbuhan kelenjar susu. Sebuah penelitian juga menemukan komponen yang mirip hormon estrogen dan bisa meningkatkan jumlah ASI. Banyak eksperimen yang juga mengkonfirmasi hal yang sama, yaitu peningkatan produksi ASI yang cukup signifikan.
                    <br/><br/>
                    <b>Anjuran Pemakaian </b> Tidak direkomendasikan untuk wanita hamil. Jika Anda memiliki riwayat diabetes atau gula darah rendah, silahkan berkonsultasi pada dokter sebelum konsumsi.
                `,
            },
            {
                imgSrc: Bahan2, 
                nameProd: 'Teh Pelancar ASI.',
                title: 'Fennel / Adas',
                desc:`
                    Tanaman herbal liar yang penuh aroma, fennel atau adas terkenal akan khasiatnya untuk meningkatkan produksi ASI sekaligus kadar lemak susu, tanpa ada efek samping bagi tubuh manusia.
                    <br/><br/>
                    <b>Fakta Menarik </b>Karena aromanya yang harum seperti parfum, jangan heran jika fennel sering juga ditemukan sebagai bahan dasar pada sabun, kosmetik dan zat perasa.
                    <br/><br/>
                    <b>Penjelasan Ilmiah</b> Dua studi berskala kecil dengan tema fennel-therapy terbukti menunjukkan kenaikan pada beberapa parameter, yaitu volume ASI, kadar lemak dan berat badan bayi
                    <br/><br/>
                    <b>Anjuran Pemakaian </b>Konsumsi harus sesuai dengan dosis yang direkomendasikan, tidak untuk wanita hamil. Ada potensi efek alergi bagi yang sensitif terhadap seledri, wortel dan mugwort/tanaman baru Cina.
                `,
            },
            {
                imgSrc: Bahan3, 
                nameProd: 'Teh Pelancar ASI',
                title: 'Nigella Sativa ( Black Seed) / Jintan Hitam',
                desc:`
                    Dikenal juga dengan nama black seed atau jintan hitam, Nigella Sativa telah dipakai sebagai obat tradisional untuk meningkatkan volume ASI. Kandungan asam lemak dan protein yang kaya pada bahan ini, menjadikannya sebagai bahan medis yang banyak manfaat, termasuk untuk laktasi.
                    <br/><br/>
                    <b>Fakta Menarik</b> Salah satu tanaman obat tertua di dunia, para arkeolog pernah menemukan bukti penggunaan jintan hitam sejak 2.000 tahun lalu, termasuk di makam King Tut Mesir.
                    <br/><br/>
                    <b>Penjelasan Ilmiah</b> Menyusul popularitas jintan hitam di dunia medis global, tim riset lokal di Yogyakarta juga sudah meneliti efektivitas Nigella Sativa. Hasilnya, terdapat peningkatan kualitas dan kuantitas ASI secara signifikan. Ini karena kandungan lipid/ lemak biologis dan komponen hormon yang berperan aktif pada proses produksi ASI, salah satunya polifenol yang berkaitan dengan kadar prolaktin dan oxytocin.
                    <br/><br/>
                    <b>Anjuran Pemakaian</b> Tidak cocok untuk wanita hamil. Berpotensi menurunkan kadar gula darah – jika Anda memiliki riwayat diabetes atau gula darah rendah, silahkan berkonsultasi dengan dokter terlebih dahulu.
                `,
            },
            {
                imgSrc: Bahan4, 
                nameProd: 'Zoyamix dan Almon Mix.',
                title: 'Androgynus Leaf / Daun Katuk',
                desc:`
                    Tanaman ini tumbuh subur terutama di iklim tropis Asia Tenggara. Daun Katuk terkenal akan efek biofarma, diantaranya anti-bakteri, anti-anemik, dan anti radang. Beberapa nutrisi kesehatan yang terkandung adalah folat, vitamin dan polifenol yang bisa ditemukan di bagian daunnya. Sebagai salah satu obat alternatif, daun katuk sering diolah dalam bentuk ekstrak untuk meningkatkan produksi ASI .
                    <br/><br/>
                    <b>Penjelasan Ilmiah</b> Sebuah riset lama membuktikan bahwa pemberian dosis daun katuk secara oral bisa meningkatkan jumlah produksi ASI pada subyek tikus percobaan. Dilaporkan juga bahwa ekstrak daun katuk bisa meningkatkan produksi ASI pada ibu hingga 50.7% tanpa menurunkan kualitasnya sama sekali.
                    <br/><br/>
                    <b>Anjuran Pemakaian</b> Untuk dikonsumsi sesuai dosis yang direkomendasikan untuk menghindari rasa kantuk dan sembelit.
                `,
            },
            {
                imgSrc: Bahan6, 
                nameProd: 'Zoyamix dan Kukis Almond Oat.',
                title: 'Biji Flaxseed',
                desc:`
                    Kaya akan asam linoleat (ALA), biji flaxseed memiliki manfaat estrogenik yang bisa memicu suplai ASI beserta kandungan asam lemak Omega 3. Komponen lemak sehat ini bukan hanya penting untuk jumlah ASI, tetapi juga untuk memperbaiki kualitas susu ibu untuk kesehatan jantung dan otak bayi.
                    <br/><br/>
                    <b>Fakta Menarik</b> Tahukah Anda? Nama latin biji flaxseed adalah Linum usitatissimum, yang artinya “paling bermanfaat”! Tak lain karena kandungannya yang baik untuk kesehatan manusia.
                    <br/><br/>
                    <b>Penjelasan Ilmiah</b> 50% dari komponen ASI terdiri dari lemak, yaitu umumnya dalam bentuk Omega 3 (DHA) dan Omega 6 (AA). Sebuah studi mengkonfirmasi kinerja flaxseed yang merubah kandungan ALA menjadi asam lemak omega 3 (DHA & EPA). Dalam prosesnya tersebut, flaxseed efektif meningkatkan air susu, plasma, dan konten eritrosit/ sel darah dalam tubuh. Flaxseed juga bisa meningkatkan kadar prolaktin dan oxytocin, serta mengandung nutrisi sebagai bahan dasar pembentukan ASI.
                    <br/><br/>
                    <b>Anjuran Pemakaian </b> Untuk dikonsumsi sesuai dosis atau silahkan berkonsultasi pada dokter jika memiliki riwayat keluhan penggumpalan darah, diabetes dan tekanan darah. Lihat Lebih Sedikit
                `,
            },
            {
                imgSrc: Bahan7, 
                nameProd: 'Kukis Almond Oat.',
                title: 'Biji Flaxseed',
                desc:`
                    Kaya akan asam linoleat (ALA), biji flaxseed memiliki manfaat estrogenik yang bisa memicu suplai ASI beserta kandungan asam lemak Omega 3. Komponen lemak sehat ini bukan hanya penting untuk jumlah ASI, tetapi juga untuk memperbaiki kualitas susu ibu untuk kesehatan jantung dan otak bayi.
                    <br/><br/>
                    <b>Fakta Menarik</b>Tahukah Anda? Nama latin biji flaxseed adalah Linum usitatissimum, yang artinya “paling bermanfaat”! Tak lain karena kandungannya yang baik untuk kesehatan manusia.
                    <br/><br/>
                    <b>Penjelasan Ilmiah</b> 50% dari komponen ASI terdiri dari lemak, yaitu umumnya dalam bentuk Omega 3 (DHA) dan Omega 6 (AA). Sebuah studi mengkonfirmasi kinerja flaxseed yang merubah kandungan ALA menjadi asam lemak omega 3 (DHA & EPA). Dalam prosesnya tersebut, flaxseed efektif meningkatkan air susu, plasma, dan konten eritrosit/ sel darah dalam tubuh. Flaxseed juga bisa meningkatkan kadar prolaktin dan oxytocin, serta mengandung nutrisi sebagai bahan dasar pembentukan ASI.
                    <br/><br/>
                    <b>Anjuran Pemakaian </b>Untuk dikonsumsi sesuai dosis atau silahkan berkonsultasi pada dokter jika memiliki riwayat keluhan penggumpalan darah, diabetes dan tekanan darah. Lihat Lebih Sedikit
                `,
            },
            {
                imgSrc: Bahan8, 
                nameProd: 'Kukis Almond Oat.',
                title: 'Almond / Badam',
                desc:`
                    Kacang almond mentah terkenal kaya akan protein dan kalsium yang penting sifatnya bagi kesehatan tulang ibu menyusui. Konsumsi kacang almond selama masa laktasi diyakini bisa meningkatkan kekentalan ASI, sekaligus rasa manis dan jumlahnya secara keseluruhan.
                    <br/><br/>
                    <b>Fakta Menarik</b>Kacang almond sudah menjadi bagian dari diet pasca-melahirkan dan ibu menyusui di Timur Tengah dan India. Konon, rasa dan nilai ASI jadi semakin berkualitas. 
                    <br/><br/>    
                    <b>Penjelasan Ilmiah </b>Kandungan asam amino pada kacang- kacangan sejatinya merupakan komponen untuk pembentukan serotonin, yaitu saraf penghubung yang penting untuk proses laktasi. Selain itu, almond juga mengandung lemak tak jenuh yang bisa meningkatkan kepadatan ASI ¹⁸. Studi menunjukkan bahwa konsumsi makanan yang kaya akan lemak tak jenuh adalah salah satu faktor penting untuk profil ASI yang baik.
                    <br/><br/>
                    <b>Anjuran Pemakaian</b> Baik untuk wanita hamil atau menyusui, kacang almond secara umum aman untuk dikonsumsi dalam jumlah wajar. Silahkan berkonsultasi dengan dokter jika Anda memiliki alergi kacang.  `,
            }
        ]


    };

    return (
        <div className="wrapper-pages">
            <Navbar />

            <div className="about-us">
                <div className="hero">
                    <img src={BannerIngre} alt="" />
                    <div className="wrapper">
                        <div className="title-hero">
                            <h1>Bahan - Bahan<span> Kami</span></h1>
                        </div>
                    </div>
                </div>

                <div className="isi-term no-before">
                    <div className="wrapper">
                        <div className="container">
                            <div className="wrapp-card-base">
                                {ingredienData.data.map((ingredient, index) => (
                                    <CardIngredient 
                                        key={index}
                                        imgSrc={ingredient.imgSrc}
                                        nameProd={ingredient.nameProd}
                                        title={ingredient.title}
                                        desc={ingredient.desc}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="banner-referensi">
                <div className="wrapp">
                    <div className="wrapp-text">
                        <p className="title">Daftar Referensi</p>
                        <p className="sub-title">Berikut merupakan daftar referensi yang digunakan dalam pemilihan bahan</p>
                    </div>
                    <a href="/referensi" className="referensi">Lihat Referensi</a>
                </div>
            </div>

            <Footer />
        </div>
    );
}