import { useState, useEffect, ChangeEvent } from "react";
import BottomSheet from "../../../../shared/components/bottom_sheet";
import menuUser from "../../../../../assets/images/menu_user.svg";
import menuEmail from "../../../../../assets/images/menu_email.svg";
import menuGender from "../../../../../assets/images/menu_gender.svg";
import menuDate from "../../../../../assets/images/menu_date.svg";
import menuPhone from "../../../../../assets/images/menu_phone.svg";
import profileImage from "../../../../../assets/images/profile.png";
import profileFacade from "../../../facades/profile_facade";
import EditFormDialog from "./edit_form_dialog";
import TextInput from "../../../../shared/components/text_input";

interface ProfileDialogProps {
  open?: boolean;
  onClose?: () => void;
  onMenuClick?: (menu: string) => void;
}

function ProfileDialog(props: ProfileDialogProps) {
  const [image, setImage] = useState<File | null>(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [currentDialog, setCurrentDialog] = useState("name");
  const [todayFormatted, setTodayFormatted] = useState("name");
  const { user, uploadImage, uploadProgress, updateUser } = profileFacade();

  useEffect(
    function () {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const day = String(today.getDate()).padStart(2, "0");
      setTodayFormatted(`${year}-${month}-${day}`);
    },
    [props.open]
  );

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    is_male: 0,
    birth_date: "",
    phone_country_code: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    phone: "",
    is_male: "",
    birth_date: "",
    phone_country_code: "",
  });

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    // console.log(`name ${name}, value: ${value} type: ${typeof value}`)
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  function handleImageChange(e: ChangeEvent<HTMLInputElement>) {
    const selectedImage = e.target.files?.[0] || null;
    uploadImage(selectedImage);
    setImage(selectedImage);
  }

  function onMenuClick(value: string) {
    setFormData({
      ...formData,
      [value]: user[value],
    });
    setCurrentDialog(value as string);
    setOpenEditDialog(true);
  }

  function updateChanges() {
    const newErrors = {
      name: formData.name.length < 5 ? "Please enter at least 5 character" : "",
      phone: formData.phone.length < 9 ? "Please enter at least 9 digit" : "",
      is_male: formData.is_male > 1 ? "Please choose a gender" : "",
      birth_date:
        formData.birth_date.length < 8 ? "Please enter a valid date" : "",
      phone_country_code:
        formData.phone_country_code.length < 8
          ? "Please choose a country code"
          : "",
    };

    if (newErrors[currentDialog as keyof typeof newErrors].length == 0) {
      updateUser(
        currentDialog == "phone"
          ? { ...formData, phone_country_code: "+62" }
          : formData
      ).then((success) => {
        if (success) {
          setFormData({
            name: "",
            phone: "",
            is_male: user.is_male ?? 0,
            birth_date: "",
            phone_country_code: "",
          });
          setErrors({
            name: "",
            phone: "",
            is_male: "",
            birth_date: "",
            phone_country_code: "",
          });
          setOpenEditDialog(false);
        }
      });
    }

    setErrors({ ...newErrors });
  }

  return (
    <>
      <BottomSheet
        open={props.open}
        onClose={props.onClose}
        title="Informasi Personal"
      >
        <div className="profile-detail">
          <figure>
            <img
              src={
                image != null
                  ? URL.createObjectURL(image)
                  : user.image_url_text ?? profileImage
              }
            />
            <div className="icon"></div>
            <input type="file" onChange={handleImageChange} />
            {uploadProgress != 0 ? (
              <div className="progress">{uploadProgress}%</div>
            ) : null}
          </figure>

          <section>
            <Menu
              onClick={() => onMenuClick("name")}
              icon={menuUser}
              label="Nama Lengkap"
              text={user.name ?? ""}
            />
            <div className="divider"></div>
            <Menu
              onClick={() => onMenuClick("email")}
              icon={menuEmail}
              label="Email"
              text={user.email ?? ""}
            />
            <div className="divider"></div>
            <Menu
              onClick={() => onMenuClick("is_male")}
              icon={menuGender}
              label="Jenis Kelamin"
              text={`${user.is_male ? "Laki-laki" : "Perempuan"}`}
            />
            <div className="divider"></div>
            <Menu
              onClick={() => onMenuClick("birth_date")}
              icon={menuDate}
              label="Tanggal Lahir"
              text={user.birth_date ?? "-"}
            />
            <div className="divider"></div>
            <Menu
              onClick={() => onMenuClick("phone")}
              icon={menuPhone}
              label="Nomor Telepon"
              text={`${user.phone_country_code ?? ""}${user.phone ?? ""}`}
            />
          </section>
        </div>
        <div className="secondary-button" onClick={props.onClose}>
          Tutup
        </div>
      </BottomSheet>
      <EditFormDialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        onSubmit={() => updateChanges()}
      >
        {
          {
            name: (
              <TextInput
                label="Nama Lengkap"
                name="name"
                value={formData.name}
                onChange={handleChange}
                isOutlined={true}
                isPrimary={false}
                text={errors.name}
              />
            ),
            email: (
              <TextInput
                label="Email"
                name="email"
                value={user.email}
                disabled
                isOutlined={true}
                isPrimary={false}
              />
            ),
            is_male: (
              <TextInput
                label="Jenis Kelamin"
                name="is_male"
                value={`${formData.is_male ?? 0}`}
                onChange={handleChange}
                isOutlined={true}
                text={errors.is_male}
                isPrimary={false}
              >
                <option value="1">Laki-laki</option>
                <option value="0">Perempuan</option>
              </TextInput>
            ),
            phone: (
              <TextInput
                prefix={<span>+62</span>}
                label="Nomor Telepon"
                name="phone"
                value={`${formData.phone ?? 0}`}
                onChange={handleChange}
                text={errors.phone}
                isOutlined={true}
                isPrimary={false}
              />
            ),
            birth_date: (
              <TextInput
                prefix={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M12 1.33301V2.66634M4 1.33301V2.66634"
                      stroke="#DE8D8D"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1.66675 8.16216C1.66675 5.25729 1.66675 3.80486 2.50149 2.90243C3.33624 2 4.67974 2 7.36675 2H8.63341C11.3204 2 12.6639 2 13.4987 2.90243C14.3334 3.80486 14.3334 5.25729 14.3334 8.16216V8.5045C14.3334 11.4094 14.3334 12.8618 13.4987 13.7642C12.6639 14.6667 11.3204 14.6667 8.63341 14.6667H7.36675C4.67974 14.6667 3.33624 14.6667 2.50149 13.7642C1.66675 12.8618 1.66675 11.4094 1.66675 8.5045V8.16216Z"
                      stroke="#DE8D8D"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2 5.33301H14"
                      stroke="#DE8D8D"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                }
                label="Tanggal Lahir"
                name="birth_date"
                type="date"
                max={todayFormatted}
                value={`${formData.birth_date}`}
                onChange={handleChange}
                text={errors.birth_date}
                isOutlined={true}
                isPrimary={false}
              />
            ),
          }[currentDialog]
        }
      </EditFormDialog>
    </>
  );
}

interface MenuProp {
  label: string;
  text: string;
  icon: string;
  onClick?: () => void;
}

function Menu(props: MenuProp) {
  return (
    <div className="menu" onClick={() => props?.onClick?.()}>
      <img src={props.icon} />
      <div className="expanded">
        <div className="label">{props.label}</div>
        <div className="text">{props.text}</div>
      </div>
    </div>
  );
}

export default ProfileDialog;
