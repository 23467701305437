import { useNavigate } from "react-router-dom";
import routes from "../../../routes";

export default function CardProduct(props) {
  const navigate = useNavigate();

  return (
    <div className="card-product">
      <img className="product-image" src={props.image} alt="" />
      <div className="text">
        <div className="category">{props.promo}</div>
        <h1>{props.title}</h1>
        {/* <hr /> */}
        <div className="price">
          <span>Rp</span>
          {props.price}
        </div>
      </div>
      <img
        onClick={function () {
          navigate(routes.productDetails, { state: props.product });
        }}
        className="product-cart d-md-none"
        src="/svg/product-cart.svg"
        alt=""
      />
      <img
        onClick={function () {
          navigate(routes.productDetails, { state: props.product });
        }}
        className="product-cart-desktop d-none d-md-block"
        src="/svg/product-cart-dekstop.svg"
        alt=""
      />
      <img
        onClick={function () {
          navigate(routes.productDetails, { state: props.product });
        }}
        className="product-cart-desktop xl d-none d-lg-block"
        src="/svg/product-cart-dekstop-2.svg"
        alt=""
      />
    </div>
  );
}
