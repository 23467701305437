import React, { useState } from "react";

const Accordion = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="card-inner">
            <div className="detail-barang" onClick={toggleAccordion}>
                <h3>detail produk</h3>
                <span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="7"
                        viewBox="0 0 12 7"
                        fill="none"
                    >
                        <path
                            d="M1 1.5L5.41074 5.91074C5.68852 6.18852 5.82741 6.32741 6 6.32741C6.17259 6.32741 6.31148 6.18852 6.58926 5.91074L11 1.5"
                            stroke="#191919"
                            strokeWidth="1.25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </span>
            </div>
            {isOpen && <div>{children}</div>}
        </div>
    );
};

export default Accordion;
