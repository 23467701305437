import React, { useState } from 'react';

const CardIngredient = ({ nameProd, title, desc, imgSrc }) => {
    const [isExpanded, setIsExpanded] = useState(false); 

    const toggleDescription = () => {
        setIsExpanded(!isExpanded); 
    };

    const wordLimit = 35;

    const plainDesc = desc.replace(/<[^>]+>/g, ' '); 
    const descWords = plainDesc.split(' ').filter(Boolean); 
    
    const displayedDesc = isExpanded 
        ? desc 
        : descWords.length > wordLimit 
            ? descWords.slice(0, wordLimit).join(' ') + '...' 
            : plainDesc; 

    console.log('word', displayedDesc); 

    return (
        <div className="card-ingredients">
            <div className="wrapp-image">
                <img src={imgSrc} alt={title} />
                <p>Digunakan pada <span>{nameProd}</span></p>
            </div>
            <div className="wrapp-text">
                <p className="title">{title}</p>
                <p className="desc" dangerouslySetInnerHTML={{ __html: isExpanded ? desc : displayedDesc }} />
                
                {isExpanded && (
                    <span 
                        className="text-lihat-lebih-sedikit" 
                        onClick={toggleDescription} 
                        role="button" 
                        tabIndex={0} 
                        onKeyPress={(e) => { if (e.key === 'Enter') toggleDescription(); }} 
                    >
                        {' '}Lihat Lebih Sedikit
                    </span>
                )}

                {!isExpanded && (
                    <div 
                        className="btn button-selengkapnya" 
                        onClick={toggleDescription} 
                        role="button" 
                        tabIndex={0} 
                        onKeyPress={(e) => { if (e.key === 'Enter') toggleDescription(); }} 
                    >
                        Selengkapnya
                    </div>
                )}
            </div>
        </div>
    );
};

export default CardIngredient;
