import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import BackButton from "../components/back_button";
import Countdown from "../components/count_down";
import { useState } from "react";
import PinInput from "react-pin-input";
import authFacade from "../facades/auth_facade";
import routes from "../../../routes";

function VerificationCodeScreen() {
  const [queryParameters] = useSearchParams();
  const [enabledResend, setEnabledResend] = useState(false);
  const [pin, setPIN] = useState("");
  const location = useLocation();
  const [isError, setIsError] = useState(false);
  // const data = location.state ? location.state.data : null;
  const { email, phone, phone_country_code, resendOtp, verifyOtp } =
    authFacade();

  const navigate = useNavigate();
  // const [activeOption, setActiveOption] = useState(0);
  return (
    <div id="auth-root">
      <div className="height-50"></div>
      <div className="height-20"></div>
      <div className="back-button" onClick={() => navigate(routes.register)}>
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.33333 7.1665L5 10.4998M5 10.4998L8.33333 13.8332M5 10.4998L15 10.4998"
            stroke="white"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        Kembali
      </div>
      <div className="height-18"></div>
      <div className="header">
        <h1>Masukkan Kode Verifikasi</h1>
        <p>
          Kode verifikasi telah dikirim melalui
          {(location.state?.type ?? "email") == "email" ? (
            <>
              &nbsp;email
              <br />
              {email}
            </>
          ) : (
            <>
              &nbsp;WhatsApp
              <br />
              {phone_country_code}
              {phone}
            </>
          )}
        </p>
      </div>
      <div className="height-45"></div>
      {isError && (
        <div className="warn-message">
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.1667 8.00016C15.1667 11.6821 12.1819 14.6668 8.5 14.6668C4.8181 14.6668 1.83333 11.6821 1.83333 8.00016C1.83333 4.31826 4.8181 1.3335 8.5 1.3335C12.1819 1.3335 15.1667 4.31826 15.1667 8.00016ZM7.83333 11.3335C7.83333 10.9653 8.13181 10.6668 8.5 10.6668C8.86819 10.6668 9.16666 10.9653 9.16666 11.3335C9.16666 11.7017 8.86818 12.0002 8.5 12.0002C8.13181 12.0002 7.83333 11.7017 7.83333 11.3335ZM8 9.3335C8 9.60964 8.22385 9.8335 8.5 9.8335C8.77614 9.8335 9 9.60964 9 9.3335L9 4.66683C9 4.39069 8.77614 4.16683 8.5 4.16683C8.22385 4.16683 8 4.39069 8 4.66683L8 9.3335Z"
              fill="#DE8D8D"
            />
          </svg>
          "Kode verifikasi salah, masukkan ulang kode"
        </div>
      )}
      <div className="height-27"></div>
      <PinInput
        length={4}
        initialValue=""
        secret
        secretDelay={100}
        onChange={(value, index) => {
          setPIN(value);
        }}
        type="numeric"
        inputMode="number"
        onComplete={(value, index) => {
          setPIN(value);
        }}
        autoSelect={true}
        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
      />
      <div className="height-27"></div>
      {!enabledResend ? (
        <Countdown onCompleted={() => setEnabledResend(true)} duration={20} />
      ) : (
        <div className="count-down">00:00</div>
      )}
      <div className="height-27"></div>
      <p className="resend-actions">
        Tidak menerima kode?
        <span
          style={{ opacity: enabledResend ? 1 : 0.3 }}
          onClick={() => {
            if (enabledResend) {
              // console.log(location.state?.type);

              resendOtp(
                (location.state?.type ?? "email") == "email"
                  ? { email: location.state.email }
                  : { phone: location.state.phone },
                "register"
              ).then((success) => {
                if (success) {
                  setEnabledResend(false);
                }
              });
            }
          }}
        >
          Kirim Ulang
        </span>
      </p>
      <div className="height-45"></div>
      <div
        className={`primary-button${pin.length == 4 ? "" : " disabled"}`}
        onClick={() => {
          if (pin.length == 4) {
            setIsError(false);
            verifyOtp(pin).then((success) => {
              if (success) {
                navigate(
                  queryParameters.get("register") != null
                    ? "/store/register-completion"
                    : "/store/home",
                  { state: location.state }
                );
              } else {
                setIsError(true);
              }
            });
          }
        }}
      >
        Verifikasi
      </div>
    </div>
  );
}
export default VerificationCodeScreen;
