import { NavLink, Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer">
      <div className="wrapper">
        <div className="left">
          <img src="svg/brand.svg" alt="" />

          <div className="address">
            <div className="item">
              <img src="svg/location-black.svg" alt="" />
              <p>Surabaya, Indonesia</p>
            </div>
            <div className="item">
              <img src="svg/call.svg" alt="" />
              <p>+62 888-869-5757</p>
            </div>
            <div className="item">
              <img src="svg/email.svg" alt="" />
              <p>hi@MamaBear.co.id</p>
            </div>
          </div>
        </div>

        <div className="right">
          <div className="item">
            <h1>Company</h1>
            <hr />

            <div className="menu">
              <NavLink to="/our-story" href="#">
                Cerita Kami
              </NavLink>
              <NavLink to="/article" href="#">
                Artikel
              </NavLink>
              <NavLink to="/store/home" target="_blank">
                Produk
              </NavLink>
              <NavLink to="/contact-us" href="#">
                Hubungi Kami
              </NavLink>
              <NavLink to="/afiliator" href="#">
                Afiliator
              </NavLink>
            </div>
          </div>
          <div className="item">
            <h1>Link</h1>
            <hr />

            <div className="menu">
              <NavLink to="/career-home" href="#">
                Karir
              </NavLink>
              <NavLink>Katalog</NavLink>
              <NavLink to="/store/login">Official Store</NavLink>
              <a href="https://wa.me/628888695757" target="_blank">
                Konsultasi
              </a>
              {/* <NavLink>Konsultasi</NavLink> */}
              <NavLink to="/reseller" href="#">
                Reseller
              </NavLink>
              <NavLink to="/cekresi" href="#">
                Cek Resi
              </NavLink>
            </div>
          </div>
          <div className="item">
            <h1>Assistance</h1>
            <hr />

            <div className="menu">
              <NavLink to="/faq" href="#">
                F.A.Q
              </NavLink>
              <NavLink to="/term-condition">Syarat & Ketentuan</NavLink>
              {/* <NavLink to="/store/terms-condition">Syarat & Ketentuan</NavLink> */}
              <NavLink to="/privacy-policy">Kebijakan Privasi</NavLink>
              {/* <NavLink to="/store/privacy-policy">Kebijakan Privasi</NavLink> */}

              <div className="sosmed">
                <a href="https://www.instagram.com/mamabearid/">
                  <img src="svg/instagram.svg" alt="" />
                </a>
                <a href="https://www.youtube.com/channel/UCs2bKV4_gToSL_SFSHpMT8Q/videos">
                  <img src="svg/youtube.svg" alt="" />
                </a>
                <a href="https://www.facebook.com/mamabearcoid/">
                  <img src="svg/facebook.svg" alt="" />
                </a>
                <a href="https://www.tiktok.com/@mamabear_id">
                  <img src="svg/tiktok.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <p className="trademark">© 2019 - 2023 All rights reserved</p>
    </div>
  );
}
