import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../../../../api/api-client";

const getTestimonialsFn = async () => {
  const response = await apiClient.get("/v1/landing-pages/testimonials");
  return response.data.data;
};

export function useTestimonials() {
  return useQuery({
    queryKey: ["useTestimonials"],
    queryFn: getTestimonialsFn,
  });
}
