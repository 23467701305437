import { useNavigate } from "react-router-dom";
import routes from "../../../routes";

export default function CardBayi(props) {
    const { beforeImage, afterImage } = props;
    const navigate = useNavigate();
    return (
        <div className="card-bayi">
            <div className="wrapp-img">
                <img src={beforeImage} alt="Before" className="img-bayi" />
                <img src={afterImage} alt="After" className="img-bayi"/>
            </div>
            <div className="wrapp-text">
                <p className="text">Before</p>
                {/* <div className="line"></div> */}
                <p className="">After</p>
            </div>
        </div>
    );
}