import { persist } from "zustand/middleware";
import { create } from "zustand";
import Network from "../../../utils/network_utils";
import Api from "../../../endpoint/api";
import { MembershipPagination } from "../models/membership_model";

type VoucherStore = {
  vouchers: MembershipPagination;
  fetchVouchers: (code?: string) => Promise<void>;
};

const voucherStore = create<VoucherStore>()(
  persist(
    (set, get) => ({
      vouchers: {},
      fetchVouchers: async (code?: string) => {
        const res = await Network.get(Api.vouchers, {
          queryParameters: { code: code },
        });
        if (res?.ok ?? false) {
          const body = await res!.json();
          const data = body;
          set({ vouchers: data });
        }
      },
    }),
    {
      name: "voucher-storage",
      getStorage: () => sessionStorage,
    }
  )
);

export default voucherStore;
