import Toast from "./toast";

class Network {
  static _getHeader(useToken = true): Record<string, string> {
    const header: Record<string, string> = {
      // "Content-Type": "multipart/form-data",
      Accept: "application/json",
    };
    if (useToken) {
      header["Authorization"] = "Bearer " + localStorage.getItem("token");
    }
    // console.log(header['Authorization'])
    return header;
  }

  static async get(
    url: string,
    {
      useToken = true,
      queryParameters,
    }: { useToken?: boolean; queryParameters?: Record<string, any> }
  ): Promise<Response | null> {
    try {
      const response = await fetch(
        url +
          `${
            !queryParameters
              ? ""
              : `?${Object.entries(queryParameters)
                  .map(([key, value]) => {
                    const encodedKey = encodeURIComponent(key);
                    const encodedValue = encodeURIComponent(value);
                    return `${encodedKey}=${encodedValue}`;
                  })
                  .join("&")}`
          }`,
        {
          method: "GET",
          headers: this._getHeader(useToken),
        }
      );

      if (response.status === 401) {
        Toast.show("Mohon Autentikasi untuk Mengakses Konten Eksklusif", {});
        // localStorage.removeItem("token");
      }

      return response;
    } catch (error) {
      return null;
    }
  }

  static async post(
    url: string,
    {
      data,
      files,
      useToken,
    }: {
      data?: Record<string, any>;
      files?: Record<string, FileList | null | undefined>;
      useToken?: boolean;
    }
  ): Promise<Response> {
    const formData = new FormData();

    // Append data to FormData
    for (const key in data) {
      const value = data[key];
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          formData.append(`${key}[]`, value[i]);
        }
      } else {
        formData.append(key, value);
      }
    }

    // Append files to FormData
    if (files) {
      for (const key in files) {
        const fileList = files[key];
        if (fileList) {
          for (let i = 0; i < fileList.length; i++) {
            formData.append(`${key}[]`, fileList[i]);
          }
        }
      }
    }

    const response = await fetch(url, {
      method: "POST",
      headers: this._getHeader(useToken),
      body: data instanceof FormData ? data : formData,
    });

    if (response.status === 401) {
      Toast.show("Mohon Autentikasi untuk Mengakses Konten Eksklusif", {});
      localStorage.removeItem("token");
    }

    return response;
  }
}

export default Network;
